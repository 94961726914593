import React from "react"
import { Doughnut } from "react-chartjs-2"

const DountChart = () => {
  const data = {
    labels: ["V1", "V2", "V3"],
    datasets: [
      {
        label:'poll',
        data: [300, 210, 90],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
        circumference:180,
        rotation:270
      },
    ],
  }

  return <Doughnut width={474} height={230} data={data} />
}

export default DountChart
