import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { get, post } from 'helpers/helper_api';

import { toast } from 'react-toastify';
// import { withTranslation } from "react-i18next"

const EsgDetail = props => {
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState([]);
    const formRef = useRef();

    const { user } = props;

    const wasteManagement = (e, parent, child) => {
        let temp = Object?.assign([], data);
        if (e.target?.value == "Yes") {
            setWastemanagement(true)
            temp[parent].questions[child].isOpen = true;
        } else {
            setWastemanagement(false)
            temp[parent].questions[child].isOpen = false;
        }
        setData(temp)
    }

    const handleInput = (v, q) => {
        let temp = Object.assign([], form);
        let already = temp?.findIndex(x => x.quesId == q)
        if (already == -1) {
            temp.push({ quesId: q, answer: v })
        }
        else {
            temp[already].answer = v
        }
        setForm(temp)
    }
    useEffect(() => {
        if (props?.user?.token)
            getUserData();
    }, [props])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/esg/question/list', body)
                .then((res => {
                    setLoading(false);
                    console.log('esg for sme question list', res)
                    if (res?.statusCode == 200) {
                        get(`/esg/answer/user?userId=${user?._id}`, { token: user?.token })
                            .then(ansRes => {
                                if (ansRes?.statusCode == 200) {
                                    let formTemp = [];
                                    res?.data?.map((item) => {
                                        if (ansRes?.data?.length > 0) {
                                            ansRes?.data?.map(ans => {
                                                if (ans?.questions?.length > 0) {
                                                    ans?.questions.map(ques => {
                                                        item?.questions?.map(ques2 => {
                                                            // ques2.answer = '';
                                                            if (ques?._id == ques2?._id) {
                                                                ques2.isOpen = false;
                                                                ques2.answer = ques?.answer;
                                                                formTemp.push({ quesId: ques?._id, answer: ques?.answer });
                                                                console.log("question type", ques2?.type, ques?.answer);
                                                                if (ques2?.type != "text") {
                                                                    if (ques.answer != "No") {
                                                                        ques2.isOpen = true;
                                                                    } else {
                                                                        ques2.answer = "No";
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    });
                                    setForm(formTemp);
                                    setData(res?.data)
                                }
                            })
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "ESG", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('ESG', breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        if (!loading) {
            let body = {
                userId: user?._id,
                token: user?.token,
                form: form
            }
            console.log('submitting form', form);
            setLoading(true)
            post('/esg/answer', body)
                .then((res => {
                    setLoading(false);
                    console.log('ad answer', res)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }
    const routeChange = () =>{
        props?.history.push('/esg',{id:user?._id})
     }
    return (
        <React.Fragment>
            <MetaTags>
                <title>ESG | Demleen </title>
            </MetaTags>

            <Loader loading={loading} />

            {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
            <Card>
                <CardBody>

                    <Row>
                        <Col lg={12}>
                            <Table

                            >

                                <tbody >

                                    <tr>
                                        <td style={{ padding: 0, border: 'none' }} colspan="2">
                                            <Table
                                                bordered
                                            >
                                                <thead >
                                                    <tr className='right_head'>
                                                        <th colspan="2" style={{ textAlign: 'left' }}>
                                                            <div className='table_edit_box1'>
                                                                <span>{t("ESG Form")}</span>
                                                                <button className='edit_button' onClick={routeChange}><i className="mdi mdi-pencil"></i> {t("Edit")}</button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.map((item, index) => (
                                                            <>
                                                                <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                                                    <th colSpan={2} style={{ width: '70%' }}>
                                                                        {i18n?.language == 'en' ? item?.title : item?.titleFs}
                                                                    </th>
                                                                    {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}
                                                                </tr>
                                                                <tr className='second_head_theree'>
                                                                    <th colSpan={2} style={{ width: '70%' }}>
                                                                        {i18n?.language == 'en' ? item?.desc : item?.descFs}
                                                                    </th>
                                                                </tr>
                                                                {
                                                                    item?.questions?.map((item1, index1) => (
                                                                        <tr>
                                                                            <td>
                                                                                {i18n?.language == 'en' ? item1?.title : item1?.titleFs}
                                                                            </td>
                                                                            <td>

                                                                                <td>
                                                                                    {item1?.answer}
                                                                                </td>


                                                                            </td>

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </>
                                                        ))
                                                    }



                                                </tbody>
                                            </Table>
                                        </td>

                                    </tr>

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>


        </React.Fragment>
    )
}

EsgDetail.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(EsgDetail)
)