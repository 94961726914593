import React, { useEffect, useState, useRef } from 'react'
import Header from './Header'
import img1 from './assets/img/800x600.png'
import img2 from './assets/img/about-bg.png'
// import img3 from './assets/img/shape/9.png'
import img3 from './assets/img/download.png'
import img4 from './assets/img/side_image.png'
import icon from './assets/img/al-1.jpg'
import icon1 from './assets/img/al-2.jpg'
import icon2 from './assets/img/al-3.jpg'
import banner1 from './assets/img/1.png'
import banner2 from './assets/img/left.png'
import interview from './assets/img/interview.png'
import interview1 from './assets/img/Picture-African-business.png'
import strategy from './assets/img/strategy.png'
import strategy1 from './assets/img/Funding-growth-financing.png'
import selecti from './assets/img/select.png'
import selecti1 from './assets/img/financing-gap-africa.png'
import img_1 from './assets/img/img-1.png'
import img_2 from './assets/img/img-2.png'
import img_3 from './assets/img/img-3.png'
import img_4 from './assets/img/img-4.png'
import Fichier105 from './assets/img/MicrosoftTeams-image.png'
import img_5 from './assets/img/img-5.png'
import Footer from './Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Accordion from 'react-bootstrap/Accordion';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { editProfile, resetProfileFlag } from "../store/actions"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../store/actions";
import ChooseUs from './ChooseUs'
import { AvField, AvForm, AvInput, AvRadio, AvRadioGroup, AvCheckboxGroup, AvCheckbox, AvGroup } from 'availity-reactstrap-validation'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify'
import { post } from 'helpers/helper_api'
import Loader from 'components/Loader'
import { Helmet } from "react-helmet";
function Home(props) {
    const { i18n, t } = useTranslation();
    const [profileModal, setProfileModal] = useState(false);
    const [eligibility, setEligibility] = useState(false);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);
    const [loading, setLoading] = useState(false);

    const ref = useRef(null);
    useEffect(() => {
        checkPopup();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setEligibility(true)
        },10000)
    }, [])

    const delayPopup = () => {
        localStorage.setItem('currentpopuptime', new Date().getTime());
        setProfileModal(false);
    }
    // Sticky Menu Area
    const handleValidSubmit = (e, values) => {
        if (!loading) {
            setLoading(true)
            const body = {
                ...values
            }

            post('/subscribe', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res?.data);
                        toast.success(t("Thankumsg"))
                        delayPopup();
                        ref?.current?.reset();

                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const handleValidSubmit1 = (e, values) => {
        if (!loading) {
            setLoading(true)
            const body = {
                ...values,
                name: values?.fname + ' ' + values?.lname
            }
            post('/user/home_modal', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res?.data);
                        toast.success(res?.message)

                        ref?.current?.reset();
                        setProfileModal(false)


                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const checkPopup = () => {
        const cookies = localStorage.getItem('currentpopuptime');
        const timenow = new Date().getTime() - 86400000;
        // if (!cookies || Number(cookies) < timenow)
        // setProfileModal(true);
    }


    return (
        <div className='body_front'>
            <Helmet>

                <title>Demleen: African Business Financing and Guarantor Platform for SMEs</title>
                <meta name="description" content="Comprehensive financing solutions for African businesses. Discover digital financial services for SMEs.Enhance your business with expert guidance" />
                <link rel="canonical" href="https://demleen.com/" />
            </Helmet>
            <Loader loading={loading} />
            <Modal centered show={profileModal} size='md' className='subscribe_popup home_page_popup' backdrop="static" onHide={delayPopup}>
                <Modal.Body>
                    <div className='subscribe_section subscribe_section_box'>
                        <button className='close-btn' onClick={() => setProfileModal(false)}><i class="fa fa-times"></i></button>
                        <h4>{i18n?.language == 'en' ? 'Demleen arrives in Dakar!' : 'Demleen arrive à Dakar!'} </h4>
                        {/* <p>
                            {t("Main text")}
                        </p> */}
                        <p>{i18n?.language == 'en' ? 'We will be at the ADEPME premises for a demonstration session of our platform on Tuesday February 13, 2024 starting at 9 am.' : 'Nous serons dans les locaux de l’ADEPME pour une séance de démonstration de notre plateforme le mardi 13 février 2024 à partir de 9h00.'}</p>
                        <p>{i18n?.language == 'en' ? 'Come and meet us on this occasion and ask all your questions.' : 'Venez nous rencontrer à cette occasion et poser toutes vos questions.'}</p>
                        <p>{i18n?.language == 'en' ? 'Register here to reserve your place' : 'Inscrivez-vous ici pour réserver votre place'}</p>
                        <AvForm
                            onValidSubmit={(e, v) => {
                                handleValidSubmit1(e, v)
                            }}
                        >
                            <div className='subscribe_box'>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <AvField class="form-control" id="name" name="fname" placeholder={t("First Name") + "*"} type="text" required />
                                            <span class="alert-error"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <AvField class="form-control" id="name" name="lname" placeholder={t("Last Name") + "*"} type="text" required />
                                            <span class="alert-error"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <AvField class="form-control" id="email" name="email" placeholder="Email*" type="email" required />
                                            <span class="alert-error"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <AvField class="form-control" id="text" name="companyName" placeholder={t("Company name")} type="text" required />
                                            <span class="alert-error"></span>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className='btn'>{i18n?.language == 'en' ? 'Register' : 'Réserver ma place'}</button>
                            </div>

                        </AvForm>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal centered show={eligibility} size='md' className='subscribe_popup eligibility_popup home_page_popup' backdrop="static">
                <Modal.Body>
                    <div className='subscribe_section subscribe_section_box'>
                        <button className='close-btn' onClick={() => setEligibility(false)}><i class="fa fa-times"></i></button>
                        <h4>{i18n?.language == "en" ? "Do you want to know if you are eligible to join our Demleen platform ?":"Êtes-vous éligible pour rejoindre Demleen?"}</h4>
                        <h6>{i18n?.language == "en" ? "Click here and find out in 1 min":"Obtenez la réponse en 1 min"}</h6>
                        <a href='https://ogjafkeym7m.typeform.com/to/ct4U6Ezl' target='_blank'>{i18n?.language == "en" ? "Test your eligibility":"Testez votre éligibilité"}</a>
                    </div>
                </Modal.Body>
            </Modal>
            <Header />
            <div class="banner-area top-pad-60-responsive border-shadow text-center content-less text-large">
                <div id="bootcarousel" class="carousel text-light slide carousel-fade animate_text" data-ride="carousel">
                    <div class="carousel-inner carousel-zoom">
                        <Carousel showStatus={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            animationHandler="fade"
                            showThumbs={false}
                            showIndicators={false}
                            stopSwipingHandler={true}
                            showArrows={true}
                            swipeable={false}
                            interval={8000}
                            transitionTime={500}
                        >
                            <div>
                                <div class="slider-thumb bg-cover" style={{ backgroundImage: `url(${banner1})` }}></div>
                                <div class="box-table">
                                    <div class="box-cell shadow dark">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 offset-lg-2">
                                                    <div class="content">
                                                        <h2 data-animation="animated slideInRight" className='banner_heading'><strong>{t("Innovate. Empower. Impact.")}</strong></h2>
                                                        <p>{t("Africa’s strength lies in its entrepreneurs. Backing one of them is impacting plenty")}</p>
                                                        {/* <a data-animation="animated fadeInUp" class="btn circle btn-light border btn-md" href="#">{t("Africa Built Strong")}</a> */}
                                                        {/* <a class="popup-youtube relative video-play-button" href="https://www.youtube.com/watch?v=owhuBrGIOsE">
                                                            <i class="fa fa-play"></i>
                                                        </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="slider-thumb bg-cover" style={{ backgroundImage: `url(${banner1})` }}></div>
                                <div class="box-table">
                                    <div class="box-cell shadow dark">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-8 offset-lg-2">
                                                    <div class="content">
                                                        <h2 data-animation="animated slideInRight" className='banner_heading'><strong>{t("Empowering Entrepreneurial Greatness")}</strong></h2>
                                                        <p>{t("We believe that African Business owners are the ones charging the way forward")}</p>
                                                        {/* <a data-animation="animated fadeInUp" class="btn circle btn-light border btn-md" href="#">Africa Built Strong</a> */}
                                                        {/* <a class="popup-youtube relative video-play-button" href="https://www.youtube.com/watch?v=owhuBrGIOsE">
                                                            <i class="fa fa-play"></i>
                                                        </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>
                <div className='subscribe_strip'>
                    <div className='subscribe_section'>
                        <p>
                            {t("Main text")}
                        </p>
                        <AvForm
                            ref={ref}
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <div className='subscribe_box'>
                                <AvField class="form-control" id="name" name="email" placeholder="Email" type="text" required />
                                <button type="submit" className='btn'>{t("receivethedemolink")}</button>
                            </div>

                        </AvForm>
                    </div>
                </div>
            </div>
            <div class="bg_white_b about-area faq-area inc-shape default-padding ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="thumb">
                                <img src={img1} alt="Thumb" />
                                <img src={img2} alt="Thumb" />
                                <div class="overlay">
                                    <div class="content">
                                        <h4>{t("Impact, efficiency, security")}</h4>
                                        {/* <img src={Fichier105} /> */}
                                    </div>
                                </div>
                                {/* <div className='pattel_box_3'>
                                    <img src={Fichier105} />
                                </div>
                                <div className='pattel_box_4'>
                                    <img src={Fichier106} />
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-5 offset-lg-1 info">
                            <h2 className='h2_m'>{t("Herotwo Heading")}</h2>
                            <p>
                                {t("Herotwo Paragraph1")}
                            </p>
                            <p>{t("Herotwo Paragraph2")}</p>
                            <br />
                            <div className='accordion_one'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <h4 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {t("Herotwo subheading1")}
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div class="card-body">
                                                <p>
                                                    {t("Herotwo subparagraph1")}
                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                {t("Herotwo subheading2")}
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div class="card-body">
                                                <p>
                                                    {t("Herotwo subparagraph")}
                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                By facilitating these connections...
                                            </h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div class="card-body">
                                                <p>
                                                    These connections between SMEs, guarantors, financiers, and investors, Demleen aims to bridge the financing gap that many small and medium-sized enterprises face. They provide a platform that streamlines the process of accessing financing, making it more efficient and accessible for both SMEs and financial stakeholders.
                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item> */}
                                </Accordion>
                            </div>
                            {/* <div class="faq-content accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h4 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Through this platform...
                                        </h4>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                With this platform, guarantors, who are individuals or entities willing to provide a guarantee for a loan or investment, can evaluate the SMEs and decide whether to support them. Financiers, such as banks or financial institutions, can review the SMEs' profiles and choose to offer loans or other financial products based on their viability and potential.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Furthermore, Demleen...
                                        </h4>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                Demleen also connects SMEs with potential investors who are interested in supporting and investing in promising ventures. These investors could be individuals, venture capital firms, or other entities seeking investment opportunities in SMEs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            By facilitating these connections...
                                        </h4>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>
                                                These connections between SMEs, guarantors, financiers, and investors, Demleen aims to bridge the financing gap that many small and medium-sized enterprises face. They provide a platform that streamlines the process of accessing financing, making it more efficient and accessible for both SMEs and financial stakeholders.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="who-we-area-area text-light" style={{ backgroundColor: '#1b005e' }}>
                <div class="container-fluid">
                    <div class="who-we-area-box">
                        <div class="row" style={{ marginRight: 0 }}>
                            <div class="col-lg-6 thumb bg-cover hero_2"></div>
                            <div class="col-lg-6 info">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 item">
                                        <h4>{t("Our Mission")}</h4>
                                        <h2 class="text-blur">Mission</h2>
                                        <p>
                                            {t("Our Mission Paragraph")}
                                        </p>
                                    </div>
                                    <div class="col-lg-6 col-md-6 item">
                                        <h4>{t("Our Vision")}</h4>
                                        <h2 class="text-blur">Vision</h2>
                                        <p>
                                            {t("Our Vision Paragraph")}
                                        </p>
                                    </div>
                                </div>
                                <div className='pattel_box_4'>
                                    <img src={img_5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg_white_b work-process-area overflow-hidden default-padding bottom-less">
                <div className='pattel_box'>
                    <img src={img_3} />
                </div>
                <div className='pattel_box_2'>
                    <img src={img_4} />
                </div>
                <div className='pattel_box_3'>
                    <img src={img_4} />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="site-heading text-center">
                                <h4>{t("Why are African SMEs called the")} <br />{t("missing middle")}</h4>
                                <p>{t("Why Subheading")}</p>
                                <h3>{t("Why Subheading1")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-full">
                    <div class="work-pro-items">
                        <div class="row justify-content-center">

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <div class="item-inner">
                                        <div class="icon">
                                            {/* <img src={interview} alt={interview} /> */}
                                            {/* <span>01</span> */}
                                        </div>
                                        <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={80} />%</h5>
                                        <p>
                                            {t("Box1")}
                                        </p>
                                    </div>
                                    <div className='overly'></div>
                                    <img className='imgBox' src={interview1} alt={interview1} />
                                </div>
                            </div>

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <div class="item-inner">
                                        <div class="icon">
                                            {/* <img src={strategy} alt={strategy} /> */}
                                            {/* <span>02</span> */}
                                        </div>
                                        <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={50} />%</h5>
                                        <p>
                                            {t("Box2")}
                                        </p>
                                    </div>

                                    <div className='overly'></div>
                                    <img className='imgBox' src={strategy1} alt={strategy} />
                                </div>
                            </div>

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <div class="item-inner">
                                        <div class="icon">
                                            {/* <img src={selecti} alt={selecti} /> */}
                                            {/* <span>03</span> */}
                                        </div>
                                        <h5><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={330} /> {t("Box4")}</h5>
                                        <p>{t("Box3")}</p>
                                    </div>
                                    <div className='overly'></div>
                                    <img className='imgBox' src={selecti1} alt={selecti1} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="bg_white_b video-area extra-padding text-center default-padding faq-area bg-gray bg-fixed dark hero_3 text-light">
                <div class="container">
                    <div class="content">
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2">
                                <h5>Why Choose Us</h5>
                                <h2>Demleen meets your needs.</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fixed-shape-bottom">
                    <img src={img3} alt="Shape" />
                </div>

            </div> */}

            <div class=" works-about-area overflow-hidden">
                <div class="container">
                    <div class="works-about-items default-padding">
                        <div class="row align-center">
                            <div class="col-lg-6 info">
                                <h5>{t("Herothree SubHeading")}</h5>
                                <h2>
                                    {t("Herothree Heading")}
                                </h2>
                                <p>
                                    {t("Herothree paragraph")}
                                </p>
                                {/* <p>
                                    Investment in Africa can take many forms.<br /><b> “There guarantee is one. Demleen"</b>
                                </p> */}
                                <p>
                                    {t("Herothree paragraph1")}
                                </p>

                                <Link class="btn btn-theme effect btn-sm" to="/contact">{t("Talk to a consultant")}</Link>
                            </div>
                            <div class="col-lg-6">
                                <div class="thumb thumb_bg_pattel">
                                    <img src={img4} alt="Thumb" />
                                    <img src={img_2} alt="Thumb" className='thumb_bg_' />
                                    <div class="fun-fact">
                                        {/* <h2 class="timer"> <CountUp end={1000} />+</h2> */}

                                        <span class="medium">{t("Multiplier effect of up to 2x of financing")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg_white_b feature-area half-bg overflow-hidden default-padding-top">
                <div class="container">
                    <div class="heading-left">
                        <div class="row">
                            <div class="col-lg-6">
                                <h5>10+ years of experience</h5>
                                <h2>
                                    {t("They support us!")}
                                </h2>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    {t("They support paragraph")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="features-box text-light">
                        <div class="row">

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <img src={icon} alt="Thumb" />

                                </div>
                            </div>

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <img src={icon1} alt="Thumb" />

                                </div>
                            </div>

                            <div class="single-item col-lg-4 col-md-6">
                                <div class="item">
                                    <img src={icon2} alt="Thumb" />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-area faq_area default-padding bg-gray">
                <div class="container">
                    <div class="testimonial-items">
                        <div class="row">
                            <div class="col-lg-5 info">
                                <h5>{t("FAQ")}</h5>
                                <h2>{t("FAQ Heading")}</h2>
                                <div className='faq_img'>
                                    <img src={img_1} />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div className='accordion_one'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <h4 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <strong>?</strong> {t("FAQ Sub Heading")}
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card-body">
                                                    <p>
                                                        {t("FAQ Sub paragraph1")}
                                                    </p>
                                                    <p>{t("FAQ Sub paragraph2")}</p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong> {t("FAQ Sub Heading1")}
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card-body">
                                                    <p>
                                                        {t("FAQ Sub paragraph3")}
                                                    </p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <strong>?</strong>{t("FAQ Sub Heading2")}
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card-body">
                                                    <p>
                                                        {t("FAQ Sub paragraph4")}
                                                    </p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong>  {t("FAQ Sub Heading3")}
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card-body">
                                                    <p>
                                                        {t("FAQ Sub paragraph5")}
                                                    </p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong>How does Demleen ensure the security and confidentiality of information shared on the platform?
                                                </h4>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card-body">
                                                    <div class="card-body">
                                                        <p>
                                                            Demleen prioritizes the security and confidentiality of information shared on its platform. The organization implements robust data protection measures and adheres to relevant privacy laws and regulations. Only authorized users, such as registered SMEs, investors, and Demleen's administrative team, have access to specific information. Demleen maintains a commitment to safeguarding sensitive data and employs appropriate technical and organizational safeguards to protect user information.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item> */}

                                    </Accordion>
                                </div>
                                {/* <div class="faq-content">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h4 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <strong>?</strong> How does Demleen's digital platform work?
                                                </h4>
                                            </div>
                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <p>
                                                        Demleen's digital platform serves as a connection hub between African SMEs and impact investors. SMEs can showcase their business models, strategies, and financing needs on the platform. Impact investors can browse through these profiles, evaluate potential investment opportunities, and connect directly with the SMEs they are interested in supporting.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong> What criteria does Demleen use to select SMEs for its platform?
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <p>
                                                        Demleen selects SMEs based on their potential for impact and growth. Criteria may include factors such as social and environmental impact, innovation, scalability, financial viability, and alignment with the sustainable development goals. SMEs are evaluated through a thorough screening process to ensure the quality and suitability of ventures on the platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingFour">
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <strong>?</strong> What types of financing opportunities are available through Demleen?
                                                </h4>
                                            </div>
                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <p>
                                                        Demleen offers a range of financing opportunities for SMEs, including loans, equity investments, and other financial products. The availability of specific financing options depends on the interests and preferences of impact investors registered on the platform. SMEs can connect with investors who align with their financing needs and negotiate terms based on mutual agreement.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong>   Is Demleen limited to specific industries or sectors?
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <p>
                                                        No, Demleen is open to SMEs across various industries and sectors. The platform aims to support a diverse range of businesses, including those involved in sectors such as technology, agriculture, renewable energy, healthcare, education, and more. SMEs from any industry with a potential for impact are welcome to join and showcase their ventures on Demleen's platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h4 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>?</strong>How does Demleen ensure the security and confidentiality of information shared on the platform?
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <p>
                                                        Demleen prioritizes the security and confidentiality of information shared on its platform. The organization implements robust data protection measures and adheres to relevant privacy laws and regulations. Only authorized users, such as registered SMEs, investors, and Demleen's administrative team, have access to specific information. Demleen maintains a commitment to safeguarding sensitive data and employs appropriate technical and organizational safeguards to protect user information.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg_white_b quick-contact-area default-padding">
                <div class="container">
                    <div class="quick-contact-items shadow dark bg-cover text-light hero_4">
                        <div class="row align-center">
                            <div class="col-lg-8">
                                <h5>{t("NEED HELP")}</h5>
                                <h4 className='need_help'>{t("NEED HELP Paragraph")}</h4>
                            </div>
                            <div class="col-lg-4 text-right">
                                <Link class="btn btn-light effect btn-md" to="/contact">Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

Home.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(Home)
)