import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

const generateShades = (color, steps) => {
    const colorSteps = [];
    const colorRegex = /rgba?\((\d+), (\d+), (\d+)(?:, (\d+))?\)/;
    const match = color ? color.match(colorRegex) : null;
    if (!match) return [];

    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);

    for (let i = 0; i < steps; i++) {
        const factor = (i + 1) / steps;
        colorSteps.push(`rgba(${Math.round(r * factor)}, ${Math.round(g * factor)}, ${Math.round(b * factor)}, 0.6)`);
    }
    return colorSteps;
};

const createColorShades = (baseColor, steps) => {
    const colors = [];
    const color = baseColor.match(/\d+/g).map(Number);
    const step = 255 / steps;

    for (let i = 0; i < steps; i++) {
        const factor = step * i;
        const r = Math.min(255, color[0] + factor);
        const g = Math.min(255, color[1] + factor);
        const b = Math.min(255, color[2] + factor);
        colors.push(`rgba(${r}, ${g}, ${b}, 0.5)`);
    }
    return colors;
};

const RadarChart = ({ result, i18n, resultPer }) => {

    // console.log('result', result, resultPer)
    const baseColors = [
        'rgba(255, 99, 71, 1)',    // Tomato
        'rgba(135, 206, 235, 1)',  // Sky Blue
        'rgba(60, 179, 113, 1)',   // Medium Sea Green
        'rgba(255, 215, 0, 1)',    // Gold
        'rgba(123, 104, 238, 1)',  // Medium Slate Blue
        'rgba(255, 69, 0, 1)',     // Red Orange
        'rgba(70, 130, 180, 1)',   // Steel Blue
        'rgba(199, 21, 133, 1)',   // Medium Violet Red
        'rgba(255, 140, 0, 1)',    // Dark Orange
        'rgba(46, 139, 87, 1)',    // Sea Green
    ];


    const steps = 10;

    // const backgroundColors = baseColors.map(baseColor => createColorShades(baseColor, steps));
    const backgroundColors = baseColors.map(baseColor => baseColor);

    const data = {
        labels: result?.labels,
        datasets: [
            {
                label: 'Your Scores',
                data: result?.data,
                backgroundColor: backgroundColors,
                borderColor: baseColors,
                borderWidth: 2,
                hoverBorderColor: '#000',
                hoverBorderWidth: 3,
            }
        ]
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
            r: {
                min: 0,
                max: 100,
                ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    // backdropColor: 'rgba(255, 255, 255, 0)',
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                angleLines: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                pointLabels: {
                    display: true,
                    font: {
                        size: 13,
                        weight: 'bold'
                    },
                    centerPointLabels: true,
                    color: '#000',
                    padding: 20
                }
            }
        },
        layout: {
            padding: 0
        },
        plugins: {
            legend: {
                display: true,
                position: window?.innerWidth < 1199 ? 'bottom' : 'left',
                // labels: {
                //     generateLabels: (chart) => {
                //         const data = chart.data;
                //         return data.labels.map((label, i) => ({
                //             text: label,
                //             fillStyle: data.datasets[0].backgroundColor[i],
                //             hidden: false,
                //             index: i
                //         }));
                //     }
                // }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}`;
                    }
                }
            },
            datalabels: {
                display: window?.innerWidth < 576 ? false : true,
                color: '#000',
                formatter: function (value, context) {
                    // const label = resultPer.labels[context.dataIndex];
                    // return `${label}`;
                },
                anchor: 'end',
                align: 'end',
                offset: -45,
                clip: true,
                padding: -20,

            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
            animationDuration: 400,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', margin: 'auto' }}>
            <PolarArea width={900} height={500} data={data} options={options} />
        </div>
    );
};

export default RadarChart;