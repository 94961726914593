import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const balanceChart = ({ title, value }) => {

    const series = [
        {
            name: '',
            data: value,
        },
    ]
    const options = {
        chart: {
            type: 'bar',
            height: 380
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#559A9D'],
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val) {
                return val.toLocaleString()
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 0,
            colors: ['#fff']
        },
        xaxis: {
            categories: title,
        },
        yaxis: {
            labels: {
                show: true
            }
        },


        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    }

    return (
        <ReactApexChart options={options} series={series} type="bar" height="350" />
    )
}

export default balanceChart
