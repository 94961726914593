import React from 'react'
import { BallTriangle } from 'react-loader-spinner'

function Loader(props) {
    if(props?.loading)
    return (
        <div className='loader_component'>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#fff"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    )
    else
    return null
}

export default Loader