import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
export default function ChooseUs() {
    const { i18n, t } = useTranslation();
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 500 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    return (
        <div class="header-section choose-us-area">
            <div class="container">
                <div class="items-box">

                    <div class="row">

                        
                        <div class="single-item col-lg-8 col-md-8">
                            <div class="item info_paragraph_item">
                               <span >{t("ContactsParagraph")}</span>
                            </div>
                        </div>

                        <div class="single-item col-lg-4 col-md-4">
                            <div class="item">
                                {/* <div class="icon">
                                    <i class="fas fa-phone"></i>
                                </div> */}
                                <Link to='/contact'>
                                    <div class="info">
                                        {/* <h4>IS YOUR SME LOOKING FOR FUNDING?</h4> */}
                                        {/* <p className='info_paragraph'>
                                        Learn about our criteria and make yourself known
                                        </p>
                                        */}
                                        <p class="btn-more">Contact</p>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
