import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvCheckbox, AvGroup, AvInput } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"


// import images
import logoLightPng from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-sm.png"

import { post } from "helpers/helper_api";
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Register = props => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [isOtp, setIsOtp] = useState(false);
  const [isVisi, setIsVisi] = useState(false);
  const [isTrue, setIsTrue] = useState(false)
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // setLoading(true);
    if (isTrue) {
      const body = {
        ...values,
        lang: i18n?.language
      }
      console.log('req on register', body);
      let url = '/register';
      if (isOtp)
        url = '/verify';
      post(url, body)
        .then(res => {
          // setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on register', res?.data);
            if (isOtp) {
              toast.success(res?.message)
              localStorage.setItem("user", JSON.stringify(res?.data));
              props.registerUser(res?.data)
              history.push('/login')
            } else {
              setIsOtp(true);
            }
          } else
            toast.error(res?.error)
          console.log(res?.error);
        })
        .catch(error => {
          // setLoading(false);
          console.log('error while login', error);
          toast.error('Something went wrong')
          console.log('Something went wrong');
        })
    }
    else {
      toast.error('Please check the terms and condition')
    }
    // props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
  }, []);

  const checkFunction = (e) => {
    setIsTrue(e.target.checked)
  }

  console.log('isTrue', isTrue)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Demleen</title>
      </MetaTags>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="60" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="60" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">{t("Signup")}</h4>
                    <p className="text-muted text-center">{t("Get your Demleen account now")}</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {/* {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {props.registrationError &&
                        props.registrationError ? (
                        <Alert color="danger">
                          {props.registrationError}
                        </Alert>
                      ) : null} */}

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      {/* <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type="text"
                          required
                          placeholder="Enter username"
                        />
                      </div> */}
                      <div className="mb-3 password">
                        <AvField
                          name="password"
                          label={t("Password")}
                          type={isVisi ? "text" : "password"}
                          required
                          placeholder={t("Password")}
                        />
                        <i className="mdi mdi-eye" onClick={() => setIsVisi(!isVisi)} />
                      </div>
                      <div className="mb-3">
                        <AvField type="select" name="role" label={t("Signup as")} required>
                          <option value="">{t("Select type")}</option>
                          <option value={"SME"}>{t("SME1")}</option>
                          <option value={"Investor"}>{t("Investor")}</option>
                        </AvField>
                      </div>
                      {
                        isOtp == true && <div className="mb-3">
                          <AvField
                            name="otp"
                            label={t("Otp")}
                            type="number"
                            required
                            placeholder={t("Otp")}
                          />
                        </div>
                      }
                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                          <button className="btn btn_bg w-md waves-effect waves-light" type="submit">{t("Register")}</button>
                        </div>
                      </div>

                      <div className="mb-0 row">
                        <div className="col-12 mt-4">
                          <p className="text-muted mb-0 font-size-13 terms-check-box">
                            <div className="checlbox_box">
                              <input type="checkbox" name="checkbox" onChange={(e) => checkFunction(e)} id="term_check" required />
                              <label>By registering you agree to the Demleen <Link to="/terms-of-service" className="text-primary">Terms of Use</Link></label>
                            </div>
                          </p>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Already have an account")}?{" "}
                  <Link to="/login" className="text-primary">
                    {" "}
                    {t("Login")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Demleen <span className="d-none d-sm-inline-block"> - All Rights Reserved. <i className="mdi mdi-heart text-danger"></i> Design by <a href='https://www.rajmith.com/'>RAJMITH</a>.</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
