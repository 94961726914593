import PropTypes, { number } from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table as TableData,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap"
import { Space, Table, Tag } from 'antd';
const { Column, ColumnGroup } = Table;
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import ExcelFile from '../../assets/excel/sample.xlsx'
import { FileUploader } from "react-drag-drop-files";

import { upload, post, put, get } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import { load } from 'dotenv';
import Loader from 'components/Loader';
import * as Utils from "../Utils";

const BalanceSheetReport = props => {
    const scrollContainerRef = useRef(null);
    const fileTypes = ["xlsx"];
    const years = Utils.getYears();
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)
    const [value, setValue] = useState(0);
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);
    const [year_1, setYear_1] = useState(undefined);
    const [year_2, setYear_2] = useState(undefined);
    const [year_3, setYear_3] = useState(undefined);
    const { i18n, t } = useTranslation();
    const [excelUploadModal, setExcelUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
    const [formValue, setFormValue] = useState();
    const [loading, setLoading] = useState(false);
    const [data1, setData1] = useState(null)
    const handleChange = (file) => {
        setFile(file);
    };
    const toggle = () => setExcelUploadModal(!excelUploadModal);
    const formRef = useRef();

    const { user, userId } = props;
    const scrollAmount = 300;

    const scrollLeftHandler = () => {
        scrollContainerRef.current.scrollLeft -= scrollAmount;
    };

    const scrollRightHandler = () => {
        scrollContainerRef.current.scrollLeft += scrollAmount;
    };

    useEffect(() => {
        // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        // if (isEmpty) {
        //    setButtonStatus(false)
        // } else {
        //     setButtonStatus(true)
        // }
        const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
        const formElements = form.getElementsByTagName('input');
        let allFieldsNotEmpty1 = true;
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            // console.log('element', element.value)
            if (element.tagName === 'INPUT' && element.value === '') {
                allFieldsNotEmpty1 = false;
                break;
            }
        }
        setAllFieldsNotEmpty(allFieldsNotEmpty1)

    }, [formValue]);

    const getUserData = (year, setter, reportDataSetter, count) => {
        if (!loading) {
            setLoading(true);
            let body = {
                year,
                userId: props?.userId,
                token: user?.token
            }
            post('/balance_sheet', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setter(res?.data);
                        reportDataSetter((prevProYear) => ({
                            ...prevProYear,
                            [`year${count}`]: res?.data, // Dynamically set year-specific data
                        }));
                    } else {
                        setter(undefined);
                        reportDataSetter((prevProYear) => ({
                            ...prevProYear,
                            [`year${count}`]: { year }, // Dynamically set year-specific data
                        }));
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setter(undefined);
                    reportDataSetter((prevProYear) => ({
                        ...prevProYear,
                        [`year${count}`]: undefined, // Dynamically set year-specific data
                    }));
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                });
        }
    }

    const getUserData1 = (year, setter, reportDataSetter, count) => {
        if (!loading) {
            setLoading(true);
            let body = {
                year,
                userId: props?.userId,
                token: user?.token
            }
            post('/liab', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setter(res?.data);
                        reportDataSetter((prevProYear) => ({
                            ...prevProYear,
                            [`year${count}`]: res?.data, // Dynamically set year-specific data
                        }));
                    } else {
                        setter(undefined);
                        reportDataSetter((prevProYear) => ({
                            ...prevProYear,
                            [`year${count}`]: { year }, // Dynamically set year-specific data
                        }));
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setter(undefined);
                    reportDataSetter((prevProYear) => ({
                        ...prevProYear,
                        [`year${count}`]: undefined, // Dynamically set year-specific data
                    }));
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                });
        }
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                props.resetProfileFlag();
            }, 3000);
        }
    }, [props.success])

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Balance Sheet", link: "#" },
    ]


    // function handleValidSubmit(event, values) {

    //     if (values?.year_one) {
    //         let data = [];
    //         if (values?.year_one) {
    //             data.push({
    //                 ...renameKeys(values, 1),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_one,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross1),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation1),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet1),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross1),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation1),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet1),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross1),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation1),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet1),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross1),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation1),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet1),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross1),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation1),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet1),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross1),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation1),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet1),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross1),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation1),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet1),
    //                 totalAssetsGross: Number(totalAssetsGross1),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation1),
    //                 totalAssetsNet: Number(totalAssetsNet1),
    //             });
    //         }
    //         if (values?.year_two) {
    //             data.push({
    //                 ...renameKeys(values, 2),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_two,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross2),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation2),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet2),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross2),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation2),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet2),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross2),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation2),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet2),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross2),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation2),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet2),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross2),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation2),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet2),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross2),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation2),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet2),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross2),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation2),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet2),
    //                 totalAssetsGross: Number(totalAssetsGross2),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation2),
    //                 totalAssetsNet: Number(totalAssetsNet2),
    //             });
    //         }
    //         if (values?.year_three) {
    //             data.push({
    //                 ...renameKeys(values, 3),
    //                 userId: user?._id,
    //                 token: user.token,
    //                 year: values?.year_three,
    //                 intangiblefixedassetsGross: Number(intangiblefixedassetsGross3),
    //                 intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation3),
    //                 intangiblefixedassetsNet: Number(intangiblefixedassetsNet3),
    //                 tangiblefixedassetsGross: Number(tangiblefixedassetsGross3),
    //                 tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation3),
    //                 tangiblefixedassetsNet: Number(tangiblefixedassetsNet3),
    //                 financialFixedAssetsGross: Number(financialFixedAssetsGross3),
    //                 financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation3),
    //                 financialFixedAssetsNet: Number(financialFixedAssetsNet3),
    //                 totalFixedAssetsGross: Number(totalFixedAssetsGross3),
    //                 totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation3),
    //                 totalFixedAssetsNet: Number(totalFixedAssetsNet3),
    //                 suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross3),
    //                 suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation3),
    //                 suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet3),
    //                 totalCurrentAssetsGross: Number(totalCurrentAssetsGross3),
    //                 totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation3),
    //                 totalCurrentAssetsNet: Number(totalCurrentAssetsNet3),
    //                 cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross3),
    //                 cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation3),
    //                 cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet3),
    //                 totalAssetsGross: Number(totalAssetsGross3),
    //                 totalAssetsDepreciation: Number(totalAssetsDepreciation3),
    //                 totalAssetsNet: Number(totalAssetsNet3),
    //             });
    //         }
    //         data?.forEach((body, index) => {
    //             post('/balance_sheet/add', body)
    //                 .then((res) => {
    //                     if (data?.length - 1 === index)
    //                         if (res?.statusCode == 200) {
    //                             console.log('request on login', res);
    //                             toast.success(res?.message)
    //                         } else {
    //                             console.log(res?.error);
    //                             toast.error(res?.error)
    //                         }
    //                 })
    //                 .catch((err) => {
    //                     console.log(err)
    //                 })
    //         })
    //     }
    //     else {
    //         toast?.error('First Year is Required')
    //     }
    // }

    const uploadExcel = () => {
        if (file) {
            const form = new FormData();
            form.append("sample", file[0]);

            upload('/profit_loss/sample_upload', form, user?.token)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        setExcelUploadModal(false)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toast.error('Please choose file')
        }
    }

    function renameKeys(obj, to) {
        const newKeys = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const regexPattern = new RegExp(to + "$");
                const newKey = key.replace(regexPattern, ''); // Remove trailing digits
                newKeys[newKey] = obj[key];
            }
        }

        return newKeys;
    }

    const columns = [
        {
            title: 'Asset',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
        },
        {
            title: 'Other',
            render: (item) => <p>action</p>,
            children: [
                {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                    width: 150,
                    sorter: (a, b) => a.age - b.age,
                },
                {
                    title: 'Address',
                    children: [
                        {
                            title: 'Street',
                            dataIndex: 'street',
                            key: 'street',
                            width: 150,
                        },
                        {
                            title: 'Block',
                            children: [
                                {
                                    title: 'Building',
                                    dataIndex: 'building',
                                    key: 'building',
                                    width: 100,
                                },
                                {
                                    title: 'Door No.',
                                    dataIndex: 'number',
                                    key: 'number',
                                    width: 100,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Company',
            children: [
                {
                    title: 'Company Address',
                    dataIndex: 'companyAddress',
                    key: 'companyAddress',
                    width: 200,
                },
                {
                    title: 'Company Name',
                    dataIndex: 'companyName',
                    key: 'companyName',
                },
            ],
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            fixed: 'right',
        },
    ];
    const data = [
        {
            key: '1',
            asset: <strong>{t("Intangible fixed assets")}</strong>,
            gross1: <strong>{year1?.intangiblefixedassetsGross ? Number(year1?.intangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.intangiblefixedassetsGross ? Number(year2?.intangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.intangiblefixedassetsGross ? Number(year3?.intangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.intangiblefixedassetsDepreciation ? Number(year1?.intangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.intangiblefixedassetsDepreciation ? Number(year2?.intangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.intangiblefixedassetsDepreciation ? Number(year3?.intangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.intangiblefixedassetsNet ? Number(year1?.intangiblefixedassetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.intangiblefixedassetsNet ? Number(year2?.intangiblefixedassetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.intangiblefixedassetsNet ? Number(year3?.intangiblefixedassetsNet).toLocaleString() : "0"}</strong>,

        },
        {
            key: '2',
            asset: <span>{t("Research and development costs")}</span>,
            gross1: <span>{year1?.researchAndDevelopmentCostsGross ? Number(year1?.researchAndDevelopmentCostsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.researchAndDevelopmentCostsGross ? Number(year2?.researchAndDevelopmentCostsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.researchAndDevelopmentCostsGross ? Number(year3?.researchAndDevelopmentCostsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.researchAndDevelopmentCostsDepreciation ? Number(year1?.researchAndDevelopmentCostsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.researchAndDevelopmentCostsDepreciation ? Number(year2?.researchAndDevelopmentCostsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.researchAndDevelopmentCostsDepreciation ? Number(year3?.researchAndDevelopmentCostsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.researchAndDevelopmentCostsNet ? Number(year1?.researchAndDevelopmentCostsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.researchAndDevelopmentCostsNet ? Number(year2?.researchAndDevelopmentCostsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.researchAndDevelopmentCostsNet ? Number(year3?.researchAndDevelopmentCostsNet).toLocaleString() : "0"}</span>,
        },
        {
            key: '3',
            asset: <span>{t("Concessions and similar rights, patents, licenses, trademarks, processes, software, rights and similar assets")}</span>,
            gross1: <span>{year1?.concessionsAndSimilarRightsGross ? Number(year1?.concessionsAndSimilarRightsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.concessionsAndSimilarRightsGross ? Number(year2?.concessionsAndSimilarRightsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.concessionsAndSimilarRightsGross ? Number(year3?.concessionsAndSimilarRightsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.concessionsAndSimilarRightsDepreciation ? Number(year1?.concessionsAndSimilarRightsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.concessionsAndSimilarRightsDepreciation ? Number(year2?.concessionsAndSimilarRightsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.concessionsAndSimilarRightsDepreciation ? Number(year3?.concessionsAndSimilarRightsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.concessionsAndSimilarRightsNet ? Number(year1?.concessionsAndSimilarRightsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.concessionsAndSimilarRightsNet ? Number(year2?.concessionsAndSimilarRightsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.concessionsAndSimilarRightsNet ? Number(year3?.concessionsAndSimilarRightsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '4',
            asset: <span>{t("Goodwill & Leashold Rights")}</span>,
            gross1: <span>{year1?.goodwillLeasholdRightsGross ? Number(year1?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.goodwillLeasholdRightsGross ? Number(year2?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.goodwillLeasholdRightsGross ? Number(year3?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.goodwillLeasholdRightsGross ? Number(year1?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.goodwillLeasholdRightsGross ? Number(year2?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.goodwillLeasholdRightsGross ? Number(year3?.goodwillLeasholdRightsGross).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.goodwillLeasholdRightsNet ? Number(year1?.goodwillLeasholdRightsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.goodwillLeasholdRightsNet ? Number(year2?.goodwillLeasholdRightsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.goodwillLeasholdRightsNet ? Number(year3?.goodwillLeasholdRightsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '5',
            asset: <span> {t("Other intangible fixed assets")}</span>,
            gross1: <span>{year1?.otherIntangibleFixedAssetsGross ? Number(year1?.otherIntangibleFixedAssetsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.otherIntangibleFixedAssetsGross ? Number(year2?.otherIntangibleFixedAssetsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.otherIntangibleFixedAssetsGross ? Number(year3?.otherIntangibleFixedAssetsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.otherIntangibleFixedAssetsDepreciation ? Number(year1?.otherIntangibleFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.otherIntangibleFixedAssetsDepreciation ? Number(year2?.otherIntangibleFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.otherIntangibleFixedAssetsDepreciation ? Number(year3?.otherIntangibleFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.otherIntangibleFixedAssetsNet ? Number(year1?.otherIntangibleFixedAssetsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.otherIntangibleFixedAssetsNet ? Number(year2?.otherIntangibleFixedAssetsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.otherIntangibleFixedAssetsNet ? Number(year3?.otherIntangibleFixedAssetsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '6',
            asset: <strong>{t("Tangible fixed assets")}</strong>,
            gross1: <strong>{year1?.tangiblefixedassetsGross ? Number(year1?.tangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.tangiblefixedassetsGross ? Number(year2?.tangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.tangiblefixedassetsGross ? Number(year3?.tangiblefixedassetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.tangiblefixedassetsDepreciation ? Number(year1?.tangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.tangiblefixedassetsDepreciation ? Number(year2?.tangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.tangiblefixedassetsDepreciation ? Number(year3?.tangiblefixedassetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.tangiblefixedassetsNet ? Number(year1?.tangiblefixedassetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.tangiblefixedassetsNet ? Number(year2?.tangiblefixedassetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.tangiblefixedassetsNet ? Number(year3?.tangiblefixedassetsNet).toLocaleString() : "0"}</strong>,
        },
        {
            key: '7',
            asset: <span>{t("Land")}</span>,
            gross1: <span>{year1?.landGross ? Number(year1?.landGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.landGross ? Number(year2?.landGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.landGross ? Number(year3?.landGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.landDepreciation ? Number(year1?.landDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.landDepreciation ? Number(year2?.landDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.landDepreciation ? Number(year3?.landDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.landNet ? Number(year1?.landNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.landNet ? Number(year2?.landNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.landNet ? Number(year3?.landNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '8',
            asset: <span>{t("Constructions")}</span>,
            gross1: <span>{year1?.constructionsGross ? Number(year1?.constructionsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.constructionsGross ? Number(year2?.constructionsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.constructionsGross ? Number(year3?.constructionsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.constructionsDepreciation ? Number(year1?.constructionsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.constructionsDepreciation ? Number(year2?.constructionsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.constructionsDepreciation ? Number(year3?.constructionsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.constructionsNet ? Number(year1?.constructionsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.constructionsNet ? Number(year2?.constructionsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.constructionsNet ? Number(year3?.constructionsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '9',
            asset: <span>{t("Technical installations, industrial machinery, equipment and tools")}</span>,
            gross1: <span>{year1?.technicalInstallationsGross ? Number(year1?.technicalInstallationsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.technicalInstallationsGross ? Number(year2?.technicalInstallationsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.technicalInstallationsGross ? Number(year3?.technicalInstallationsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.technicalInstallationsDepreciation ? Number(year1?.technicalInstallationsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.technicalInstallationsDepreciation ? Number(year2?.technicalInstallationsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.technicalInstallationsDepreciation ? Number(year3?.technicalInstallationsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.technicalInstallationsNet ? Number(year1?.technicalInstallationsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.technicalInstallationsNet ? Number(year2?.technicalInstallationsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.technicalInstallationsNet ? Number(year3?.technicalInstallationsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '10',
            asset: <span>{t("Office equipment and computer hardware, Furniture, Livestock, Cheptel, Returnable packaging")}</span>,
            gross1: <span>{year1?.officeEquipmentGross ? Number(year1?.officeEquipmentGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.officeEquipmentGross ? Number(year2?.officeEquipmentGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.officeEquipmentGross ? Number(year3?.officeEquipmentGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.officeEquipmentDepreciation ? Number(year1?.officeEquipmentDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.officeEquipmentDepreciation ? Number(year2?.officeEquipmentDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.officeEquipmentDepreciation ? Number(year3?.officeEquipmentDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.officeEquipmentNet ? Number(year1?.officeEquipmentNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.officeEquipmentNet ? Number(year2?.officeEquipmentNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.officeEquipmentNet ? Number(year3?.officeEquipmentNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '11',
            asset: <span>{t("Transport equipment")}</span>,
            gross1: <span>{year1?.transportEquipmentGross ? Number(year1?.transportEquipmentGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.transportEquipmentGross ? Number(year2?.transportEquipmentGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.transportEquipmentGross ? Number(year3?.transportEquipmentGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.transportEquipmentDepreciation ? Number(year1?.transportEquipmentDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.transportEquipmentDepreciation ? Number(year2?.transportEquipmentDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.transportEquipmentDepreciation ? Number(year3?.transportEquipmentDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.transportEquipmentNet ? Number(year1?.transportEquipmentNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.transportEquipmentNet ? Number(year2?.transportEquipmentNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.transportEquipmentNet ? Number(year3?.transportEquipmentNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '12',
            asset: <span>{t("Advance payments on account on orders for intangible fixed assets")}</span>,
            gross1: <span>{year1?.advancePaymentsGross ? Number(year1?.advancePaymentsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.advancePaymentsGross ? Number(year2?.advancePaymentsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.advancePaymentsGross ? Number(year3?.advancePaymentsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.advancePaymentsDepreciation ? Number(year1?.advancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.advancePaymentsDepreciation ? Number(year2?.advancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.advancePaymentsDepreciation ? Number(year3?.advancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.advancePaymentsNet ? Number(year1?.advancePaymentsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.advancePaymentsNet ? Number(year2?.advancePaymentsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.advancePaymentsNet ? Number(year3?.advancePaymentsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '13',
            asset: <strong>{t("Financial fixed assets")}</strong>,
            gross1: <strong>{year1?.financialFixedAssetsGross ? Number(year1?.financialFixedAssetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.financialFixedAssetsGross ? Number(year2?.financialFixedAssetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.financialFixedAssetsGross ? Number(year3?.financialFixedAssetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.financialFixedAssetsDepreciation ? Number(year1?.financialFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.financialFixedAssetsDepreciation ? Number(year2?.financialFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.financialFixedAssetsDepreciation ? Number(year3?.financialFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.financialFixedAssetsNet ? Number(year1?.financialFixedAssetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.financialFixedAssetsNet ? Number(year2?.financialFixedAssetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.financialFixedAssetsNet ? Number(year3?.financialFixedAssetsNet).toLocaleString() : "0"}</strong>,

        },
        {
            key: '14',
            asset: <span>{t("Equity investments")}</span>,
            gross1: <span>{year1?.equityInvestmentsGross ? Number(year1?.equityInvestmentsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.equityInvestmentsGross ? Number(year2?.equityInvestmentsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.equityInvestmentsGross ? Number(year3?.equityInvestmentsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.equityInvestmentsDepreciation ? Number(year1?.equityInvestmentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.equityInvestmentsDepreciation ? Number(year2?.equityInvestmentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.equityInvestmentsDepreciation ? Number(year3?.equityInvestmentsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.equityInvestmentsNet ? Number(year1?.equityInvestmentsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.equityInvestmentsNet ? Number(year2?.equityInvestmentsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.equityInvestmentsNet ? Number(year3?.equityInvestmentsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '15',
            asset: <span>{t("Other financial fixed assets (portfolio, long-terms securities)")}</span>,
            gross1: <span>{year1?.otherFinancialFixedAssetsGross ? Number(year1?.otherFinancialFixedAssetsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.otherFinancialFixedAssetsGross ? Number(year2?.otherFinancialFixedAssetsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.otherFinancialFixedAssetsGross ? Number(year3?.otherFinancialFixedAssetsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.otherFinancialFixedAssetsDepreciation ? Number(year1?.otherFinancialFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.otherFinancialFixedAssetsDepreciation ? Number(year2?.otherFinancialFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.otherFinancialFixedAssetsDepreciation ? Number(year3?.otherFinancialFixedAssetsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.otherFinancialFixedAssetsNet ? Number(year1?.otherFinancialFixedAssetsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.otherFinancialFixedAssetsNet ? Number(year2?.otherFinancialFixedAssetsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.otherFinancialFixedAssetsNet ? Number(year3?.otherFinancialFixedAssetsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '16',
            asset: <strong>{t("Total fixed assets")}</strong>,
            gross1: <strong>{year1?.totalFixedAssetsGross ? Number(year1?.totalFixedAssetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.totalFixedAssetsGross ? Number(year2?.totalFixedAssetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.totalFixedAssetsGross ? Number(year3?.totalFixedAssetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.totalFixedAssetsDepreciation ? Number(year1?.totalFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.totalFixedAssetsDepreciation ? Number(year2?.totalFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.totalFixedAssetsDepreciation ? Number(year3?.totalFixedAssetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.totalFixedAssetsNet ? Number(year1?.totalFixedAssetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.totalFixedAssetsNet ? Number(year2?.totalFixedAssetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.totalFixedAssetsNet ? Number(year3?.totalFixedAssetsNet).toLocaleString() : "0"}</strong>,

        },
        {
            key: '17',
            asset: <span>{t("Current assets")}</span>,
            gross1: <span>{year1?.currentAssetsGross ? Number(year1?.currentAssetsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.currentAssetsGross ? Number(year2?.currentAssetsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.currentAssetsGross ? Number(year3?.currentAssetsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.currentAssetsDepreciation ? Number(year1?.currentAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.currentAssetsDepreciation ? Number(year2?.currentAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.currentAssetsDepreciation ? Number(year3?.currentAssetsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.currentAssetsNet ? Number(year1?.currentAssetsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.currentAssetsNet ? Number(year2?.currentAssetsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.currentAssetsNet ? Number(year3?.currentAssetsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '18',
            asset: <span>{t("Inventory and work in progress")}</span>,
            gross1: <span>{year1?.inventoryAndWorkInProgressGross ? Number(year1?.inventoryAndWorkInProgressGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.inventoryAndWorkInProgressGross ? Number(year2?.inventoryAndWorkInProgressGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.inventoryAndWorkInProgressGross ? Number(year3?.inventoryAndWorkInProgressGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.inventoryAndWorkInProgressDepreciation ? Number(year1?.inventoryAndWorkInProgressDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.inventoryAndWorkInProgressDepreciation ? Number(year2?.inventoryAndWorkInProgressDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.inventoryAndWorkInProgressDepreciation ? Number(year3?.inventoryAndWorkInProgressDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.inventoryAndWorkInProgressNet ? Number(year1?.inventoryAndWorkInProgressNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.inventoryAndWorkInProgressNet ? Number(year2?.inventoryAndWorkInProgressNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.inventoryAndWorkInProgressNet ? Number(year3?.inventoryAndWorkInProgressNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '19',
            asset: <strong>{t("Suppliers accounts payable and related accounts")}</strong>,
            gross1: <span>{year1?.suppliersAccountsGross ? Number(year1?.suppliersAccountsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.suppliersAccountsGross ? Number(year2?.suppliersAccountsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.suppliersAccountsGross ? Number(year3?.suppliersAccountsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.suppliersAccountsDepreciation ? Number(year1?.suppliersAccountsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.suppliersAccountsDepreciation ? Number(year2?.suppliersAccountsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.suppliersAccountsDepreciation ? Number(year3?.suppliersAccountsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.suppliersAccountsNet ? Number(year1?.suppliersAccountsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.suppliersAccountsNet ? Number(year2?.suppliersAccountsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.suppliersAccountsNet ? Number(year3?.suppliersAccountsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '20',
            asset: <span>{t("Suppliers Advance payments on account on orders")}</span>,
            gross1: <span>{year1?.suppliersAdvancePaymentsGross ? Number(year1?.suppliersAdvancePaymentsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.suppliersAdvancePaymentsGross ? Number(year2?.suppliersAdvancePaymentsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.suppliersAdvancePaymentsGross ? Number(year3?.suppliersAdvancePaymentsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.suppliersAdvancePaymentsDepreciation ? Number(year1?.suppliersAdvancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.suppliersAdvancePaymentsDepreciation ? Number(year2?.suppliersAdvancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.suppliersAdvancePaymentsDepreciation ? Number(year3?.suppliersAdvancePaymentsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.suppliersAdvancePaymentsNet ? Number(year1?.suppliersAdvancePaymentsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.suppliersAdvancePaymentsNet ? Number(year2?.suppliersAdvancePaymentsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.suppliersAdvancePaymentsNet ? Number(year3?.suppliersAdvancePaymentsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '21',
            asset: <span>{t("Trade Receivable")}</span>,
            gross1: <span>{year1?.tradeReceivableGross ? Number(year1?.tradeReceivableGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.tradeReceivableGross ? Number(year2?.tradeReceivableGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.tradeReceivableGross ? Number(year3?.tradeReceivableGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.tradeReceivableDepreciation ? Number(year1?.tradeReceivableDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.tradeReceivableDepreciation ? Number(year2?.tradeReceivableDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.tradeReceivableDepreciation ? Number(year3?.tradeReceivableDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.tradeReceivableNet ? Number(year1?.tradeReceivableNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.tradeReceivableNet ? Number(year2?.tradeReceivableNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.tradeReceivableNet ? Number(year3?.tradeReceivableNet).toLocaleString() : "0"}</span>,
        },
        {
            key: '22',
            asset: <span>{t("Other Receivables")}</span>,
            gross1: <span>{year1?.otherReceivablesGross ? Number(year1?.otherReceivablesGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.otherReceivablesGross ? Number(year2?.otherReceivablesGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.otherReceivablesGross ? Number(year3?.otherReceivablesGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.otherReceivablesDepreciatio ? Number(year1?.otherReceivablesDepreciatio).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.otherReceivablesDepreciatio ? Number(year2?.otherReceivablesDepreciatio).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.otherReceivablesDepreciatio ? Number(year3?.otherReceivablesDepreciatio).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.otherReceivablesNet ? Number(year1?.otherReceivablesNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.otherReceivablesNet ? Number(year2?.otherReceivablesNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.otherReceivablesNet ? Number(year3?.otherReceivablesNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '23',
            asset: <strong>{t("Total current assets")}</strong>,
            gross1: <strong>{year1?.totalCurrentAssetsGross ? Number(year1?.totalCurrentAssetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.totalCurrentAssetsGross ? Number(year2?.totalCurrentAssetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.totalCurrentAssetsGross ? Number(year3?.totalCurrentAssetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.totalCurrentAssetsDepreciation ? Number(year1?.totalCurrentAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.totalCurrentAssetsDepreciation ? Number(year2?.totalCurrentAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.totalCurrentAssetsDepreciation ? Number(year3?.totalCurrentAssetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.totalCurrentAssetsNet ? Number(year1?.totalCurrentAssetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.totalCurrentAssetsNet ? Number(year2?.totalCurrentAssetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.totalCurrentAssetsNet ? Number(year3?.totalCurrentAssetsNet).toLocaleString() : "0"}</strong>,

        },
        {
            key: '24',
            asset: <span>{t("Shares and Marketable Securities")}</span>,
            gross1: <span>{year1?.sharesAndMarketableSecuritiesGross ? Number(year1?.sharesAndMarketableSecuritiesGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.sharesAndMarketableSecuritiesGross ? Number(year2?.sharesAndMarketableSecuritiesGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.sharesAndMarketableSecuritiesGross ? Number(year3?.sharesAndMarketableSecuritiesGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.sharesAndMarketableSecuritiesDepreciation ? Number(year1?.sharesAndMarketableSecuritiesDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.sharesAndMarketableSecuritiesDepreciation ? Number(year2?.sharesAndMarketableSecuritiesDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.sharesAndMarketableSecuritiesDepreciation ? Number(year3?.sharesAndMarketableSecuritiesDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.sharesAndMarketableSecuritiesNet ? Number(year1?.sharesAndMarketableSecuritiesNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.sharesAndMarketableSecuritiesNet ? Number(year2?.sharesAndMarketableSecuritiesNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.sharesAndMarketableSecuritiesNet ? Number(year3?.sharesAndMarketableSecuritiesNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '25',
            asset: <span>{t("Cash and cash equivalents")}</span>,
            gross1: <span>{year1?.cashAndCashEquivalentsGross ? Number(year1?.cashAndCashEquivalentsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.cashAndCashEquivalentsGross ? Number(year2?.cashAndCashEquivalentsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.cashAndCashEquivalentsGross ? Number(year3?.cashAndCashEquivalentsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.cashAndCashEquivalentsDepreciation ? Number(year1?.cashAndCashEquivalentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.cashAndCashEquivalentsDepreciation ? Number(year2?.cashAndCashEquivalentsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.cashAndCashEquivalentsDepreciation ? Number(year3?.cashAndCashEquivalentsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.cashAndCashEquivalentsNet ? Number(year1?.cashAndCashEquivalentsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.cashAndCashEquivalentsNet ? Number(year2?.cashAndCashEquivalentsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.cashAndCashEquivalentsNet ? Number(year3?.cashAndCashEquivalentsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '26',
            asset: <span>{year1?.banksFinancialGross ? Number(year1?.banksFinancialGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.banksFinancialGross ? Number(year2?.banksFinancialGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.banksFinancialGross ? Number(year3?.banksFinancialGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.banksFinancialDepreciation ? Number(year1?.banksFinancialDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.banksFinancialDepreciation ? Number(year2?.banksFinancialDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.banksFinancialDepreciation ? Number(year3?.banksFinancialDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.banksFinancialNet ? Number(year1?.banksFinancialNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.banksFinancialNet ? Number(year2?.banksFinancialNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.banksFinancialNet ? Number(year3?.banksFinancialNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '27',
            asset: <strong>{t("Cash and financial instruments")}</strong>,
            gross1: <strong>{year1?.cashandFinancialIInstrumentsGross ? Number(year1?.cashandFinancialIInstrumentsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.cashandFinancialIInstrumentsGross ? Number(year2?.cashandFinancialIInstrumentsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.cashandFinancialIInstrumentsGross ? Number(year3?.cashandFinancialIInstrumentsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.cashandFinancialIInstrumentsDepreciation ? Number(year1?.cashandFinancialIInstrumentsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.cashandFinancialIInstrumentsDepreciation ? Number(year2?.cashandFinancialIInstrumentsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.cashandFinancialIInstrumentsDepreciation ? Number(year3?.cashandFinancialIInstrumentsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.cashandFinancialIInstrumentsNet ? Number(year1?.cashandFinancialIInstrumentsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.cashandFinancialIInstrumentsNet ? Number(year2?.cashandFinancialIInstrumentsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.cashandFinancialIInstrumentsNet ? Number(year3?.cashandFinancialIInstrumentsNet).toLocaleString() : "0"}</strong>,
        },
        {
            key: '28',
            asset: <span>{t("Valuation difference Assets")}</span>,
            gross1: <span>{year1?.valuationDifferenceAssetsGross ? Number(year1?.valuationDifferenceAssetsGross).toLocaleString() : "0"}</span>,
            gross2: <span>{year2?.valuationDifferenceAssetsGross ? Number(year2?.valuationDifferenceAssetsGross).toLocaleString() : "0"}</span>,
            gross3: <span>{year3?.valuationDifferenceAssetsGross ? Number(year3?.valuationDifferenceAssetsGross).toLocaleString() : "0"}</span>,
            depreciation1: <span>{year1?.valuationDifferenceAssetsDepreciation ? Number(year1?.valuationDifferenceAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation2: <span>{year2?.valuationDifferenceAssetsDepreciation ? Number(year2?.valuationDifferenceAssetsDepreciation).toLocaleString() : "0"}</span>,
            depreciation3: <span>{year3?.valuationDifferenceAssetsDepreciation ? Number(year3?.valuationDifferenceAssetsDepreciation).toLocaleString() : "0"}</span>,
            net1: <span>{year1?.valuationDifferenceAssetsNet ? Number(year1?.valuationDifferenceAssetsNet).toLocaleString() : "0"}</span>,
            net2: <span>{year2?.valuationDifferenceAssetsNet ? Number(year2?.valuationDifferenceAssetsNet).toLocaleString() : "0"}</span>,
            net3: <span>{year3?.valuationDifferenceAssetsNet ? Number(year3?.valuationDifferenceAssetsNet).toLocaleString() : "0"}</span>,

        },
        {
            key: '29',
            asset: <strong>{t("Total assets")}</strong>,
            gross1: <strong>{year1?.totalAssetsGross ? Number(year1?.totalAssetsGross).toLocaleString() : "0"}</strong>,
            gross2: <strong>{year2?.totalAssetsGross ? Number(year2?.totalAssetsGross).toLocaleString() : "0"}</strong>,
            gross3: <strong>{year3?.totalAssetsGross ? Number(year3?.totalAssetsGross).toLocaleString() : "0"}</strong>,
            depreciation1: <strong>{year1?.totalAssetsDepreciation ? Number(year1?.totalAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation2: <strong>{year2?.totalAssetsDepreciation ? Number(year2?.totalAssetsDepreciation).toLocaleString() : "0"}</strong>,
            depreciation3: <strong>{year3?.totalAssetsDepreciation ? Number(year3?.totalAssetsDepreciation).toLocaleString() : "0"}</strong>,
            net1: <strong>{year1?.totalAssetsNet ? Number(year1?.totalAssetsNet).toLocaleString() : "0"}</strong>,
            net2: <strong>{year2?.totalAssetsNet ? Number(year2?.totalAssetsNet).toLocaleString() : "0"}</strong>,
            net3: <strong>{year3?.totalAssetsNet ? Number(year3?.totalAssetsNet).toLocaleString() : "0"}</strong>,
        }
    ];

    useEffect(() => {
        if (props?.user?.token) {
            getUserDataF();
            getDefaultData();
        }
    }, [props?.user?.token, userId])

    const getUserDataF = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: props?.user?.token,

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setData1(res?.data)
                        setValue(res?.data?.balanceRating?.rating)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const getDefaultData = () => {
        if (userId)
            if (!loading) {
                setLoading(true);
                get("/balance_sheet/default?userId=" + userId, { token: user?.token })
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            res?.data?.forEach((element, index) => {
                                if (index == 0) {
                                    setYear1(element);
                                    props?.setBalanceSheetYear((prevProYear) => ({
                                        ...prevProYear,
                                        year1: element,
                                    }));
                                }
                                if (index == 1) {
                                    setYear2(element);
                                    props?.setBalanceSheetYear((prevProYear) => ({
                                        ...prevProYear,
                                        year2: element,
                                    }));
                                }
                                if (index == 2) {
                                    setYear3(element);
                                    props?.setBalanceSheetYear((prevProYear) => ({
                                        ...prevProYear,
                                        year3: element,
                                    }));
                                }
                            });
                        }
                    })
            }
    }

    function handleValidSubmit(e, v) {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: props?.user?.token,
                balanceRating: {
                    comment: v?.balanceComment,
                    rating: value
                },
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserDataF();
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }


    return (
        <React.Fragment>

            <Loader loading={loading} />
            <AvForm
                id="myForm"
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table dataSource={data}
                                    scroll={{
                                        x: window?.innerWidth < 991 ? 1000 : 0,
                                        y: 450
                                    }}
                                    pagination={false}
                                    className='view_table'
                                >
                                    <Column fixed={window?.innerWidth < 991 ? '' : 'left'} style={{ width: '30%' }} title={t("Asset")} dataIndex="asset" key="asset" />
                                    <ColumnGroup title={<> <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1, props?.setBalanceSheetYear, 1)} value={year1?.year}>
                                        <option value="">{t("Select Year")}</option>
                                        {years?.map((item) => (
                                            <option value={item?.value}>{item?.label}</option>
                                        ))}
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross1" key="gross1" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation1" key="depreciation1" />
                                        <Column title="Net" dataIndex="net1" key="net1" />
                                    </ColumnGroup>
                                    <ColumnGroup title={<> <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2, props?.setBalanceSheetYear, 2)} value={year2?.year}>
                                        <option value="">{t("Select Year")}</option>
                                        {years?.map((item) => (
                                            <option value={item?.value}>{item?.label}</option>
                                        ))}
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross2" key="gross2" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation2" key="depreciation2" />
                                        <Column title="Net" dataIndex="net2" key="net2" />
                                    </ColumnGroup>
                                    <ColumnGroup title={<> <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3, props?.setBalanceSheetYear, 3)} value={year3?.year}>
                                        <option value="">{t("Select Year")}</option>
                                        {years?.map((item) => (
                                            <option value={item?.value}>{item?.label}</option>
                                        ))}
                                    </AvField></>}>
                                        <Column title={t("Gross")} dataIndex="gross3" key="gross3" />
                                        <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation3" key="depreciation3" />
                                        <Column title="Net" dataIndex="net3" key="net3" />
                                    </ColumnGroup>

                                </Table>
                            </Col>

                        </Row>

                    </CardBody>
                </Card>
                <h4 className="card-title mb-4">{t("Liabilities")}</h4>
                <Card>
                    <CardBody className='table-resp'>

                        <Row>
                            <Col lg={12}>

                                <TableData
                                    className='common_table'
                                    bordered
                                    style={{ marginBottom: 0 }}
                                >
                                    <thead >

                                        <tr className='right_head'>

                                            <th style={{ width: '50%', textAlign: 'left' }}>
                                                {t("Liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_one" onChange={(e) => getUserData1(e.target.value, setYear_1, props?.setLiabilitiesYear, 1)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        {years?.map((item) => (
                                                            <option value={item?.value}>{item?.label}</option>
                                                        ))}
                                                    </AvField>
                                                </AvForm>
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_two" onChange={(e) => getUserData1(e.target.value, setYear_2, props?.setLiabilitiesYear, 2)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        {years?.map((item) => (
                                                            <option value={item?.value}>{item?.label}</option>
                                                        ))}
                                                    </AvField>
                                                </AvForm>
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>
                                                <AvForm>
                                                    <AvField type="select" name="year_three" onChange={(e) => getUserData1(e.target.value, setYear_3, props?.setLiabilitiesYear, 3)} >
                                                        <option value="">{t("Select Year")}</option>
                                                        {years?.map((item) => (
                                                            <option value={item?.value}>{item?.label}</option>
                                                        ))}
                                                    </AvField>
                                                </AvForm>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t("Capital")}
                                            </td>
                                            <td>
                                                {year_1?.capital ? Number(year_1?.capital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.capital ? Number(year_2?.capital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.capital ? Number(year_3?.capital).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Shareholders subscribed uncalled capital")}
                                            </td>
                                            <td>
                                                {year_1?.shareholdersSubscribedUncalledCapital ? Number(year_1?.shareholdersSubscribedUncalledCapital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.shareholdersSubscribedUncalledCapital ? Number(year_2?.shareholdersSubscribedUncalledCapital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.shareholdersSubscribedUncalledCapital ? Number(year_3?.shareholdersSubscribedUncalledCapital).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Premiums related to share capital")}
                                            </td>
                                            <td>
                                                {year_1?.premiumsRelatedtoShareCapital ? Number(year_1?.premiumsRelatedtoShareCapital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.premiumsRelatedtoShareCapital ? Number(year_2?.premiumsRelatedtoShareCapital).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.premiumsRelatedtoShareCapital ? Number(year_3?.premiumsRelatedtoShareCapital).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Revaluation difference")}
                                            </td>
                                            <td>
                                                {year_1?.revaluationDifference ? Number(year_1?.revaluationDifference).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.revaluationDifference ? Number(year_2?.revaluationDifference).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.revaluationDifference ? Number(year_3?.revaluationDifference).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Reserves not available for distribution")}
                                            </td>
                                            <td>
                                                {year_1?.reservesNotAvailableForDistribution ? Number(year_1?.reservesNotAvailableForDistribution).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.reservesNotAvailableForDistribution ? Number(year_2?.reservesNotAvailableForDistribution).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.reservesNotAvailableForDistribution ? Number(year_3?.reservesNotAvailableForDistribution).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Legal Reserve")}
                                            </td>
                                            <td>
                                                {year_1?.legalReserve ? Number(year_1?.legalReserve).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.legalReserve ? Number(year_2?.legalReserve).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.legalReserve ? Number(year_3?.legalReserve).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Retained Earnings")}
                                            </td>
                                            <td>
                                                {year_1?.retainedEarnings ? Number(year_1?.retainedEarnings).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.retainedEarnings ? Number(year_2?.retainedEarnings).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.retainedEarnings ? Number(year_3?.retainedEarnings).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Profit or loss for the financial year")}
                                            </td>
                                            <td>
                                                {year_1?.profitOrLossForTheFinancialYear ? Number(year_1?.profitOrLossForTheFinancialYear).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.profitOrLossForTheFinancialYear ? Number(year_2?.profitOrLossForTheFinancialYear).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.profitOrLossForTheFinancialYear ? Number(year_3?.profitOrLossForTheFinancialYear).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Investment grants")}
                                            </td>
                                            <td>
                                                {year_1?.investmentGrants ? Number(year_1?.investmentGrants).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.investmentGrants ? Number(year_2?.investmentGrants).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.investmentGrants ? Number(year_3?.investmentGrants).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Regulated provisions")}
                                            </td>
                                            <td>
                                                {year_1?.regulatedProvisions ? Number(year_1?.regulatedProvisions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.regulatedProvisions ? Number(year_2?.regulatedProvisions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.regulatedProvisions ? Number(year_3?.regulatedProvisions).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total Owner's Equity")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalOwnersEquity ? Number(year_1?.totalOwnersEquity).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalOwnersEquity ? Number(year_2?.totalOwnersEquity).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalOwnersEquity ? Number(year_3?.totalOwnersEquity).toLocaleString() : "0"}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Loans and debt from financial institutions")}
                                            </td>
                                            <td>
                                                {year_1?.loansandDebtFromFinancialInstitutions ? Number(year_1?.loansandDebtFromFinancialInstitutions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.loansandDebtFromFinancialInstitutions ? Number(year_2?.loansandDebtFromFinancialInstitutions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.loansandDebtFromFinancialInstitutions ? Number(year_3?.loansandDebtFromFinancialInstitutions).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Leasing debt")}
                                            </td>
                                            <td>
                                                {year_1?.leasingDebt ? Number(year_1?.leasingDebt).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.leasingDebt ? Number(year_2?.leasingDebt).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.leasingDebt ? Number(year_3?.leasingDebt).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Provisions")}
                                            </td>
                                            <td>
                                                {year_1?.provisions ? Number(year_1?.provisions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.provisions ? Number(year_2?.provisions).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.provisions ? Number(year_3?.provisions).toLocaleString() : "0"}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                {t("Total Dettes Financieres Et Ressources Assimilees")}
                                            </td>
                                            <td>
                                                {year_1?.totalDettesFinancieresEtRessourcesAssimilees ? Number(year_1?.totalDettesFinancieresEtRessourcesAssimilees).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.totalDettesFinancieresEtRessourcesAssimilees ? Number(year_2?.totalDettesFinancieresEtRessourcesAssimilees).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.totalDettesFinancieresEtRessourcesAssimilees ? Number(year_3?.totalDettesFinancieresEtRessourcesAssimilees).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Fixed Liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.fixedLiabilities ? Number(year_1?.fixedLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.fixedLiabilities ? Number(year_2?.fixedLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.fixedLiabilities ? Number(year_3?.fixedLiabilities).toLocaleString() : "0"}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Current debt")}
                                            </td>
                                            <td>
                                                {year_1?.currentDebt ? Number(year_1?.currentDebt).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.currentDebt ? Number(year_2?.currentDebt).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.currentDebt ? Number(year_3?.currentDebt).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Advance payments on account received on orders in progress")}
                                            </td>
                                            <td>
                                                {year_1?.advancePaymentsonAccountReceivedonOrdersInProgress ? Number(year_1?.advancePaymentsonAccountReceivedonOrdersInProgress).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.advancePaymentsonAccountReceivedonOrdersInProgress ? Number(year_2?.advancePaymentsonAccountReceivedonOrdersInProgress).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.advancePaymentsonAccountReceivedonOrdersInProgress ? Number(year_3?.advancePaymentsonAccountReceivedonOrdersInProgress).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Accounts payable and related accounts")}
                                            </td>
                                            <td>
                                                {year_1?.accountspayableAndRelatedAccounts ? Number(year_1?.accountspayableAndRelatedAccounts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.accountspayableAndRelatedAccounts ? Number(year_2?.accountspayableAndRelatedAccounts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.accountspayableAndRelatedAccounts ? Number(year_3?.accountspayableAndRelatedAccounts).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Tax and social security debts")}
                                            </td>
                                            <td>
                                                {year_1?.taxAndSocialSecurityDebts ? Number(year_1?.taxAndSocialSecurityDebts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.taxAndSocialSecurityDebts ? Number(year_2?.taxAndSocialSecurityDebts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.taxAndSocialSecurityDebts ? Number(year_3?.taxAndSocialSecurityDebts).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Other loans and similar debts")}
                                            </td>
                                            <td>
                                                {year_1?.otherLoansAndSimilarDebts ? Number(year_1?.otherLoansAndSimilarDebts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.otherLoansAndSimilarDebts ? Number(year_2?.otherLoansAndSimilarDebts).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.otherLoansAndSimilarDebts ? Number(year_3?.otherLoansAndSimilarDebts).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Unrealized foreign exchange gains and valuation differences")}
                                            </td>
                                            <td>
                                                {year_1?.unrealizedForeignExchangeGainsAndValuationDifferences ? Number(year_1?.unrealizedForeignExchangeGainsAndValuationDifferences).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.unrealizedForeignExchangeGainsAndValuationDifferences ? Number(year_2?.unrealizedForeignExchangeGainsAndValuationDifferences).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.unrealizedForeignExchangeGainsAndValuationDifferences ? Number(year_3?.unrealizedForeignExchangeGainsAndValuationDifferences).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Current liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.currentLiabilities ? Number(year_1?.currentLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.currentLiabilities ? Number(year_2?.currentLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.currentLiabilities ? Number(year_3?.currentLiabilities).toLocaleString() : "0"}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Other payables")}
                                            </td>
                                            <td>
                                                {year_1?.otherPayables ? Number(year_1?.otherPayables).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.otherPayables ? Number(year_2?.otherPayables).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.otherPayables ? Number(year_3?.otherPayables).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Short-term bank advances")}
                                            </td>
                                            <td>
                                                {year_1?.shortTermBankAdvances ? Number(year_1?.shortTermBankAdvances).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.shortTermBankAdvances ? Number(year_2?.shortTermBankAdvances).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.shortTermBankAdvances ? Number(year_3?.shortTermBankAdvances).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total tresorerie-passif")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalCashLiabilities ? Number(year_1?.totalCashLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalCashLiabilities ? Number(year_2?.totalCashLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalCashLiabilities ? Number(year_3?.totalCashLiabilities).toLocaleString() : "0"}</th>

                                        </tr>
                                        <tr>
                                            <td>
                                                {t("Valuation difference - Liabilities")}
                                            </td>
                                            <td>
                                                {year_1?.valuationDifferenceLiabilities ? Number(year_1?.valuationDifferenceLiabilities).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_2?.valuationDifferenceLiabilities ? Number(year_2?.valuationDifferenceLiabilities).toLocaleString() : "0"}
                                            </td>
                                            <td>
                                                {year_3?.valuationDifferenceLiabilities ? Number(year_3?.valuationDifferenceLiabilities).toLocaleString() : "0"}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head'>

                                            <th style={{ width: '50%' }}>
                                                {t("Total liabilities")}
                                            </th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_1?.totalLiabilities ? Number(year_1?.totalLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_2?.totalLiabilities ? Number(year_2?.totalLiabilities).toLocaleString() : "0"}</th>
                                            <th style={{ width: '16.66666666666667%' }}>{year_3?.totalLiabilities ? Number(year_3?.totalLiabilities).toLocaleString() : "0"}</th>
                                        </tr>
                                    </tbody>
                                </TableData>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>



            </AvForm>


        </React.Fragment>
    )
}



export default BalanceSheetReport;



