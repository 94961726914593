import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import avatar1 from "../../assets/images/users/user-1.jpg";
import Loader from 'components/Loader';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import DeleteModal from 'components/DeleteModal';
import moment from 'moment';

const Investor = (props) => {
    let history = useHistory();
    let { user } = props;
    const [selectedSector, setSelectedSector] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [selectedDevelopGoal, setSelectedDevelopGoals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [investerData, setInvesterData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { t, i18n } = useTranslation();
    const [searchStr, setSearchStr] = useState("");
    const [active, setActive] = useState()
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [profileData, setProfileData] = useState();
    const businessSector = Utils.getBusinessSector();
    const [avgRating, setAvgRating] = useState(0)
    const [weight, setWeight] = useState([]);
    const regionoptions = Utils.getRegions();
    const [succeesModal, setSucceesModal] = useState(false)
    const [smeID, setSmeID] = useState(undefined);
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Investor List", link: "#" },
    ]
    const sdgOptions = Utils.getSDG();

    useEffect(() => {
        props.setBreadcrumbItems('Investor List', breadcrumbItems)
    }, []);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user, selectedSector, searchStr]);

    useEffect(() => {
        if (user?.token) {
            getUserData();

        }
    }, [user, i18n?.language])

    const handleClose = () => setProfileModal(false);
    const handleClose1 = () => setSucceesModal(false);
    const handleShow = () => setProfileModal(true);

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token,

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        setInvesterData(res?.data);

                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const getData = () => {
        if (!loading) {
            setLoading(true);
            let sectors = [];
            selectedSector?.forEach((item) => {
                sectors.push(item?.value);
            })
            let goals = [];
            selectedDevelopGoal?.forEach((item) => {
                goals.push(item?.value);
            })
            let body = {
                search: searchStr,
                businessSector: sectors,
                developmentGoals: goals,
                token: user?.token
            }
            post("/user/companies", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.error('While getting SME list', err);
                    toast.error("Something Went Wrong!");
                })
        }
    }


    useEffect(() => {
        if (user?.token) {

            get('/weight/list', { token: user?.token }).
                then((res) => {
                    if (res?.statusCode == 200) {
                        setWeight(res?.data);
                    }
                })
        }
    }, [user])

    const calculateRating = (row) => {
        let total = 0;
        if (row?.profRating?.rating) {
            let per = Number(weight?.profitAndLoss) / 100;
            total = total + (Number(row?.profRating?.rating) * per);

        }

        if (row?.esgRating?.rating) {
            let per = Number(weight?.eSG) / 100;
            total = total + (Number(row?.esgRating?.rating) * per)

        }
        if (row?.financeRating?.rating) {
            let per = Number(weight?.financingRequest) / 100;
            total = total + (Number(row?.financeRating?.rating) * per)

        }
        if (row?.cashFlowRating?.rating) {
            let per = Number(weight?.cashFlow) / 100;
            total = total + (Number(row?.cashFlowRating?.rating) * per)

        }
        if (row?.balanceRating?.rating) {
            let per = Number(weight?.balanceSheet) / 100;
            total = total + (Number(row?.balanceRating?.rating) * per)

        }
        if (row?.nuroRating?.rating) {
            let per = Number(weight?.nuroProfiler) / 100;
            total = total + (Number(row?.nuroRating?.rating) * per)

        }

        if (row?.ratioRating?.rating) {
            let per = Number(weight?.ratio) / 100;
            total = total + (Number(row?.ratioRating?.rating) * per)

        }
        return total;

    }



    useEffect(() => {
        setColumns([
            {
                name: t("Username"),
                selector: (row, index) => row?.username,
                maxWidth: '30px'
            },
            {
                name: t("Company Name"),
                selector: row => row.companyName,
                maxWidth: '200px'
            },
            {
                name: t("Business start date"),
                selector: row => t(moment(row.businessStartDate).format("YYYY-MM-DD"))
            },
            {
                name: t("Country"),
                selector: row => row.businessCountry,
                maxWidth: '200px'
            },
            {
                name: t("Financing Needed"),
                selector: row => <span title={row?.typeOfNeed?.length ? row?.typeOfNeed[0]?.form[0]?.answer : ""}>
                    {row?.typeOfNeed?.length ? row?.typeOfNeed[0]?.form[0]?.answer : ""}
                </span>,
            },
            {
                name: 'Action',
                selector: row =>
                    <div className='action_box'>

                        <button onClick={() => { handleShow(); setSmeID(row?._id); setProfileData(row) }} ><i class="mdi mdi-account-convert" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Connect to SME")} ></i></button>
                        {/* <button onClick={() => history.push('/search-companie', { userId: row?._id })}><i class="mdi mdi-account-convert" data-bs-toggle="tooltip" data-bs-placement="top" title={t("More Details")}></i></button> */}
                        <button onClick={() => addFavorite(row?._id)}><i class="mdi mdi-heart" style={{ color: row?.liked ? 'red' : '#fff' }} data-bs-toggle="tooltip" data-bs-placement="top" title="Like"></i></button>
                    </div>,
                maxWidth: '200px'
            },
        ]);
    }, [data, i18n.language, weight]);

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    console.log('profileData', profileData)

    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <Table bordered>
                <tbody>
                    <tr>
                        <th style={{ width: '50%' }}>SDG</th>
                        <td style={{ width: '50%' }}>
                            <div className='result_exp'>
                                {
                                    data?.developmentGoals.map((item) => (
                                        <span>{item}, {''}</span>
                                    ))
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ width: '50%' }}><h5 className=''>Financing Needed</h5></th>
                        <td style={{ width: '50%' }}> <p className='mb-0'>{data?.typeOfNeed[0]?.form[0]?.answer}</p></td>
                    </tr>
                    <tr>
                        <th style={{ width: '50%' }}><h5 className=''>Requested amount of financing</h5></th>
                        <td style={{ width: '50%' }}> <p className='mb-0'>{data?.typeOfNeed[0]?.form[1]?.answer}</p></td>
                    </tr>

                    {/* <tr>
                        <th style={{ width: '50%' }}><h5 className=''>Average annual revenue for the past 3 years</h5></th>
                        <td style={{ width: '50%' }}><p className='mb-0'>{data?.typeOfNeed[0]?.form[7]?.answer}</p></td>
                    </tr> */}
                </tbody>
            </Table>
        </div>;


    const handleStatusChange = (row) => {
        setCurrentData({ ...row, actionType: 'Approve' });
        setDeleteModal(true);
    }

    const onStatus = () => {
        if (!loading) {
            setLoading(true)
            const body = {
                token: user?.token,
                isApproved: !currentData?.isApproved,
                userId: currentData?._id
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res?.data?.isApproved);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        setDeleteModal(false);
                        setCurrentData(null);
                        getData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const addFavorite = (id) => {
        if (!loading) {
            let body = {
                smeId: id,
                token: user?.token
            }
            post('/user/add_fav_company', body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        getData()
                        toast.success(res?.message)
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.error('While getting SME list', err);
                    toast.error("Something Went Wrong!");
                })
        }

    }

    const formatEmail = (email) => {
        if (email) {
            const [username, domain] = email.split('@');
            const formattedUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
            return `${formattedUsername}@${domain}`;
        } else
            return '';
    };

    const formatPhoneNumber = (phoneNumber) => {
        const phoneNumberStr = String(phoneNumber);
        const formattedPhoneNumber = phoneNumberStr.charAt(0) + '*'.repeat(phoneNumberStr.length - 2) + phoneNumberStr.charAt(phoneNumberStr.length - 1);
        return formattedPhoneNumber;
    };


    const conectFunction = () => {
        let body = {
            token: user?.token,
            smeId: smeID
        }
        if (smeID) {
            post('/user/connect_sme', body)
                .then((res) => {
                    console.log('res');
                    if (res?.statusCode == 200) {
                        // toast.success(res?.message);
                        setSucceesModal(true)
                        setProfileModal(false)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={onStatus}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <Modal centered show={profileModal} className='model_profile model_profile_investor' >
                <Modal.Header onHide={handleClose} closeButton>
                    <Modal.Title>Sme {t("Profile")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <div className=''>

                        <ul className='profile_detail_i'>
                            <li>
                                <strong>{t("Username")}</strong> : {profileData?.username}
                            </li>
                            <li>
                                <strong>{t("Name")}</strong> : {profileData?.fname} {investerData?.lname}
                            </li>
                            <li>
                                <strong>{t("Email")}</strong> : {profileData?.email}
                            </li>
                            <li>
                                <strong>{t("Company Name")}</strong> : {profileData?.companyName}
                            </li>
                            <li>
                                <strong>{t("Country")}</strong> : {profileData?.businessCountry}
                            </li>
                        </ul>
                        {/* <hr /> */}
                    </div>
                    <Modal.Title className='py-3'>INVESTOR {t("Profile")}</Modal.Title>
                    <div className=''>
                        <ul className='profile_detail_i'>
                            <li>
                                <strong>{t("Username")}</strong> : {investerData?.username}
                            </li>
                            <li>
                                <strong>{t("Name")}</strong> : {investerData?.fname} {investerData?.lname}
                            </li>
                            <li>
                                <strong>{t("Email")}</strong> : {investerData?.email}
                            </li>

                            <li>
                                <strong>{t("Company Name")}</strong> : {investerData?.companyName}
                            </li>
                            <li>
                                <strong>{t("Country")}</strong> : {investerData?.businessCountry}
                            </li>
                        </ul>
                        {/* <hr /> */}
                        <button onClick={conectFunction} className='apply_btn_b profile_detail_i_btn'>Connect</button>
                    </div>
                </Modal.Body>

            </Modal>
            <Modal centered show={succeesModal} className='succees_popup-investor' >
                <Modal.Header onHide={handleClose1} closeButton>
                </Modal.Header>
                <Modal.Body className=''>
                    <div class="page_wrapper page_wrapper_page_sme">
                        <div className='success_box'>
                            <div class="custom-modal">
                                <div class="succes succes-animation icon-top"><i class="fa fa-check"></i></div>
                                <div class="border-bottom"></div>
                                <p className="success-paragraph px-3">
                                    Thank you for showing your interest in the SME. You will soon receive update from the Demleen team on your registered email.
                                </p>

                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody >
                            <div className='investor_component'>
                                <div className='search_section'>
                                    <h3 className='Filter'>Filter <i class="mdi mdi-filter-variant"></i></h3>
                                    <AvForm>
                                        <Row>
                                            <Col lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                    {/* <label>{t("Sector/Industry supported")}</label> */}
                                                    <MultiSelect
                                                        options={businessSector} // Options to display in the dropdown
                                                        hasSelectAll={true}
                                                        value={selectedSector}
                                                        onChange={setSelectedSector}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Sector..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            {/* <Col lg={2} md={4}>
                                                <div className="form-group mb-3">
                                                    <MultiSelect
                                                        options={regionoptions}
                                                        hasSelectAll={true}
                                                        value={selectedRegion}
                                                        onChange={setSelectedRegion}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Region..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}

                                                    />
                                                </div>
                                            </Col> */}
                                            <Col lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                    {/* <label>{t("Investment Region in Africa")}</label> */}
                                                    <MultiSelect
                                                        options={sdgOptions} // Options to display in the dropdown
                                                        hasSelectAll={true}
                                                        value={selectedDevelopGoal}
                                                        onChange={setSelectedDevelopGoals}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select SDG..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={1}>
                                                <button className='apply_btn_b' onClick={getData}>{t("Apply")}</button>
                                            </Col>
                                            <Col lg={3} md={5}>
                                                <AvField type="text" name="Search" placeholder={t("Search")} value={searchStr} onChange={(e => setSearchStr(e.target.value))} />
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Investor)
)