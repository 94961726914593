import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Table,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux"
import { withRouter, Prompt, useLocation } from "react-router-dom";
import { profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { get, post } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
// import { withTranslation } from "react-i18next"

const Index = props => {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState([]);
    const [buttonStatus, setButtonStatus] = useState(false)
    const formRef = useRef();
    const [type, setType] = useState('save');
    const { user } = props;
    const { i18n, t } = useTranslation();
    const field1Ref = useRef(null);
    const [helpModal, setHelpModal] = useState(false);
    const [minimumMonthlyProfit, setMinimumMonthlyProfit] = useState(0)
    const [averageMonthlyProfit, setAverageMonthlyProfit] = useState(0)
    const [maximumAmount, setMaximumAmount] = useState(0)
    const [restKey, seRestKey] = useState(undefined)
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Financing Request", link: "#" },
    ];
    useEffect(() => {
        props.setBreadcrumbItems('Financing Request', breadcrumbItems)
    }, []);

    const handleInput = (v, q) => {
        let temp = Object.assign([], form);
        let already = temp?.findIndex(x => x.quesId == q)
        if (already == -1) {
            temp.push({ quesId: q, answer: v })
        }
        else {
            temp[already].answer = v
        }
        setForm(temp);
    }

    useEffect(() => {
        if (props?.user?.token)
            getUserData(location?.state?.state);
        console.log('location?.state?.state', location?.state?.state)
    }, [props])

    const getUserData = (userID) => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/finance/question/list', body)
                .then((res => {
                    setLoading(false);
                    console.log('esg for sme question list', res)
                    if (res?.statusCode == 200) {
                        get(`/finance/answer/user?userId=${user?._id}`, { token: user?.token })
                            .then(ansRes => {
                                if (ansRes?.statusCode == 200) {
                                    let formTemp = [];
                                    res?.data?.map((item) => {
                                        if (ansRes?.data?.length > 0) {
                                            ansRes?.data?.map(ans => {
                                                if (ans?.questions?.length > 0) {
                                                    ans?.questions.map(ques => {
                                                        item?.questions?.map(ques2 => {
                                                            // ques2.answer = '';
                                                            if (ques?._id == ques2?._id) {
                                                                ques2.isOpen = false;
                                                                ques2.answer = userID == undefined ? null : ques?.answer;
                                                                formTemp.push({ quesId: ques?._id, answer: ques?.answer });
                                                                // console.log("question type", ques2?.type, ques?.answer);
                                                                if (ques2?.type != "text") {
                                                                    if (ques.answer != "No") {
                                                                        ques2.isOpen = true;
                                                                    } else {
                                                                        ques2.answer = "No";
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    });
                                    setForm(formTemp);
                                    setData(res?.data)
                                    if (userID) {
                                        seRestKey(ansRes?.restKey)
                                        setMinimumMonthlyProfit(ansRes?.restKey?.jFine)
                                        setAverageMonthlyProfit(ansRes?.restKey?.kFine)
                                        setMaximumAmount(ansRes?.restKey?.nFine)
                                    }
                                }
                            })
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const onChangeSetValue = () => {
        jFinanceFun()
        kFinanceFun()
    }

    const jFinanceFun = () => {
        let jValue = Number(formRef.current?._inputs?.eFine?.value);
        jValue = jValue - Number(formRef.current?._inputs?.gFine?.value);
        jValue = jValue - Number(formRef.current?._inputs?.hFine?.value);
        jValue = jValue - Number(formRef.current?._inputs?.iFine?.value);
        setMinimumMonthlyProfit(jValue)

    }

    const kFinanceFun = () => {
        let kValue = Number(formRef.current?._inputs?.fFine?.value);
        kValue = kValue - Number(formRef.current?._inputs?.gFine?.value);
        kValue = kValue - Number(formRef.current?._inputs?.hFine?.value);
        kValue = kValue - Number(formRef.current?._inputs?.iFine?.value);
        setAverageMonthlyProfit(kValue)

    }

    useEffect(() => {
        if (minimumMonthlyProfit) {
            const calculated = (50 / 100) * minimumMonthlyProfit;
            setMaximumAmount(calculated)
        }
    }, [minimumMonthlyProfit])

    // console.log('onchange form', form);

    function handleValidSubmit(event, values) {
        if (!loading) {
            let body = {
                userId: user?._id,
                token: user?.token,
                form: form,
                isSubmit: type == 'submit' ? true : undefined,
                eFine: values?.eFine,
                fFine: values?.fFine,
                gFine: values?.gFine,
                hFine: values?.hFine,
                iFine: values?.iFine,
                jFine: values?.jFine,
                kFine: values?.kFine,
                lFine: values?.lFine,
                mFine: values?.mFine,
                nFine: values?.nFine,
            }
            // console.log('submitting form', body);
            // return;
            setLoading(true)
            post('/finance/answer', body)
                .then((res => {
                    setLoading(false);
                    // console.log('ad answer', res)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        formRef?.current?.reset();
                        if (user?.step == 2) {
                            props?.profileSuccess({ ...user, step: 3 });
                            localStorage.setItem("user", JSON.stringify({ ...user, step: 3 }));
                        } else if (user?.step == 3) {
                            props?.profileSuccess({ ...user, step: 4 });
                            localStorage.setItem("user", JSON.stringify({ ...user, step: 4 }));
                        }
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error(error)
                })
        }
    }

    return (
        <React.Fragment>
            <Prompt
                message={(location, action) => {
                    if (action === 'POP') {
                        console.log("Backing up...")
                    }

                    return location.pathname.startsWith("/app")
                        ? true
                        : `Are you sure you want to leave this page? Your unsaved changes may be lost.`
                }}
            />
            <Loader loading={loading} />
            <MetaTags>
                <title>Financing Request | Demleen </title>
            </MetaTags>
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCING REQUEST one")}
                            </li>

                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> {t("Help")}</button>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={12}>
                                <Table
                                responsive
                                >
                                    <tbody >
                                        <tr>
                                            <td style={{ padding: 0, border: 'none' }} colspan="2">
                                                <Table
                                                    bordered
                                                >
                                                    <thead >
                                                        <tr className='right_head'>
                                                            <th colspan="5">{t("Financing Request")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data?.map((segment, segI) => (
                                                            <>
                                                                <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>
                                                                    <th style={{ width: '100%' }} colSpan={2}>
                                                                        {i18n?.language == 'en' ? segment?.title : segment?.titleFs}
                                                                    </th>
                                                                </tr>
                                                                {segment?.questions?.map((item, index) => (
                                                                    <tr>
                                                                        <td>
                                                                            {i18n?.language == 'en' ? item?.title : item?.titleFs}
                                                                        </td>
                                                                        {item?.type == 'text' &&
                                                                            <td>
                                                                                <AvField
                                                                                    type="text"
                                                                                    name={"text" + index}
                                                                                    value={item?.answer}
                                                                                    // ref={field1Ref}
                                                                                    onChange={(e) => handleInput(e.target.value, item?._id)}
                                                                                />
                                                                            </td>
                                                                        }
                                                                        {item?.type == 'number' &&
                                                                            <td>
                                                                                <AvField
                                                                                    type="number"
                                                                                    name={"number" + index}
                                                                                    value={item?.answer}
                                                                                    // ref={field1Ref}
                                                                                    onChange={(e) => handleInput(Number(e.target.value), item?._id)}
                                                                                />
                                                                            </td>
                                                                        }
                                                                        {item?.type == 'dropdown' &&
                                                                            <td>
                                                                                <AvField type="select" name={"select" + index}
                                                                                    // ref={field1Ref}
                                                                                    value={item?.answer} onChange={(e) => handleInput(e.target.value, item?._id)} style={{ width: '100%' }}>
                                                                                    <option selected>{t("Select")}</option>
                                                                                    {item?.options?.map((op) => (
                                                                                        <option value={op.title}>{i18n?.language == 'en' ? op.title : op.titleFs}</option>
                                                                                    ))}
                                                                                </AvField>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                ))}
                                                                {/* <tr>
                                                                    <td>
                                                                        {t("Requested Amount of Funding?")}
                                                                    </td>
                                                                    <td>
                                                                        <AvField
                                                                            type="number"
                                                                            name="Amount"
                                                                        />
                                                                    </td>
                                                                </tr> */}
                                                            </>
                                                        ))}
                                                        <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>
                                                            <th style={{ width: '100%' }} colSpan={2}>
                                                                {t("Assessment of the monthly activity - based on the past 12 months?")}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Minimum monthly revenue based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="eFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.eFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Average monthy revenue based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="fFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.fFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Monthly fixed charges based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="gFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.gFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Monthly variable charges based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="hFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.hFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td >{t("Monthly expenses based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="iFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.iFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr className='backgroun_td'>
                                                            <td >{t("Minimum monthly profit based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="jFine"
                                                                    style={{ width: '100%' }}
                                                                    disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={minimumMonthlyProfit}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr className='backgroun_td'>
                                                            <td>{t("Average monthly profit based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="kFine"
                                                                    style={{ width: '100%' }}
                                                                    disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={averageMonthlyProfit}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Minimum monthly cash based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="lFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.lFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td >{t("Average monthly cash based on the past 12 months?")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="mFine"
                                                                    style={{ width: '100%' }}
                                                                    value={restKey?.mFine}
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                />

                                                            </td>
                                                        </tr>
                                                        <tr className='backgroun_td'>
                                                            <td>{t("Maximum amount to consider (50% of minimum monthly profit)")}</td>
                                                            <td>
                                                                <AvField
                                                                    type="number"
                                                                    name="nFine"
                                                                    style={{ width: '100%' }}
                                                                    disabled
                                                                    onChange={(e) => onChangeSetValue(e.target.value)}
                                                                    onWheel={(event) => event.currentTarget.blur()}
                                                                    value={maximumAmount}
                                                                />

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center button-group-section">
                            {/* <Button type="submit" className='btn_bg' color="">
                                {
                                    buttonStatus == true ? <span>{t("Submit")}</span> : <span>{t("Save")}</span>
                                }

                            </Button> */}
                            <Button type="submit" className='btn_bg' color="">
                                {t("Save")}
                            </Button>
                            <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                                {t("Save & Submit")}
                            </Button>

                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

Index.propTypes = {
    t: PropTypes.any,
    profileSuccess: PropTypes.func
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { profileSuccess, setBreadcrumbItems })(Index)
)