import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Button,
  Modal, ModalHeader, ModalBody
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { get, post, put, upload } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CommentSection from 'pages/Comment/CommentSection';
import StateJson from '../../assets/state.json'
import Loader from 'components/Loader';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import Cropper, { ReactCropperElement } from "react-cropper";
import Resizer from "react-image-file-resizer";

const UserProfile = props => {
  let { user } = props;
  const [helpModal, setHelpModal] = useState(false);
  const [rightModal, setRightModal] = useState(false);
  const { i18n, t } = useTranslation();
  const { role } = props?.user;
  const [selectedSdg, setSelectedSdg] = useState([]);
  const [sdg, setSdg] = useState([]);
  const [region, setRegion] = useState([]);
  const [investmentRegion, setInvestmentRegion] = useState([]);
  const [investment, seInvestment] = useState([]);
  const [businessStructure, setBusinessStructure] = useState([]);
  const [country, setCountry] = useState([]);
  const [country1, setCountry1] = useState('');
  const [stateJ, setStateJ] = useState([]);
  const [stateData, setStateData] = useState('');
  const [cityName, setCityName] = useState([]);
  const [cityName1, setCityName1] = useState('');
  const [lang, setLang] = useState(null);
  const [data, setData] = useState(null);
  const [age, setAge] = useState(null);
  const [file, setFile] = useState(avatar);
  const [file1, setFile1] = useState(avatar);
  const [fileType, setFileType] = useState("profile");
  const [changeIcon, setChangeIcon] = useState(false);
  const [changeIcon1, setChangeIcon1] = useState(false);
  const [progress, setProgress] = useState(45)
  const [member, setMember] = useState([{}]);
  const [avgRating, setAvgRating] = useState(0)
  const [weight, setWeight] = useState([]);
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [cropImg, setCropImg] = useState(false);
  const formRef = useRef(null);
  const cropperRef = useRef(null);

  const options = [

    { label: t("SDG 1 - No poverty"), value: t("SDG 1 - No poverty") },
    { label: t("SDG 2 - Zero hunger"), value: t("SDG 2 - Zero hunger") },
    { label: t("SDG 3 - Good health and well-being"), value: t("SDG 3 - Good health and well-being") },
    { label: t("SDG 4 - Quality education"), value: t("SDG 4 - Quality education") },
    { label: t("SDG 5 - Gender equality"), value: t("SDG 5 - Gender equality") },
    { label: t("SDG 6 - Clean water and sanitation"), value: t("SDG 6 - Clean water and sanitation") },
    { label: t("SDG 7 - Affordable and clean energy"), value: t("SDG 7 - Affordable and clean energy") },
    { label: t("SDG 8 - Decent work and economic growth"), value: t("SDG 8 - Decent work and economic growth") },
    { label: t("SDG 9 - Industry innovation and infrastructure"), value: t("SDG 9 - Industry innovation and infrastructure") },
    { label: t("SDG 10 - Reduced inequalities"), value: t("SDG 10 - Reduced inequalities") },
    { label: t("SDG 11 - Sustainable cities and communities"), value: t("SDG 11 - Sustainable cities and communities") },
    { label: t("SDG 12 - Responsible consumption and production"), value: t("SDG 12 - Responsible consumption and production") },
    { label: t("SDG 13 - Climate action"), value: t("SDG 13 - Climate action") },
    { label: t("SDG 14 - Life below water"), value: t("SDG 14 - Life below water") },
    { label: t("SDG 15 - Life on land"), value: t("SDG 15 - Life on land") },
    { label: t("SDG 16 - Peace, justice and strong institutions"), value: t("SDG 16 - Peace, justice and strong institutions") },
    { label: t("SDG 17 - Partnership for the goals"), value: t("SDG 17 - Partnership for the goals") },
  ];

  const businessSector = Utils.getBusinessSector();

  const sdgOptions = Utils.getSDG();

  const regionoptions = [
    { label: t("Western Africa"), value: t("Western Africa") },
    { label: t("Eastern Africa"), value: t("Eastern Africa") },
    { label: t("Northern Africa"), value: t("Northern Africa") },
    { label: t("Southern Africa"), value: t("Southern Africa") },
    { label: t("Central Africa"), value: t("Central Africa") },
  ]

  const investmentoptions = [
    { label: t("Equity"), value: t("Equity") },
    { label: t("Debt / Loans"), value: t("Debt / Loans") },
    { label: t("Guarantees"), value: t("Guarantees") },
    { label: t("Grants"), value: t("Grants") },
  ]

  const Business_Structure = [
    { label: t("Non-Profit"), value: t("Non-Profit") },
    { label: t("Cooperative"), value: t("Cooperative") },
    { label: t("For-Profit (sole)"), value: t("For-Profit (sole)") },
    { label: t("For Profit (one-person limited liability undertaking)"), value: t("For Profit (one-person limited liability undertaking)") },
    { label: t("For Profit (limited liability corporation)"), value: t("For Profit (limited liability corporation)") },
    { label: t("For Profit (simplified joint stock company)"), value: t("For Profit (simplified joint stock company)") },
    { label: t("For-Profit (partnership)"), value: t("For-Profit (partnership)") },
    { label: t("For-Profit (limited partnership)"), value: t("For-Profit (limited partnership)") },
  ]

  useEffect(() => {
    if (user?.token) {
      getUserData();
    }
  }, [user, i18n?.language])

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    if (currentLanguage)
      setLang(currentLanguage)
  }, [lang])

  useEffect(() => {
    let total = 0;
    if (data?.profRating?.rating) {
      let per = Number(weight?.profitAndLoss) / 100;
      total = total + (Number(data?.profRating?.rating) * per);

    }

    if (data?.esgRating?.rating) {
      let per = Number(weight?.eSG) / 100;
      total = total + (Number(data?.esgRating?.rating) * per)

    }
    if (data?.financeRating?.rating) {
      let per = Number(weight?.financingRequest) / 100;
      total = total + (Number(data?.financeRating?.rating) * per)

    }
    if (data?.cashFlowRating?.rating) {
      let per = Number(weight?.cashFlow) / 100;
      total = total + (Number(data?.cashFlowRating?.rating) * per)

    }
    if (data?.balanceRating?.rating) {
      let per = Number(weight?.balanceSheet) / 100;
      total = total + (Number(data?.balanceRating?.rating) * per)

    }
    if (data?.nuroRating?.rating) {
      let per = Number(weight?.nuroProfiler) / 100;
      total = total + (Number(data?.nuroRating?.rating) * per)

    }

    if (data?.ratioRating?.rating) {
      let per = Number(weight?.ratio) / 100;
      total = total + (Number(data?.ratioRating?.rating) * per)

    }
    setAvgRating(total)

  }, [data, weight])

  // console.log('user on profile', data)


  const getUserData = () => {
    if (!loading) {
      setLoading(true)
      let body = {
        userId: user?._id,
        token: user?.token,
      }
      post('/user', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            setData(res?.data);
            setCountry1(res?.data?.businessCountry)
            setCityName1(res?.data?.businessCity)
            setStateData(res?.data?.businessState)
            if (res?.data?.member?.length) {
              setMember(res?.data?.member)
            }
            else {
              setMember([{}])
            }
            // console.log('member length', res?.data?.member)
            if (res?.data?.isEmailVerified == true) {
              setProgress(70)
              if (res?.data?.businessDesc) {
                setProgress(100)
              }
            }
            setAge(res?.data?.age)
            let temp0 = [];
            res?.data?.businessGoals?.map((item) => {
              temp0.push({ label: item, value: item });
            })
            setRegion(temp0)
            let temp = [];
            res?.data?.investmentRegion?.map((item) => {
              temp.push({ label: item, value: item });
            })
            setInvestmentRegion(temp);
            let temp1 = [];
            res?.data?.investmentInstrument?.map((item) => {
              temp1.push({ label: item, value: item });
            })
            seInvestment(temp1)
            let temp2 = [];
            res?.data?.developmentGoals?.map((item) => {
              temp2.push({ label: t(item), value: item });
            })
            setSelectedSdg(temp2)
            setSdg(temp2)
            let temp3 = [];
            res?.data?.orgSector?.map((item) => {
              temp3.push({ label: item, value: item });
            })
            setBusinessStructure(temp3)
          } else {
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        })
    }
  }
  // console.log('sdg length',sdg?.length)
  const handleValidSubmit = async (e, v) => {
    // console.log("values on profile", v);
    if (!loading) {
      // setLoading(true)
      let sdgGoals = [];
      selectedSdg.forEach(item => {
        sdgGoals.push(item?.value);
      });
      let goals = Object.assign([], region);
      let temp = [];
      if (role == 'SME')
        if (goals?.length > 0) {
          goals?.map(item => {
            temp.push(item.value);
          })
          // return temp;

        } else {
          // toast.error('please Select Investment Region in Africa')
        }

      let goals1 = Object.assign([], investmentRegion);
      let temp4 = [];
      if (role == 'Investor')
        if (investmentRegion?.length > 0) {
          goals1?.map(item => {
            temp4.push(item.value);
          })
          // return temp;

        } else {
          // return toast.error('please Select Investment Region in Africa')
        }

      let investmentList = Object.assign([], investment);
      let temp2 = [];
      if (role == 'Investor')
        if (investmentList?.length > 0) {
          investmentList?.map(item => {
            temp2.push(item.value);
          })
        } else {
          // return toast.error('please Select Investment Instrument')
          // return temp2;
        }

      let businessSt = Object.assign([], businessStructure);
      let temp3 = [];
      if (role == 'Investor')
        if (businessSt?.length > 0) {
          businessSt?.map(item => {
            temp3.push(item.value);
          })
        } else {
          // return toast.error('please Select Sector/Industry supported')
          // return temp3;
        }

        if(!cityName1){
          toast.error('Please Choose your City');
          return
        }

      let body = {
        ...v,
        businessGoals: temp,
        developmentGoals: sdgGoals,
        investmentInstrument: temp2,
        orgSector: temp3,
        investmentRegion: temp4,
        userId: user?._id,
        token: user?.token,
        businessCountry: country1,
        businessState: stateData,
        businessCity: cityName1
      }
      if (member[0]?.fname)
        body.member = member;
      if (data?.step == 1)
        body.step = 2;

      // console.log('body response', body)

      put('/user', body)
        .then((res => {
          // setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            toast.success(res?.message);
            props?.profileSuccess({ ...user, ...body });
            localStorage.setItem("user", JSON.stringify({ ...user, ...body }));
            // getUserData();
            // history.push('/dashboard')
          } else {
            console.log(res?.error);
            toast.error(res?.error)
          }
        }))
        .catch(error => {
          // setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
          toast.error('Something went wrong')
        })
    }
  }

  const sdgFun = (e) => {
    setSdg(e)
    // console.log(e)
  }

  const getAge = (e) => {
    let userDateinput = e.target.value;
    let birthDate = new Date(userDateinput);
    // console.log(" birthDate"+ birthDate);

    // get difference from current date;
    let difference = Date.now() - birthDate.getTime();

    let ageDate = new Date(difference);
    // console.log(" ageDate"+ ageDate);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    // console.log(" calculatedAge", calculatedAge);
    setAge(calculatedAge)
  }

  // console.log('age',age)

  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: "Profile", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Profile', breadcrumbItems)

  }, []);

  // console.log('daat', data?.businessStartDate?.split('T')[0])

  // function handleValidSubmit(event, values) {
  //   props.editProfile(values)
  // }

  useEffect(() => {
    // console.log('StateJson',StateJson[0]?.name)
    const africaCountries = StateJson.filter(x => x.region === "Africa");

    // console.log('country', africaCountries);
    let temp0 = [];
    africaCountries?.map((item) => {
      temp0.push({ name: item?.name, value: item?.name });
    })


    // let temp1 = [];
    // StateJson?.map((item) => {
    //   temp1.push({ name: item?.name, value: item?.name });
    // })
    setCountry(temp0)
  }, []);

  useEffect(() => {
    // console.log('StateJson',StateJson[0]?.name)


    const selectedStateData = StateJson.find(state => state.name === country1);
    // console.log('states', selectedStateData?.states)
    let temp1 = [];
    selectedStateData?.states?.map((item) => {

      temp1.push({ name: item?.name, value: item?.name });
    })
    setStateJ(temp1)
  }, [country1]);

  useEffect(() => {
    // console.log('StateJson',StateJson[0]?.name)


    const selectedStateData = StateJson.find(state => state.name === country1);
    // console.log('city1', selectedStateData?.states)

    const selectedStateData1 = selectedStateData?.states?.find(state => state.name === stateData);
    // console.log('city', selectedStateData1?.cities)
    let temp1 = [];
    selectedStateData1?.cities?.map((item) => {
      // console.log('statesitem', item)
      temp1.push({ name: item?.name, value: item?.name });
    })
    setCityName(temp1)
  }, [stateData]);

  const getFile = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // if (fileObj)
    //   setChangeIcon(true)
    setFileType("profile");
    setFile(URL.createObjectURL(fileObj));
    setCropImg(true);
    // console.log('fileObj is', fileObj?.name);
  }

  const getFile1 = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFileType("company");
    setFile(URL.createObjectURL(fileObj));
    setCropImg(true);
    // console.log('fileObj is', fileObj?.name);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const cropImage = async (e, v) => {
    if (!loading) {
      try {
        setLoading(true);
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        let obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const image = await resizeFile(obj);
        const form = new FormData();
        form.append("image", image);
        let uploadRes = await upload("/user/image_upload", form);
        let body = {
          userId: user?._id,
          token: user?.token
        }
        if (fileType == "profile")
          body = { ...body, profile_picture: uploadRes?.data };
        else
          body = { ...body, companyLogo: uploadRes?.data };
        put('/user', body)
          .then((res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message)
              getUserData();
              setCropImg(false);
            } else {
              console.log(res?.error);
              toast.error(res?.error)
            }
          }))
          .catch(error => {
            setLoading(false);
            console.log('error while login', error);
            toast.error('Something went wrong')
          })
      } catch (err) {
        console.log("Error", err);
        setLoading(false);
        toast.error("Something Went Wrong!");
      }
      // setImageUrl(fileData)
    }
  }
  // console.log('user data', data)

  const deleteBox = (index) => {
    // console.log('index', index)
    let copyArr = Object.assign([], member);
    copyArr.splice(index, 1);
    // console.log('copyArr', copyArr)
    setMember(copyArr);
  }

  const addMemberData = (v, i, k) => {
    let temp = Object.assign([], member);
    temp[i][k] = v;
    setMember(temp)
  }
  const maxSelections = 3;
  const sdgFunction = (e) => {
    if (selectedSdg.length < maxSelections || e.length <= selectedSdg.length) {
      setSelectedSdg(e);
    }
    // setSelectedSdg(e)
    // console.log('sdgFunction', e?.length)
  }


  // console.log('data',data?.profRating?.comment)


  return (
    <React.Fragment>
      <MetaTags>
        <title>Profile | Demleen </title>
      </MetaTags>
      <Loader loading={loading} />
      <Modal isOpen={cropImg} className='trusted_amount' centered backdrop="static">
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              cropImage()
            }}
            ref={formRef}
          >
            <Row>
              <Col lg={12} className='mb-4'>
                <Cropper
                  src={file}
                  aspectRatio={1 / 1}
                  style={{ height: 300, width: "100%" }}
                  // Cropper.js options
                  preview=".img-preview"
                  initialAspectRatio={1 / 1}
                  guides={true}
                  ref={cropperRef}
                />
              </Col>
              <Col md={6}>
                <button type='button' className='btn_bg w-100' onClick={() => { setCropImg(false) }}>Cancel</button>
              </Col>
              <Col md={6}>
                <button type='submit' className='btn_bg w-100'>Submit</button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
        <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
        <ModalBody>
          <div className="popup_content">
            <ul>
              <li><i className="mdi mdi-hand-pointing-right"></i>
                {t("Profile one")}
              </li>
              <li><i className="mdi mdi-hand-pointing-right"></i>
                {t("Profile two")}
              </li>

            </ul>
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
          </div>
        </ModalBody>

      </Modal>

      <div className='right_button_box'>
        <CommentSection t={t} rightModal={rightModal} comment={data?.comment} setRightModal={setRightModal} />
        {
          data?.comment && <button className="" onClick={() => setRightModal(true)}><span className='mdi mdi-comment-processing'></span></button>
        }

        {
          user?.role == 'SME' && <button className="" onClick={() => setHelpModal(!helpModal)}><span>i</span> {t("Help")}</button>
        }

      </div>
      <Card>
        <CardBody>
          <Row>
            <Col lg="5">
              <Media>
                <div className="ms-3">
                  <div className='profile_img_pick'>
                    <img
                      src={data?.profile_picture ? data?.profile_picture : avatar}
                      alt={data?.fname}
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <input type='file' accept=".jpg,.png,.jpeg" onChange={getFile} id="userImg" style={{ display: 'none' }} />
                    <label htmlFor="userImg" className='userImg_label'>
                      <i className='mdi mdi-pencil'></i>
                    </label>
                  </div>

                  {user?.role == "Admin" &&
                    data?.approveStatus != "Pending" ?
                    <>
                      {
                        data?.approveStatus == 'Submitted' &&
                        <p className='status_check' style={{ backgroundColor: 'orange', color: '#fff' }} >New Request</p>
                      }
                      {
                        data?.approveStatus == 'In Progress' &&
                        <p className='status_check' style={{ backgroundColor: 'blue', color: '#fff' }} >In Progress</p>
                      }
                      {
                        data?.approveStatus == 'Accepted' &&
                        <p className='status_check' style={{ backgroundColor: 'green', color: '#fff' }} >Accepted</p>
                      }
                      {
                        data?.approveStatus == 'Rejected' &&
                        <p className='status_check' style={{ backgroundColor: 'red', color: '#fff' }} >Declined</p>
                      }
                    </>
                    : null
                  }
                </div>
                <Media body className="align-self-center px-4">
                  <div className="text-muted">
                    <h5 className='mb-1'>{data?.fname} {data?.lname}</h5>
                    <p className="mb-0">{data?.username}</p>
                    <p className="mb-0">{data?.email}</p>
                    <p className="mb-0">{data?.gender}</p>
                    {/*  {
                      user?.role == 'SME' && avgRating ? <p className="mb-0">({avgRating.toFixed(2)})<sup><i className='mdi mdi-star'></i></sup></p> : null
                    }*/}
                  </div>
                </Media>
              </Media>
            </Col>
            {
              user?.role == 'SME' &&
              <Col lg="7">
                <div className='profile_progress_section'>
                  <ProgressBar
                    percent={progress}
                    filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                  >
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <div className='progress_bar_section'>
                          <div className='step_icon'>
                            {
                              accomplished ? <i className="mdi mdi-check"></i> : <p>1</p>
                            }

                          </div>
                          <span>{t('Signup')}</span>
                        </div>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <div className='progress_bar_section'>
                          <div className='step_icon'>
                            {
                              accomplished ? <i className="mdi mdi-check"></i> : <p>2</p>
                            }
                          </div>
                          <span>{t("Login")}</span>
                        </div>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <div className='progress_bar_section'>
                          <div className='step_icon'>
                            {
                              accomplished ? <i className="mdi mdi-check"></i> : <p>3</p>
                            }
                          </div>
                          <span>{t('Email verification')}</span>
                        </div>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <div className='progress_bar_section'>
                          <div className='step_icon'>
                            {
                              accomplished ? <i className="mdi mdi-check"></i> : <p>4</p>
                            }
                          </div>
                          <span>{t("Profile Completion")}</span>
                        </div>
                      )}
                    </Step>

                  </ProgressBar>
                </div>
              </Col>
            }
          </Row>
        </CardBody>
      </Card>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
      >
        <h4 className="card-title mb-4">{t("Personal Details")}</h4>
        <Card>
          <CardBody>
            <Row>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="fname"
                    label={t("First Name")}
                    value={data?.fname}
                    className="form-control"
                    type="text"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="lname"
                    label={t("Last Name")}
                    value={data?.lname}
                    className="form-control"
                    type="text"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="email"
                    value={data?.email}
                    label={t("Email Address")+"*"}
                    className="form-control"
                    type="text"
                    disabled
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="mobile"
                    value={data?.mobile}
                    label={t("Phone Number")}
                    className="form-control"
                    type="number"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="dob"
                    label={t("Date of Birth")}
                    value={data?.dob}
                    className="form-control"
                    onChange={getAge}
                    type="date"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField
                    name="age"
                    label={t("Age")}
                    className="form-control"
                    value={age}
                    type="number"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField type="select" name="gender" value={data?.gender}
                    label={t("Gender")} >
                    <option value="">{t("Select")}</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-Binary</option>
                    <option value="other">Other</option>
                    <option value="Prefer not to answer">Perfer not to Answer</option>
                  </AvField>
                </div>
              </Col>
              {user?.role == 'Investor' &&
                <>
                  <Col lg={3} md={6}>
                    <div className="form-group form_group_search mb-3">
                      <label className='country_label'>{t("Country")} <p data-bs-toggle="tooltip" data-bs-placement="top" title={t("This service is only for African nations")}>i</p></label>
                      <SelectSearch options={country} value={country1} search={true} autocomplete="off" onChange={(e) => setCountry1(e)} name="businessState" placeholder="Choose your Country" />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="companyName"
                        label={t("Company Name")}
                        value={data?.companyName}
                        className="form-control"
                        // placeholder="Company Name"
                        type="text"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                </>
              }
              <Col lg={3} md={6}>
                <div className="form-group mb-3">
                  <AvField type="select" name="designation" autoComplete="off" value={data?.designation} label={t("Current Position At The Company")}>
                    <option>{t("Select")}</option>
                    <option>{t("CEO")}</option>
                    <option>{t("CFO")}</option>
                    <option>{t("Technical")}</option>
                    <option>{t("Accountant")}</option>
                  </AvField>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          role == 'SME' &&
          <>
            <h4 className="card-title mb-4">{t("Company Details")}</h4>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="3">
                    <div className='company_logo_card'>
                      <div className="profile_img_pick">
                        <img
                          src={data?.companyLogo ? data?.companyLogo : avatar}
                          alt={data?.companyName}
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                        <input type='file' accept=".jpg,.png,.jpeg" onChange={getFile1} id="userImg1" style={{ display: 'none' }} />
                        <label htmlFor="userImg1" className='userImg_label'>
                          <i className='mdi mdi-pencil'></i>
                        </label>
                      </div>
                      <p>Choose Logo</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessName"
                        value={data?.businessName}
                        label={t("Business Legal Name")}
                        className="form-control"
                        // placeholder="Business Legal Name"
                        autoComplete="off"
                        type="text"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="companyName"
                        label={t("Company Name")+"*"}
                        value={data?.companyName}
                        className="form-control"
                        // placeholder="Company Name"
                        type="text"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessStartDate"
                        label={t("Business Start Date")}
                        value={data?.businessStartDate?.split('T')[0]}
                        className="form-control"
                        // placeholder="Business Start Date"
                        type="date"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessRegisterNo"
                        value={data?.businessRegisterNo}
                        label={t("Registration Number")}
                        className="form-control"
                        // placeholder="Registration Number"
                        type="text"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessTaxId"
                        value={data?.businessTaxId}
                        label={t("Tax Id")}
                        className="form-control"
                        // placeholder="Tax Id"
                        type="number"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessAddress"
                        value={data?.businessAddress}
                        label={t("Company Address")}
                        className="form-control"
                        // placeholder="Company Address"
                        type="text"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessEmail"
                        value={data?.businessEmail}
                        label={t("Email Address")}
                        className="form-control"
                        // placeholder={t("Email Address")}
                        type="email"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessPhone"
                        value={data?.businessPhone}
                        label={t("Phone Number")}
                        className="form-control"
                        // placeholder={t("Phone Number")}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group form_group_search mb-3">
                      {/* <AvField
                        name="businessState"
                        value={data?.businessState}
                        label={t("State")}
                        className="form-control"
                        // placeholder="State"
                        type="text"
                      /> */}
                      <label className='country_label'>{t("Country")} <p data-bs-toggle="tooltip" data-bs-placement="top" title={t("This service is only for African nations")}>i</p></label>
                      <SelectSearch options={country} value={country1} search={true} autocomplete="off" onChange={(e) => setCountry1(e)} name="businessState" placeholder="Choose your Country" />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group form_group_search mb-3">
                      {/* <AvField
                        name="businessState"
                        value={data?.businessState}
                        label={t("State")}
                        className="form-control"
                        // placeholder="State"
                        type="text"
                      /> */}
                      <label>{t("State")}</label>
                      <SelectSearch options={stateJ} value={stateData} search={true} autoComplete="off" onChange={(e) => setStateData(e)} name="businessState" placeholder="Choose your State" />

                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group form_group_search mb-3">
                      {/* <AvField
                        name="businessCity"
                        value={data?.businessCity}
                        label={t("City")}
                        className="form-control"
                        // placeholder="City"
                        type="text"
                      /> */}
                      <label>{t("City")+"*"}</label>
                      <SelectSearch options={cityName} value={cityName1} autoComplete="off" search={true} onChange={(e) => setCityName1(e)} name="businessCity" placeholder="Choose your City" />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessZipCode"
                        value={data?.businessZipCode}
                        label={t("Zip Code")}
                        className="form-control"
                        // placeholder="Zip Code"
                        type="number"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField type="select" name="businessStructure" autoComplete="off" value={data?.businessStructure} label={t("Business Structure")}>
                        <option defaultValue="" selected="">{t("Business Structure/Type")}</option>
                        {
                          Business_Structure.map((item, index) => {
                            return (
                              <option value={t(item?.label)} key={index} selected="">{t(item?.label)}</option>
                            )
                          })
                        }
                      </AvField>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField type="select" required name="businessSector" autoComplete="off" value={data?.businessSector} label={t("Business Sector")+"*"}>
                        <option defaultValue="" selected="">{t("Select")}</option>
                        {businessSector.map((item, index) => {
                          return (
                            <option value={item?.value} key={index} selected="">{item?.label}</option>
                          )
                        })
                        }
                      </AvField>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessWebsite"
                        value={data?.businessWebsite}
                        label={t("Website")}
                        className="form-control"
                        // placeholder="City"
                        type="text"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessAccountPartner"
                        value={data?.businessAccountPartner}
                        label={t("External Accounting partner")}
                        className="form-control"
                        // placeholder="City"
                        type="text"
                        autoComplete="off"
                      />
                    </div>
                  </Col>
                  <Col lg={8} md={6}>
                    <div className="form-group mb-3">
                      <label>{t("Related Sustainable Development Goals (3 max)")}</label>
                      <MultiSelect
                        options={sdgOptions}
                        hasSelectAll={false}
                        value={selectedSdg}
                        onChange={sdgFunction}

                        overrideStrings={{
                          selectSomeItems: t("Select Some items..."),
                          allItemsAreSelected: t("All Items are Selected"),
                          selectAll: t("Select All"),
                          search: t("Search"),
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="form-group mb-3">
                      <AvField
                        name="businessDesc"
                        value={data?.businessDesc}
                        label={t("Activity Description")}
                        className="form-control"
                        placeholder={t("Short Description")}
                        type="textarea"
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        }
        {
          role == 'Investor' &&
          <>
            <h4 className="card-title mb-4">{t("Your Organisation Preference Details")}</h4>

            <Card>
              <CardBody>
                <Row>
                  <Col lg={4} md={6}>
                    <div className="form-group mb-3">
                      <label>{t("Supported Sustainable Development Goals")}</label>
                      <MultiSelect
                        options={options} // Options to display in the dropdown
                        hasSelectAll={true}
                        value={sdg}
                        onChange={sdgFun}
                        overrideStrings={{
                          selectSomeItems: t("Select Some items..."),
                          allItemsAreSelected: t("All Items are Selected"),
                          selectAll: t("Select All"),
                          search: t("Search"),
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="form-group mb-3">
                      <label>{t("Investment Region in Africa")}</label>
                      <MultiSelect
                        options={regionoptions} // Options to display in the dropdown
                        hasSelectAll={true}
                        value={investmentRegion}
                        onChange={setInvestmentRegion}
                        overrideStrings={{
                          selectSomeItems: t("Select Some items..."),
                          allItemsAreSelected: t("All Items are Selected"),
                          selectAll: t("Select All"),
                          search: t("Search"),
                        }}

                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="form-group mb-3">
                      <label>{t("Investment Instrument")}</label>
                      <MultiSelect
                        options={investmentoptions} // Options to display in the dropdown
                        hasSelectAll={true}
                        value={investment}
                        onChange={seInvestment}
                        overrideStrings={{
                          selectSomeItems: t("Select Some items..."),
                          allItemsAreSelected: t("All Items are Selected"),
                          selectAll: t("Select All"),
                          search: t("Search"),
                        }}

                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="form-group mb-3">
                      <label>{t("Sector/Industry supported")}</label>
                      <MultiSelect
                        options={businessSector} // Options to display in the dropdown
                        hasSelectAll={true}
                        value={businessStructure}
                        onChange={setBusinessStructure}
                        overrideStrings={{
                          selectSomeItems: t("Select Some items..."),
                          allItemsAreSelected: t("All Items are Selected"),
                          selectAll: t("Select All"),
                          search: t("Search"),
                        }}

                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <label style={{ fontWeight: '500', fontSize: 20 }}>{t("Investment Characteristics")}</label>
                    <div className="form-group d-flex justify_content_between mb-3">

                      <AvField type="select" name="investmentCurrency" value={data?.investmentCurrency} label={t("Currency")} >
                        <option>{t("Select")}</option>
                        <option>EUR</option>
                        <option>DOLLAR</option>
                        <option>XOF/XAF - Franc CFA</option>
                      </AvField>
                      <AvField
                        name="investmentAmount"
                        label={t("Amount to Invest")}
                        className="form-control"
                        value={data?.investmentAmount}
                        // placeholder="Amount to Invest"
                        type="number"

                      />
                      <AvField type="select" name="investmentUnit" value={data?.investmentUnit} label={t("Unit")} >
                        <option>{t("Select")}</option>
                        <option>Million</option>
                        <option>Billion</option>
                        <option>Hundred Thousand</option>
                      </AvField>

                    </div>
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </>
        }
        {
          role == 'SME' &&
          <>
            <h4 className="card-title mb-4">{t("Management Team Optional")}</h4>
            <Card>
              <CardBody>
                {
                  member?.map((item, index) => (
                    <Row key={index}>
                      <Col lg={12}>
                        <div className='card_hrader'>
                          <h4>{t("Membre")} {index + 1}</h4>
                          <div className='button_group'>

                            {
                              member?.length > 1 && <button type='button' onClick={() => deleteBox(index)}><i className='mdi mdi-close'></i></button>
                            }
                            {
                              index > 2 ? null : <>
                                {
                                  index == member?.length - 1 && <button type='button' onClick={() => setMember([...member, {}])}><i className='mdi mdi-plus'></i></button>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          <AvField
                            name={'fname' + index}
                            label={t("First Name")}
                            onChange={(e) => addMemberData(e.target.value, index, 'fname')}
                            className="form-control"
                            type="text"
                            value={item?.fname}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          <AvField
                            name={'lname' + index}
                            label={t("Last Name")}
                            onChange={(e) => addMemberData(e.target.value, index, 'lname')}
                            className="form-control"
                            type="text"
                            value={item?.lname}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          <AvField
                            name={'email' + index}
                            onChange={(e) => addMemberData(e.target.value, index, 'email')}
                            label={t("Email Address")}
                            className="form-control"
                            type="text"
                            value={item?.email}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          {/* <AvField
                        name={'mobile'+index}
                        onChange={(e)=>addMemberData(e.target.value,index,'mobile')}
                        label={t("Phone Number")}
                        className="form-control"
                        type="number"
                        value={item?.mobile}
                      /> */}
                          <AvField type="select" name={"gender" + index} value={item?.gender}
                            onChange={(e) => addMemberData(e.target.value, index, 'gender')}
                            label={t("Gender")} >
                            <option>{t("Select")}</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            {/* <option value="non-binary">Non-Binary</option>
                        <option value="other">Other</option>
                        <option value="Prefer not to answer">Perfer not to Answer</option> */}
                          </AvField>
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          <AvField
                            name={'department' + index}
                            label={t("Department")}
                            onChange={(e) => addMemberData(e.target.value, index, 'department')}
                            className="form-control"
                            type="text"
                            value={item?.department}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                          <AvField
                            name={'designation' + index}
                            label={t("Designation")}
                            onChange={(e) => addMemberData(e.target.value, index, 'designation')}
                            type="text"
                            value={item?.designation}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))
                }

              </CardBody>
            </Card>
          </>
        }
        <Card>
          <CardBody>
            <div className="text-center">
              <Button type="submit" className='btn_bg' color="">
                {t("Save")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </AvForm>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
  profileSuccess: PropTypes.func,
}

const mapStatetoProps = state => {

  const user = state.Profile.success;
  return { user };
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag, profileSuccess, setBreadcrumbItems })(UserProfile)
)

