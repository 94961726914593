import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { get, post } from 'helpers/helper_api';

import { toast } from 'react-toastify';
// import { withTranslation } from "react-i18next"

const DataTable = props => {

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const { i18n, t } = useTranslation();

  const [sdg, setSdg] = useState([]);
  const [region, setRegion] = useState([]);
  const [investment, seInvestment] = useState([]);
  const [grossMargin, setGrossMargin] = useState(0);
  const [turnover, setTurnover] = useState(0);
  const [addedValue, setAddedValue] = useState(0);
  const [grossOperating, setGrossOperating] = useState(0);
  const [totalOperating, setTotalOperating] = useState(0);
  const [totalFinancial, setTotalFinancial] = useState(0);
  const [pretaxprofit, setPretaxprofit] = useState(0);
  const [exceptionalProfit, setExceptionalProfit] = useState(0);
  const [profitorLoss, setProfitorLoss] = useState(0);
  const [wastemanagement, setWastemanagement] = useState(false);
  const [hSSmanagement, setHSSmanagement] = useState(false);
  const [administrationBoard, setAdministrationBoard] = useState(false);
  const [explicitdisclosure, setExplicitdisclosure] = useState(false);
  const [separation, setSeparation] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);

  const formRef = useRef();

  const { user } = props;

  const wasteManagement = (e, parent, child) => {
    let temp = Object?.assign([], data);
    if (e.target?.value == "Yes") {
      setWastemanagement(true)
      temp[parent].questions[child].isOpen = true;
    } else {
      setWastemanagement(false)
      temp[parent].questions[child].isOpen = false;
    }
    setData(temp)
  }


  const administrationBoardFun = (e) => {
    if (e.target?.value == "Yes") {
      setAdministrationBoard(true)
    } else {
      setAdministrationBoard(false)
    }
  }
  const explicitdisclosureFun = (e) => {
    if (e.target?.value == "Yes") {
      setExplicitdisclosure(true)
    } else {
      setExplicitdisclosure(false)
    }
  }
  const separationFun = (e) => {
    if (e.target?.value == "Yes") {
      setSeparation(true)
    } else {
      setSeparation(false)
    }
  }


  const HSSmanagementFun = (e) => {
    if (e.target?.value == "Yes") {
      setHSSmanagement(true)
    } else {
      setHSSmanagement(false)
    }
  }

  const handleInput = (v, q) => {
    let temp = Object.assign([], form);
    let already = temp?.findIndex(x => x.quesId == q)
    if (already == -1) {
      temp.push({ quesId: q, answer: v })
    }
    else {
      temp[already].answer = v
    }
    setForm(temp)
  }

  useEffect(() => {
    if (props?.user?.token)
      getUserData();
  }, [props])

  const getUserData = () => {
    if (!loading) {
      setLoading(true)
      let body = {
        userId: user?._id,
        token: user?.token
      }
      get('/esg/answer/list', body)
        .then((res => {
          setLoading(false);
          console.log('esg for sme question list', res)
          if (res?.statusCode == 200) {
            setData(res?.data)
          } else {
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        })
    }
  }


  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success])

  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: "ESG", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('ESG', breadcrumbItems)
  }, [])



  return (
    <React.Fragment>
      <MetaTags>
        <title>ESG | Demleen </title>
      </MetaTags>

      <Loader loading={loading} />

      {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
      <Card>
        <CardBody>

          <Row>
            <Col lg={12}>
              <Table

              >

                <tbody >

                  <tr>
                    <td style={{ padding: 0, border: 'none' }} colspan="2">
                      <Table
                        bordered
                      >
                        <thead >
                          <tr className='right_head'>
                            <th colspan="5">{t("ESG")}</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data?.form?.map((item, index) => (
                              <>
                                <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                  <th colSpan={2} style={{ width: '70%' }}>
                                    {i18n?.language == 'en' ? item?.title : item?.titleFs}
                                  </th>
                                  {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}


                                </tr>

                                <tr className='second_head_theree'>

                                  <th colSpan={2} style={{ width: '70%' }}>
                                    {i18n?.language == 'en' ? item?.desc : item?.descFs}
                                  </th>

                                </tr>
                                {
                                  item?.questions?.map((item1, index1) => (
                                    <tr>

                                      <td>
                                        {i18n?.language == 'en' ? item1?.title : item1?.titleFs}
                                      </td>

                                      <td>
                                        {}
                                      </td>

                                    </tr>
                                  ))
                                }
                              </>
                            ))
                          }



                        </tbody>
                      </Table>
                    </td>

                  </tr>

                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="text-center">
            <Button type="submit" className='btn_bg' color="">
              {t("Submit")}
            </Button>
          </div>
        </CardBody>
      </Card>



    </React.Fragment>
  )
}

DataTable.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {

  const user = state.Profile.success;
  return { user };
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(DataTable)
)