import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import img1 from './assets/img/f-second1.png'
import img2 from './assets/img/Picture-African-business1.png'
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
export default function About() {
  const { i18n, t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1')
  return (
    <div className='body_front'>
      <Helmet>

        <title>About Us - SME Finance Companies Africa | Top Providers</title>
        <meta name="description" content="Connect with leading SME finance companies in Africa. Get the financial support you need.
" />
        <link rel="canonical" href="https://demleen.com/about-us" />
      </Helmet>
      <Header />
      <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
        <div class="container">
          <div class="row align-center">
            <div class="col-lg-6">
              <h2>{t("About Us")}</h2>
            </div>

          </div>
        </div>
      </div>
      <div class="bg_white_b about-area faq-area inc-shape default-padding ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="thumb">

                <img src={img1} alt="Thumb" style={{ width: '100%' }} />


              </div>
            </div>
            <div class="col-lg-5 offset-lg-1 info">
              <h2 className='h2_m'>{t("AboutUs Heading")}</h2>
              <p>
                {t("AboutUs paragraph")}
              </p>


            </div>
          </div>
        </div>
      </div>
      <div class="case-details-area services-details-area default-padding">
        <div class="container">
          {/* <div class="row">
            <div class="col-lg-12 thumb mb-5">
              <img src={img1} alt="Thumb" />
            </div>
          </div> */}
          <div class="row">
            <div class="col-lg-12 content px-3">

              <h2>{t("AboutUs Heading1")}</h2>
              <p>
                {t("AboutUs paragraph1")}
              </p>
              <p>
                {t("AboutUs paragraph2")}
              </p>

              <h2>{t("AboutUs Heading2")}</h2>
              <p>
                {t("AboutUs paragraph3")}
              </p>
              <p>
                {t("AboutUs paragraph4")}
              </p>
              <div class="row thumbs">
                <div class="col-lg-12 col-md-12">
                  <img src={img2} alt="Thumb" />
                </div>

              </div>
              <br />
              <br />
              <h2>{t("AboutUs Heading3")}</h2>
              <p>{t("AboutUs paragraph5")}</p>
              <p>{t("AboutUs paragraph6")}</p>
              <p>{t("AboutUs paragraph7")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
