import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Card, CardBody, Col, ModalHeader, Modal, ModalBody, Row, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get, post } from 'helpers/helper_api';
import { connect } from "react-redux"
import { withRouter, useHistory, Link, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { setBreadcrumbItems } from "../../store/actions";
import * as Utils from '../Utils'
import Loader from 'components/Loader';
// import RadarChart from 'pages/AllCharts/chartjs/radarchart';
import RadarChart from 'pages/AllCharts/RadarChart';
import { profileSuccess } from "../../store/actions";

const NewTestInvite = (props) => {
    let history = useHistory();
    const params = useParams();
    const [formData, setFormData] = useState({
        step: 1,
    });
    const { user } = props;
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [value, setValue] = useState(null);
    const [currentQues, setCurrentQues] = useState(undefined);
    const [currentSegment, setCurrentSegment] = useState(undefined);
    const [currentFacet, setCurrentFacet] = useState(undefined);
    const [invite, setInvite] = useState(false);
    const [trustedAmount, setTrustedAmount] = useState(false);
    const [trustedAmountVal, setTrustedAmountVal] = useState('');
    const [result, setResult] = useState(undefined);
    const [resultPer, setResultPer] = useState(undefined);
    const [answers, setAnswers] = useState([]);
    const [step, setStep] = useState(1);
    const [prob, setProb] = useState(50);
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState(null);
    const [memId, setMemId] = useState(null);
    console.log("lang", i18n, params);
    useEffect(() => {
        if (params) {
            setUserId(params?.id)
            setMemId(params.memId);
        }
    }, [params]);
    useEffect(() => {
        if (userId) {
            getUserData();
        }
    }, [userId]);

    const getUserData = () => {
        if (!loading) {
            setLoading(true);
            get(`/nuro/invite?userId=${userId}`)
                .then(res => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        if (!res?.newAnswer) {
                            setData(res?.data);
                            if (res?.data?.length) {
                                // setCurrentSegment(res?.data[1]);
                                // setCurrentFacet(res?.data[1]?.facet[0]);
                                // setCurrentQues(res?.data[1]?.facet[0]?.questions[0]);
                                // setProb(3);
                                setCurrentSegment(res?.data[0]);
                                if (res?.data[0]?.facet?.length) {
                                    setCurrentFacet(res?.data[0]?.facet[0]);
                                    setCurrentQues(res?.data[0]?.facet[0]);
                                } else {
                                    if (res?.data[0]?.questions?.length) {
                                        setCurrentQues(res?.data[0]?.questions[0]);
                                    }
                                }
                                setStep(1);
                            }
                        } else {
                            let winnTemp = res?.answers?.winning_appetite;
                            winnTemp = ((winnTemp - 0.4) / 0.6) * 100;
                            winnTemp = winnTemp.toFixed(2);
                            let loseTemp = res?.answers?.loss_aversion;
                            if (loseTemp >= 0.5 && loseTemp <= 1) {
                                loseTemp = ((loseTemp - 0.5) * 100).toFixed(2);
                            } else {
                                loseTemp = ((((loseTemp - 1) / 9) * 50) + 50).toFixed(2)
                            }
                            // console.log("winnTemp", loseTemp);
                            let temp = {
                                labels: [
                                    `${i18n?.language == 'en' ? 'Overconfidence':'Excès de confiance'} (${res?.newAnswer?.overconfidence ? res?.newAnswer?.overconfidence:0}%)`,
                                    `${i18n?.language == 'en' ? 'Organization':'Organisation'} (${res?.newAnswer?.conscientiousness?.organization ? (res?.newAnswer?.conscientiousness?.organization * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Productiveness':'Productivité'} (${res?.newAnswer?.conscientiousness?.productiveness ? (res?.newAnswer?.conscientiousness?.productiveness * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Responsibility':'Responsabilité'} (${res?.newAnswer?.conscientiousness?.responsibility ? (res?.newAnswer?.conscientiousness?.responsibility * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Anxiety':'Anxiété'} (${res?.newAnswer?.optimism?.anxiety ? (res?.newAnswer?.optimism?.anxiety * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Depression':'Dépression'} (${res?.newAnswer?.optimism?.depression ? (res?.newAnswer?.optimism?.depression * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Emotional Volatility':'Volatilité émotionnelle'} (${res?.newAnswer?.optimism?.emotional ? (res?.newAnswer?.optimism?.emotional * 100) / 20:0}%)`,
                                    `${i18n?.language == 'en' ? 'Winning Appetite':"Gagner l'appétit"} (${winnTemp ? winnTemp:0}%)`,
                                    `${i18n?.language == 'en' ? 'Loss aversion':"Aversion aux pertes"} (${loseTemp ? loseTemp:0}%)`,
                                ], data: [
                                    res?.newAnswer?.overconfidence ? res?.newAnswer?.overconfidence:0,
                                    res?.newAnswer?.conscientiousness?.organization ? (res?.newAnswer?.conscientiousness?.organization * 100) / 20:0,
                                    res?.newAnswer?.conscientiousness?.productiveness ? (res?.newAnswer?.conscientiousness?.productiveness * 100) / 20:0,
                                    res?.newAnswer?.conscientiousness?.responsibility ? (res?.newAnswer?.conscientiousness?.responsibility * 100) / 20:0,
                                    res?.newAnswer?.optimism?.anxiety ? (res?.newAnswer?.optimism?.anxiety * 100) / 20:0,
                                    res?.newAnswer?.optimism?.depression ? (res?.newAnswer?.optimism?.depression * 100) / 20:0,
                                    res?.newAnswer?.optimism?.emotional ? (res?.newAnswer?.optimism?.emotional * 100) / 20:0,
                                    winnTemp ? winnTemp:0,
                                    loseTemp ? loseTemp:0
                                ]
                            };
                            let temp1 = {
                                labels: [
                                    `(${res?.newAnswer?.overconfidence}%)`,
                                    `(${(res?.newAnswer?.conscientiousness?.organization * 100) / 20}%)`,
                                    `(${(res?.newAnswer?.conscientiousness?.productiveness * 100) / 20}%)`,
                                    `(${(res?.newAnswer?.conscientiousness?.responsibility * 100) / 20}%)`,
                                    `(${(res?.newAnswer?.optimism?.anxiety * 100) / 20}%)`,
                                    `(${(res?.newAnswer?.optimism?.depression * 100) / 20}%)`,
                                    `(${(res?.newAnswer?.optimism?.emotional * 100) / 20}%)`,
                                    `(${winnTemp}%)`,
                                    `(${loseTemp}%)`,
                                ], data: [
                                    res?.newAnswer?.overconfidence,
                                    (res?.newAnswer?.conscientiousness?.organization * 100) / 20,
                                    (res?.newAnswer?.conscientiousness?.productiveness * 100) / 20,
                                    (res?.newAnswer?.conscientiousness?.responsibility * 100) / 20,
                                    (res?.newAnswer?.optimism?.anxiety * 100) / 20,
                                    (res?.newAnswer?.optimism?.depression * 100) / 20,
                                    (res?.newAnswer?.optimism?.emotional * 100) / 20,
                                    40,
                                    60
                                ]
                            };
                            setResult(temp);
                            setResultPer(temp1)
                            setCurrentQues(null);
                        }
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    console.log('result', result)

    const nextStep = () => {
        if (currentQues?.type == "Binary") {
            if (!value) {
                toast.error("Select an asnwer please!");
                return;
            }
        }
        let temp = Object.assign([], answers);
        let option = 3;
        let ques = currentQues, str = "";
        if (ques?.type == "Weight") {
            if (prob < 10)
                option = prob;
            if (option == 1) {
                if (i18n?.language == "en")
                    str = ques?.option1?.title;
                else
                    str = ques?.option1?.titleFs;
            }
            if (option == 2) {
                if (i18n?.language == "en")
                    str = ques?.option2?.title;
                else
                    str = ques?.option2?.titleFs;
            }
            if (option == 3) {
                if (i18n?.language == "en")
                    str = ques?.option3?.title;
                else
                    str = ques?.option3?.titleFs;
            }
            if (option == 4) {
                if (i18n?.language == "en")
                    str = ques?.option4?.title;
                else
                    str = ques?.option4?.titleFs;
            }
            if (option == 5) {
                if (i18n?.language == "en")
                    str = ques?.option5?.title;
                else
                    str = ques?.option5?.titleFs;
            }
        }
        temp.push({
            ques: currentQues?.title,
            answer: currentQues?.type == "Binary" ? value == "no" ? false : true : str,
            prob,
            quesId: currentQues?._id
        });
        setAnswers(temp);
        // console.log("Answers", temp);
        if (currentFacet) {
            if (currentFacet?.questions?.length > step) {
                setCurrentQues(currentFacet?.questions[step]);
                setStep(step + 1);
                setProb(3);
            } else {
                // toast.success("current facet question end changing facet");
                let facetTemp = currentSegment?.facet?.findIndex(x => x?._id == currentFacet?._id);
                if (currentSegment?.facet?.length - 1 > facetTemp) {
                    setCurrentFacet(currentSegment?.facet[facetTemp + 1]);
                    setCurrentQues(currentSegment?.facet[facetTemp + 1]?.questions[0]);
                    setProb(3);
                    setStep(1);
                } else {
                    // toast.success("current segment question end changing segment");
                    let segmenTemp = data?.findIndex(x => x?._id == currentSegment?._id);
                    if (segmenTemp != -1) {
                        if (data?.length - 1 > segmenTemp) {
                            setCurrentSegment(data[segmenTemp + 1]);
                            if (data[segmenTemp + 1]?.facet?.length) {
                                setCurrentFacet(data[segmenTemp + 1]?.facet[0]);
                                setCurrentQues(data[segmenTemp + 1]?.facet[0]?.questions[0]);
                                setStep(1);
                                setProb(3);
                            } else {
                                if (data[segmenTemp + 1]?.questions?.length) {
                                    setCurrentQues(data[segmenTemp + 1]?.questions[0]);
                                    setStep(1);
                                }
                            }
                        } else {
                            saveAnswers(temp);
                            // toast.success("current segment question end.");
                            // return;
                        }
                    } else {
                        toast.error("Something Went Wrong!");
                        return;
                    }
                }
            }
        } else {
            if (currentSegment?.questions?.length > step) {
                setCurrentQues(currentSegment?.questions[step]);
                setStep(step + 1);
                setValue("");
                setProb(50);
            } else {
                // toast.success("current segment question end changing segment");
                let segmenTemp = data?.findIndex(x => x?._id == currentSegment?._id);
                if (segmenTemp != -1) {
                    if (data?.length - 1 > segmenTemp) {
                        setCurrentSegment(data[segmenTemp + 1]);
                        if (data[segmenTemp + 1]?.facet?.length) {
                            setCurrentFacet(data[segmenTemp + 1]?.facet[0]);
                            setCurrentQues(data[segmenTemp + 1]?.facet[0]?.questions[0]);
                            setStep(1);
                            setProb(3);
                        } else {
                            if (data[segmenTemp + 1]?.questions?.length) {
                                setCurrentQues(data[segmenTemp + 1]?.questions[0]);
                                setStep(1);
                            }
                        }
                    } else {
                        // toast.success("current segment question end.");
                        saveAnswers(temp);
                    }
                } else {
                    toast.error("Something Went Wrong!");
                }
            }
        }
    }

    const prevStep = () => {
        let temp = Object.assign([], answers);
        let prevAns = temp.pop();
        setAnswers(temp);
        setValue(prevAns?.answer ? "yes" : "no");
        setProb(prevAns?.prob);
        if (currentFacet) {
            if (step > 1) {
                setCurrentQues(currentFacet?.questions[step - 2]);
                setStep(step - 1);
            }
        } else {
            if (currentSegment?.questions?.length > 1) {
                setCurrentQues(currentSegment?.questions[step - 2]);
                setStep(step - 1);
            }
        }
    };

    const { firstName, lastName, email, password } = formData;

    const handleValidSubmit = (e, v) => {
        // history.push('/neuro-profiler/' + user?._id, { userId: user?._id })
        if (!loading) {
            setLoading(true)
            let body = {
                token: user?.token,
                smeId: user?._id,
                memberId: v?.memberId,
                lang: i18n?.language,
                path: Utils?.API_URL + '/neuro-profiler/' + user?._id + '/' + trustedAmountVal + "/" + v?.memberId,
            }
            post('/nuro/invite_member', body)
                .then(res => {
                    setLoading(false)
                    // console.log("response from create Test", res);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setInvite(false);
                    } else if (res?.statusCode == 208) {
                        toast.error(res?.error);
                    } else {
                        toast.error("Something Went Wrong! Test Could not be started!");
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.error(err);
                    toast.error("Something Went wrong while creating a test!");
                })
        }
    }

    const handleSliderValue = (val) => {
        setProb(val);
        // currentQues?.options?.forEach((item) => {
        //     // console.log("checking for option", val, item?.binary_gamble?.y);
        //     let check = currentQues?.reference_gamble?.x - item?.binary_gamble?.x
        //     // if (check == val)
        //     //     setCurrentOtpId(item?.option_id);
        // })
    }

    const formatTooltip = (value, ques) => {
        // Customize the tooltip label here
        let str = value;
        // console.log("formatter tooltip", str);
        if (ques?.type == "Binary")
            str = str + " %";
        else {
            if (value == 1) {
                if (i18n?.language == "en")
                    str = ques?.option1?.title;
                else
                    str = ques?.option1?.titleFs;
            }
            if (value == 2) {
                if (i18n?.language == "en")
                    str = ques?.option2?.title;
                else
                    str = ques?.option2?.titleFs;
            }
            if (value == 3) {
                if (i18n?.language == "en")
                    str = ques?.option3?.title;
                else
                    str = ques?.option3?.titleFs;
            }
            if (value == 4) {
                if (i18n?.language == "en")
                    str = ques?.option4?.title;
                else
                    str = ques?.option4?.titleFs;
            }
            if (value == 5) {
                if (i18n?.language == "en")
                    str = ques?.option5?.title;
                else
                    str = ques?.option5?.titleFs;
            }
        }
        // return value + currentQues?.type == "Binary" ? " %" : "";
        return `${str}`;
    };

    const saveAnswers = (temp) => {
        if (!loading) {
            setLoading(true);
            // console.log("final answers", temp);
            post("/nuro/new/save/invite", { userId: userId, lang: i18n?.language, memId, answers: temp })
                .then(res => {
                    if (res?.statusCode == 200) {
                        // getUserData();
                        if (user?.step == 2) {
                            setLoading(false);
                            props?.profileSuccess({ ...user, step: 3 });
                            localStorage.setItem("user", JSON.stringify({ ...user, step: 3 }));
                        } else if (user?.step == 3) {
                            setLoading(false);
                            props?.profileSuccess({ ...user, step: 4 });
                            localStorage.setItem("user", JSON.stringify({ ...user, step: 4 }));
                        } else {
                            getUserData()
                        }
                    } else {
                        toast.error(res?.error);
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.log("Error while saving answers", err);
                    toast.error("Something Went Wrong!");
                    setLoading(false);
                })
        }
    }

    const clearFunction = (id) => {
        if (!loading) {
            let body = {
                neuroId: id,
            }
            post('/nuro/clear', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        setResult(null)
                        setTrustedAmount(true)
                    } else if (res?.statusCode == 401) {
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    // console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    // console.log('result', currentQues)

    return (
        <div className='member_form_section p-0'>
            <Row>
                <Loader loading={loading} />
                {currentQues ?
                    <Col className="col-12">
                        <div className='top_header-box'>
                            {/* <h4 className="card-title mb-0">{t("Neuroprofiler Questions")}</h4> */}
                            <div className='reuro-btn-box'>
                                {/* {result?.memResult?.length ?
                                <button className='btn_bg' onClick={() => setIsMemResult(true)}>Member Result</button> : null
                            }
                            <button className='btn_bg' onClick={() => setInvite(true)}>Invite Member</button> */}
                            </div>
                        </div>
                        <Card>
                            <CardBody >
                                <div className="wizard-form">
                                    <div className='header-row'>
                                        <h4 className="card-title mb-0">{i18n?.language == 'en' ? currentSegment?.title : currentSegment?.titleFs}{" "}
                                            {/* {currentFacet ? i18n?.language == 'en' ? "( " + currentFacet?.title + " )" : "( " + currentFacet?.titleFs + " )" : ""} */}
                                        </h4>
                                        <p className='total_question'>Question {step}/{currentFacet ? currentFacet?.questions?.length : currentSegment ? currentSegment?.questions?.length : 0}</p>
                                    </div>
                                    <div className="wizard-step">
                                        <div className='card_box_question'>
                                            {currentQues?.type != "Binary" ?
                                                <>
                                                    <Row>
                                                        <Col md={currentQues?.image ? 8 : 12}>
                                                            <p className='p1'>
                                                                {i18n?.language == 'en' ? currentQues?.title : currentQues?.titleFs}
                                                            </p>
                                                        </Col>
                                                        {
                                                            currentQues?.image && <Col md={4}>
                                                                <div className='question_image_section'>
                                                                    <img src={currentQues?.image} alt='question_img' />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <div className='d-flex justify-content-center align-items-center mt-5'>
                                                        <div style={{ width: 430 }}>
                                                            <Row>
                                                                <Col md={12} style={{ marginLeft: 10 }}>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className='inpu_amount'>
                                                                            <span>{i18n?.language == 'en' ? currentQues?.option1?.title : currentQues?.option1?.titleFs}</span>
                                                                        </div>
                                                                        <div className='inpu_amount'>
                                                                            <span>{i18n?.language == 'en' ? currentQues?.option2?.title : currentQues?.option2?.titleFs}</span>
                                                                        </div>
                                                                        <div className='inpu_amount'>
                                                                            <span>{i18n?.language == 'en' ? currentQues?.option3?.title : currentQues?.option3?.titleFs}</span>
                                                                        </div>
                                                                        <div className='inpu_amount'>
                                                                            <span>{i18n?.language == 'en' ? currentQues?.option4?.title : currentQues?.option4?.titleFs}</span>
                                                                        </div>
                                                                        <div className='inpu_amount'>
                                                                            <span>{i18n?.language == 'en' ? currentQues?.option5?.title : currentQues?.option5?.titleFs}</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <RangeSlider
                                                                        tooltip="on"
                                                                        tooltipPlacement="bottom"
                                                                        value={prob}
                                                                        tooltipLabel={(v) => formatTooltip(v, currentQues)}
                                                                        min={"1"}
                                                                        max={"5"}
                                                                        step={1}
                                                                        className="custom-range-slider"
                                                                        onChange={changeEvent => handleSliderValue(changeEvent.target.value)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        <Col md={currentQues?.image ? 8 : 12}>
                                                            <p className='p1'>
                                                                {i18n?.language == 'en' ? currentQues?.title : currentQues?.titleFs}
                                                            </p>
                                                        </Col>
                                                        {
                                                            currentQues?.image && <Col md={4}>
                                                                <div className='question_image_section'>
                                                                    <img src={currentQues?.image} alt='question_img' />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <div className='yes_no_button'>
                                                        <button className={value == 'no' ? 'active' : ''} onClick={() => setValue("no")}>{i18n?.language == 'en' ? currentQues?.negativeButton : currentQues?.negativeButtonFs}</button>
                                                        <button className={value == 'yes' ? 'active' : ''} onClick={() => setValue("yes")} style={{ marginLeft: 20 }}>{i18n?.language == 'en' ? currentQues?.positiveButton : currentQues?.positiveButtonFs}</button>
                                                    </div>
                                                    {value ?
                                                        <div className='d-flex justify-content-center align-items-center mt-5'>
                                                            <div style={{ width: 430 }}>
                                                                <Row>
                                                                    <Col md={12} style={{ marginLeft: 10 }}>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <div className='inpu_amount'>
                                                                                <span>0%</span>
                                                                            </div>
                                                                            <p className='p1 mb-0'>{i18n?.language == "en" ? "Level of Confidence" : "niveau de confiance"}</p>
                                                                            <div className='inpu_amount'>
                                                                                <span>100%</span>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <RangeSlider
                                                                            tooltip="on"
                                                                            tooltipPlacement="bottom"
                                                                            value={prob}
                                                                            tooltipLabel={(v) => formatTooltip(v, currentQues)}
                                                                            min={"0"}
                                                                            max={"100"}
                                                                            step={10}
                                                                            className="custom-range-slider"
                                                                            onChange={changeEvent => handleSliderValue(changeEvent.target.value)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </>
                                            }
                                            <div className='button_box_next'>
                                                <button style={{ visibility: step > 1 ? "visible" : "hidden" }} onClick={prevStep}>{t("Previous")}</button>
                                                <button onClick={nextStep}>{t("Next")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    : null}
                {result ?
                    <div className='chart-congnative-test-section'>
                        <div className='d-flex justify-content-center chart-box-h'>
                            <div className='chart-box-h-second'>
                                <RadarChart result={result} resultPer={resultPer} i18n={i18n} />
                                <div className="radar-headings">
                                    <h3 style={{ borderBottom: '5px solid rgba(29, 6, 116, 1)' }}>{i18n?.language == 'en' ? 'Overconfidence' : 'Excès de confiance'}</h3>
                                    <h3 style={{ borderBottom: '5px solid rgba(85, 154, 157, 1)' }}>{i18n?.language == 'en' ? 'Conscientiousness' : 'Conscience'}</h3>
                                    <h3 style={{ borderBottom: '5px solid rgba(27, 0, 94, 1)' }}>{i18n?.language == 'en' ? 'Optimism / Anxiety' : 'Optimisme / Anxiété'}</h3>
                                    <h3 style={{ borderBottom: '5px solid rgba(23, 202, 208, 1)' }}>{i18n?.language == 'en' ? 'Risk Appetite' : 'Appétit pour le risque'}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='cognative-test-box'>
                            <h4>{i18n?.language == "en" ?
                                "Disclaimer for the Behavioral and Personality Test Platform in the Onboarding Process"
                                :
                                "Avis de non-responsabilité concernant la plateforme de tests comportementaux et de personnalité dans le processus d'intégration"
                            }</h4>
                            <div className="popup_content">
                                {/* <p className="mt-0">{t("Dashboard one")}</p> */}
                                <ul>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "The results obtained through this test on our digital platform are provided for indicative purposes and are an integral part of our onboarding process, but do not constitute a definitive professional evaluation of your behavior or personality."
                                            : "Les résultats obtenus à travers ce test sur notre plateforme digitale sont fournis à titre indicatif et font partie intégrante de notre processus d'onboarding, mais ne constituent pas une évaluation professionnelle définitive de votre comportement ou de votre personnalité."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "This test evaluates certain aspects of your behavior, such as risk aversion, winning appetite, as well as elements of the Big Five personality test, including optimism, depression, and conscientiousness."
                                            : "Ce test évalue certains aspects de votre comportement, tels que l'aversion au risque, l'appétit pour la gagne, ainsi que des éléments du test de personnalité des Big Five, comme l'optimisme, la dépression et la rigueur."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "The answers you have provided are self-reported and may be influenced by various factors, including your current emotional state and your interpretation of the questions."
                                            : "Les réponses que vous avez fournies sont auto-déclarées et peuvent être influencées par divers facteurs, y compris votre état émotionnel actuel et votre interprétation des questions."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "The results of this test will not be the only elements taken into account in our evaluation and decision-making process regarding your funding application."
                                            : "Les résultats de ce test ne seront pas les seuls éléments pris en compte dans notre processus d'évaluation et de décision concernant votre demande de financement."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "While we strive to ensure the accuracy and reliability of our assessment tools, we cannot guarantee that the results will be accurate, complete, or up-to-date."
                                            : "Bien que nous nous efforcions de garantir l'exactitude et la fiabilité de nos outils d'évaluation, nous ne pouvons pas garantir que les résultats seront exacts, complets ou à jour."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "We strongly recommend consulting qualified professionals for a thorough evaluation and personalized advice before making any decisions based solely on the results of this test."
                                            : "Nous recommandons vivement de consulter des professionnels qualifiés pour une évaluation approfondie et des conseils personnalisés avant de prendre toute décision basée uniquement sur les résultats de ce test."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "The platform will not be held responsible for any loss or damage resulting from the use of or reliance on the results of this test."
                                            : "La plateforme ne sera pas tenue responsable de toute perte ou dommage résultant de l'utilisation ou de la confiance accordée aux résultats de ce test."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "By participating in this test, you agree that the results may be used to improve our services and research, in compliance with your confidentiality and data protection."
                                            : "En participant à ce test, vous acceptez que les résultats soient utilisés dans le cadre de l'amélioration de nos services et de nos recherches, dans le respect de votre confidentialité et de la protection de vos données personnelles."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "Your answers and the test results are subject to our privacy policy, which we encourage you to read carefully."
                                            : "Vos réponses et les résultats du test sont soumis à notre politique de confidentialité, que nous vous encourageons à lire attentivement."}
                                    </li>
                                    <li><i className="mdi mdi-hand-pointing-right"></i>
                                        {i18n?.language == "en" ? "By using this platform and taking this test, you acknowledge that you have understood and accepted the terms of this disclaimer and our general terms of use."
                                            : "En utilisant cette plateforme et en répondant à ce test, vous reconnaissez avoir compris et accepté les termes de ce disclaimer et nos conditions générales d'utilisation."}
                                    </li>

                                </ul>
                                <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                                {/* <div className='d-flex justify-content-center mt-4 mb-4'>
                            <Link to={"/cognative-test"}>
                                <button className='clear_profile' type='button'>Agree And Continue</button>
                            </Link>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    : null}
                {/* <button onClick={() => saveAnswers(answers)}>Save</button> */}
            </Row >
        </div>
    );
};



export default NewTestInvite