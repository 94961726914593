import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { post, get } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import { useRef } from 'react';
import Loader from 'components/Loader';

const Weight = props => {
    let { user } = props;
    const [lang, setLang] = useState(null);
    const [percent, setPercent] = useState(0);
    const [color, setColor] = useState('#000');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    let formRef = useRef();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Rating Formula"), link: "#" },
    ]
    useEffect(() => {
        props.setBreadcrumbItems(t("Rating Formula"), breadcrumbItems)
    }, []);

    console.log('user', user)

    const onChangeSetValue = () => {
        let total = Number(formRef.current?._inputs?.financingRequest?.value);
        total = total + Number(formRef.current?._inputs?.ratio?.value);
        total = total + Number(formRef.current?._inputs?.balanceSheet?.value);
        total = total + Number(formRef.current?._inputs?.profitAndLoss?.value);
        total = total + Number(formRef.current?._inputs?.cashFlow?.value);
        total = total + Number(formRef.current?._inputs?.eSG?.value);
        total = total + Number(formRef.current?._inputs?.nuroProfiler?.value);
        setPercent(total)
        if (total == 100) {
            setPercent(total)
            setColor('#000')
        } else if (total < 100) {

            setColor('red')
        } else {

            setColor('red')
        }

    }
    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                token: user?.token,

            }
            get('/weight/list', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data)
                        setPercent(Number(res?.data?.financingRequest) + Number(res?.data?.ratio) + Number(res?.data?.balanceSheet) + Number(res?.data?.profitAndLoss) + Number(res?.data?.cashFlow) + Number(res?.data?.eSG) + Number(res?.data?.nuroProfiler))
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }
    const handleValidSubmit = (e, v) => {
        if (!loading) {
            setLoading(true)
            if (percent > 100) {
                toast.success('Total percentage can not be greater than 100%');
            } else if (percent < 100) {
                toast.success('Total must be 100%');
            } else {
                let body = {
                    ...v,
                    token: user?.token,

                }
                post('/weight/add', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            getUserData()
                            toast.success(res?.message)

                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
        }
    }


    return (
        <React.Fragment>
        <Loader loading={loading} />


            <AvForm
                className="form-horizontal"
                ref={formRef}
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <h4 className="card-title mb-4">{t("Weight/Coef")}</h4>
                <Card>
                    <CardBody className='table-resp'>
                        <Table
                            bordered
                        >
                            <thead>
                                <tr>
                                    <th>
                                        {t("Financing Request")}
                                    </th>
                                    <th>
                                    {t("Ratio Analysis")}
                                    </th>
                                    <th>
                                    {t("Balance Sheet")}
                                    </th>
                                    <th>
                                    {t("Profit & Loss")}
                                    </th>
                                    <th>
                                    {t("Cash Flow")}
                                    </th>
                                    <th>
                                    {t("ESG Form")}
                                    </th>
                                    <th>
                                    {t("Neuroprofiler")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="financingRequest"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.financingRequest}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="ratio"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.ratio}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="balanceSheet"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.balanceSheet}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="profitAndLoss"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.profitAndLoss}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="cashFlow"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.cashFlow}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="eSG"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.eSG}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <AvField
                                                name="nuroProfiler"
                                                className="form-control"
                                                type="number"
                                                onChange={(e) => onChangeSetValue(e.target.value)}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                value={data?.nuroProfiler}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={7}><p style={{ color: color }}><strong>{i18n?.language == "en" ? "Total Percent":"Pourcentage total"}  :  {percent}%</strong></p></td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center">
                            <Button type="submit" className='btn_bg' color="">
                                {t("Submit")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}



Weight.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any,
    profileSuccess: PropTypes.func,
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, profileSuccess, setBreadcrumbItems })(Weight)
)

