import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { get, post } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
// import { withTranslation } from "react-i18next"

const FinancingDetails = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState([]);
    const [restKey,seRestKey] = useState(undefined)
    const formRef = useRef();
    const { user } = props;
    const { i18n, t } = useTranslation();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Financing Request", link: "#" },
    ];
    useEffect(() => {
        props.setBreadcrumbItems('Financing Request', breadcrumbItems)
    }, []);

    const handleInput = (v, q) => {
        let temp = Object.assign([], form);
        let already = temp?.findIndex(x => x.quesId == q)
        if (already == -1) {
            temp.push({ quesId: q, answer: v })
        }
        else {
            temp[already].answer = v
        }
        setForm(temp)
    }

    useEffect(() => {
        if (props?.user?.token)
            getUserData();
    }, [props])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/finance/question/list', body)
                .then((res => {
                    setLoading(false);
                    console.log('esg for sme question list', res)
                    if (res?.statusCode == 200) {
                        get(`/finance/answer/user?userId=${user?._id}`, { token: user?.token })
                            .then(ansRes => {
                                if (ansRes?.statusCode == 200) {
                                    let formTemp = [];
                                    res?.data?.map((item) => {
                                        if (ansRes?.data?.length > 0) {
                                            ansRes?.data?.map(ans => {
                                                if (ans?.questions?.length > 0) {
                                                    ans?.questions.map(ques => {
                                                        item?.questions?.map(ques2 => {
                                                            // ques2.answer = '';
                                                            if (ques?._id == ques2?._id) {
                                                                ques2.isOpen = false;
                                                                ques2.answer = ques?.answer;
                                                                formTemp.push({ quesId: ques?._id, answer: ques?.answer });
                                                                console.log("question type", ques2?.type, ques?.answer);
                                                                if (ques2?.type != "text") {
                                                                    if (ques.answer != "No") {
                                                                        ques2.isOpen = true;
                                                                    } else {
                                                                        ques2.answer = "No";
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    });
                                    setForm(formTemp);
                                    setData(res?.data)
                                    seRestKey(ansRes?.restKey)
                                    // console.log('ansRes',ansRes?.restKey)
                                }
                            })
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    console.log('onchange form', props)

    function handleValidSubmit(event, values) {
        if (!loading) {
            let body = {
                userId: user?._id,
                token: user?.token,
                form: form
            }
            // console.log('submitting form', body);
            // return;
            setLoading(true)
            post('/finance/answer', body)
                .then((res => {
                    setLoading(false);
                    console.log('ad answer', res)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error(res?.error)
                })
        }
    }

    const routeChange = () => {
        props?.history.push('/financing', { state: user?._id })
    }


    return (
        <React.Fragment>
            <MetaTags>
                <title>Financing Request | Demleen </title>
            </MetaTags>
            {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
            <Loader loading={loading} />
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <Table
                            >
                                <tbody >
                                    <tr>
                                        <td style={{ padding: 0, border: 'none' }} colspan="2">
                                            <Table
                                                bordered
                                            >
                                                <thead >
                                                    <tr className='right_head'>
                                                        <th colspan="2" style={{ textAlign: 'left' }}>
                                                            <div className='table_edit_box1'>
                                                                <span>{t("Financing Request")}</span>
                                                                <button onClick={routeChange} className='edit_button'>{t("Edit")} <i className="mdi mdi-pencil"></i></button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.map((segment, segI) => (
                                                        <>
                                                            <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>
                                                                <th style={{ width: '100%' }} colSpan={2}>
                                                                    {i18n?.language == 'en' ? segment?.title : segment?.titleFs}
                                                                </th>
                                                            </tr>
                                                            {segment?.questions?.map((item, index) => (
                                                                <tr>
                                                                    <td>
                                                                        {i18n?.language == 'en' ? item?.title : item?.titleFs}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.answer
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {/* <tr>
                                                                    <td>
                                                                        {t("Requested Amount of Funding?")}
                                                                    </td>
                                                                    <td>
                                                                        <AvField
                                                                            type="number"
                                                                            name="Amount"
                                                                        />
                                                                    </td>
                                                                </tr> */}
                                                        </>
                                                    ))}
                                                    <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>
                                                        <th style={{ width: '100%' }} colSpan={2}>
                                                            {t("Assessment of the monthly activity - based on the past 12 months?")}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Minimum monthly revenue based on the past 12 months?")}</td>
                                                        <td>
                                                           {restKey?.eFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Average monthy revenue based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.fFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly fixed charges based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.gFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly variable charges based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.hFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Monthly expenses based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.iFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Minimum monthly profit based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.jFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Average monthly profit based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.kFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Minimum monthly cash based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.lFine}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("Average monthly cash based on the past 12 months?")}</td>
                                                        <td>
                                                        {restKey?.mFine}
                                                        </td>
                                                    </tr>
                                                    <tr className='backgroun_td'>
                                                        <td>{t("Maximum amount to consider (50% of minimum monthly profit)")}</td>
                                                        <td>
                                                        {restKey?.nFine}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>

                                    </tr>

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>


        </React.Fragment>
    )
}

FinancingDetails.propTypes = {
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(FinancingDetails)
)