import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const profitabilityChart = ({ data, value, t }) => {

    // console.log('chart data', data)
    // const [title, setTitle] = useState(undefined);
    // let temp = [];
    // useEffect(()=>{
    //      props?.data?.map((item)=>(
    //         temp.push(item?.title)
    //      ))
    //      console.log('ration temp',temp)
    //      setTitle(temp)
    // },[ props?.data])

    const series = [
        {
            name: '',
            data: value,
        },
    ]
    const options = {
        chart: {
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString()
            },
        },
        colors: ["#559A9D", 'red'],
        grid: {
            borderColor: "#f1f1f1",
        },
        yaxis: {
            labels: {
                show: true, // Display y-axis labels
                style: {
                    colors: '#1B005E', // Set label text color
                    fontSize: '10px', // Set label font size
                    fontFamily: 'Verdana, sans-serif', // Set label font family
                    fontWeight: 200, // Set label font weight
                },
            },
        },
        xaxis: {
            labels: {
                show: true, // Display x-axis labels
                style: {
                    colors: '#000', // Set label text color
                    fontSize: '12px', // Set label font size
                    fontFamily: 'Arial, sans-serif', // Set label font family
                    fontWeight: 600, // Set label font weight
                },
            },
            categories: data?.map(element => t(element)),
        },
    }

    return (
        <ReactApexChart options={options} series={series} type="bar" height="350" />
    )
}

export default profitabilityChart
