import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import img1 from './assets/img/cyber/banner-2.jpg'
import img2 from './assets/img/f-second.png'
import { useTranslation } from 'react-i18next';
export default function MentionsLegales() {
  const { i18n, t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1')
  return (
    <div className='body_front'>
      <Header />
      <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
        <div class="container">
          <div class="row align-center">
            <div class="col-lg-6">
              <h2>Mentions Légales</h2>
            </div>

          </div>
        </div>
      </div>
     
      <div class="case-details-area services-details-area default-padding">
        <div class="container">
          {/* <div class="row">
            <div class="col-lg-12 thumb mb-5">
              <img src={img1} alt="Thumb" />
            </div>
          </div> */}
          <div class="row">
            <div class="col-lg-12 content px-3">

              <h2>Éditeur</h2>
              <ul>
                <li>Propriétaire et éditeur du site : Demleen</li>
                <li>Statut : SASU au Capital Social de 1 000 euros</li>
                <li>Immatriculation: 911 783 736 R.C.S. Créteil</li>
                <li>Adresse: 5 avenue du Général de Gaulle, 94160 Saint-Mandé</li>
                <li>Responsable publication : Eryomi Aholoukpe</li>
                <li>Mail responsable publication : eryomi@demleen.com</li>
              </ul>
              <h2>Hébergeur</h2>
              <p>Ce site est hébergé par OVH</p>

              <h2>Informatiques & Libertés</h2>
               <ul>
                <li>Les données personnelles collectées via les formulaires du sites sont uniquement destinés à un usage interne. En aucun cas ces données ne seront cédées, communiquées ou vendues à des tiers.</li>
                <li>Votre adresse e-mail sera utilisée pour répondre à une demande de contact e-mail.</li>
                <li>Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en nous adressant un email ou en nous contactant par téléphone.</li>
               </ul>
               <h2>Cookies</h2>
               <ul>
                <li>Les cookies sont des informations qui sont transmises d’un site Internet à votre disque dur afin d’enregistrer des données facilitant la navigation sur notre site Internet. Notre site utilise des cookies notamment pour les statistiques visiteurs via l’outil Google Analytics.</li>
                <li>Si vous ne souhaitez pas que des cookies enregistrent vos actions, vous pouvez les désactiver via une option de votre navigateur.</li>
               </ul>
              <div class="row thumbs">
                <div class="col-lg-6 col-md-6">
                <img src={img2} alt="Thumb" />
                </div>
                <div class="col-lg-6 col-md-6">
                <img src={img2} alt="Thumb" />
                </div>
              </div>
              <br/>
              <br/>
              <p>La Commission européenne fournit une plateforme de règlement des litiges en ligne (OS). Cette plateforme est disponible à l'adresse <a href='http://ec.europa.eu/consumers/odr/'>http://ec.europa.eu/consumers/odr/</a>. En tant que client, vous avez toujours la possibilité de contacter le conseil d'arbitrage de la Commission européenne. Nous ne sommes ni disposés à, ni obligés de, participer à une procédure de règlement des litiges devant un conseil d'arbitrage de la consommation.</p>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
