import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import moment from 'moment';
import Loader from 'components/Loader';
const ProfileData1 = props => {
    let { user } = props;
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();
    // console.log('profile page', props?.user)
    const { role } = props?.user;
    const [selectedSdg, setSelectedSdg] = useState([]);
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investmentRegion, setInvestmentRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const [lang, setLang] = useState(null);

    const [data, setData] = useState(null);
    const [age, setAge] = useState(null);
    const [userID, setUserID] = useState(null);
    const [alertType, setAlerType] = useState("");
    const [file, setFile] = useState(avatar);
    const [file1, setFile1] = useState(avatar);
    const [changeIcon, setChangeIcon] = useState(false);
    const [changeIcon1, setChangeIcon1] = useState(false);
    const [progress, setProgress] = useState(45)
    const [member, setMember] = useState([{}])
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const options = [

        { label: t("SDG 1 - No poverty"), value: t("SDG 1 - No poverty") },
        { label: t("SDG 2 - Zero hunger"), value: t("SDG 2 - Zero hunger") },
        { label: t("SDG 3 - Good health and well-being"), value: t("SDG 3 - Good health and well-being") },
        { label: t("SDG 4 - Quality education"), value: t("SDG 4 - Quality education") },
        { label: t("SDG 5 - Gender equality"), value: t("SDG 5 - Gender equality") },
        { label: t("SDG 6 - Clean water and sanitation"), value: t("SDG 6 - Clean water and sanitation") },
        { label: t("SDG 7 - Affordable and clean energy"), value: t("SDG 7 - Affordable and clean energy") },
        { label: t("SDG 8 - Decent work and economic growth"), value: t("SDG 8 - Decent work and economic growth") },
        { label: t("SDG 9 - Industry innovation and infrastructure"), value: t("SDG 9 - Industry innovation and infrastructure") },
        { label: t("SDG 10 - Reduced inequalities"), value: t("SDG 10 - Reduced inequalities") },
        { label: t("SDG 11 - Sustainable cities and communities"), value: t("SDG 11 - Sustainable cities and communities") },
        { label: t("SDG 12 - Responsible consumption and production"), value: t("SDG 12 - Responsible consumption and production") },
        { label: t("SDG 13 - Climate action"), value: t("SDG 13 - Climate action") },
        { label: t("SDG 14 - Life below water"), value: t("SDG 14 - Life below water") },
        { label: t("SDG 15 - Life on land"), value: t("SDG 15 - Life on land") },
        { label: t("SDG 16 - Peace, justice and strong institutions"), value: t("SDG 16 - Peace, justice and strong institutions") },
        { label: t("SDG 17 - Partnership for the goals"), value: t("SDG 17 - Partnership for the goals") },
    ];

    const businessSector = Utils.getBusinessSector();

    const sdgOptions = Utils.getSDG();

    const regionoptions = [
        { label: t("Western Africa"), value: t("Western Africa") },
        { label: t("Eastern Africa"), value: t("Eastern Africa") },
        { label: t("Northern Africa"), value: t("Northern Africa") },
        { label: t("Southern Africa"), value: t("Southern Africa") },
        { label: t("Central Africa"), value: t("Central Africa") },
    ]

    const investmentoptions = [
        { label: t("Equity"), value: t("Equity") },
        { label: t("Debt / Loans"), value: t("Debt / Loans") },
        { label: t("Guarantees"), value: t("Guarantees") },
        { label: t("Grants"), value: t("Grants") },
    ]

    const Business_Structure = [
        { label: t("Non-Profit"), value: t("Non-Profit") },
        { label: t("Cooperative"), value: t("Cooperative") },
        { label: t("For-Profit (sole)"), value: t("For-Profit (sole)") },
        { label: t("For Profit (one-person limited liability undertaking)"), value: t("For Profit (one-person limited liability undertaking)") },
        { label: t("For Profit (limited liability corporation)"), value: t("For Profit (limited liability corporation)") },
        { label: t("For Profit (simplified joint stock company)"), value: t("For Profit (simplified joint stock company)") },
        { label: t("For-Profit (partnership)"), value: t("For-Profit (partnership)") },
        { label: t("For-Profit (limited partnership)"), value: t("For-Profit (limited partnership)") },
    ]

    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: user?.token

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);

                        setData(res?.data)

                        setMember(res?.data?.member)
                        if (res?.data?.isEmailVerified == true) {
                            setProgress(70)
                            if (res?.data?.businessDesc) {
                                setProgress(100)
                            }
                        }

                        setAge(res?.data?.age)
                        let temp0 = [];
                        res?.data?.businessGoals?.map((item) => {
                            temp0.push({ label: item, value: item });
                        })
                        setRegion(temp0)
                        let temp = [];
                        res?.data?.investmentRegion?.map((item) => {
                            temp.push({ label: item, value: item });
                        })
                        setInvestmentRegion(temp);
                        let temp1 = [];
                        res?.data?.investmentInstrument?.map((item) => {
                            temp1.push({ label: item, value: item });
                        })
                        seInvestment(temp1)
                        let temp2 = [];
                        res?.data?.developmentGoals?.map((item) => {
                            temp2.push({ label: item, value: item });
                        })
                        setSdg(temp2)
                        let temp3 = [];
                        res?.data?.orgSector?.map((item) => {
                            temp3.push({ label: item, value: item });
                        })
                        setBusinessStructure(temp3)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const sdgFun = (e) => {
        setSdg(e)
        // console.log(e)
    }

    const getAge = (e) => {
        let userDateinput = e.target.value;
        let birthDate = new Date(userDateinput);
        // console.log(" birthDate"+ birthDate);

        // get difference from current date;
        let difference = Date.now() - birthDate.getTime();

        let ageDate = new Date(difference);
        // console.log(" ageDate"+ ageDate);
        let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        // console.log(" calculatedAge", calculatedAge);
        setAge(calculatedAge)
    }

    const getFile = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj)
            setChangeIcon(true)
        setFile(URL.createObjectURL(fileObj))
        // console.log('fileObj is', fileObj?.name);
    }

    const getFile1 = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj)
            setChangeIcon1(true)
        setFile1(URL.createObjectURL(fileObj))
        // console.log('fileObj is', fileObj?.name);
    }

    // console.log('user data', data)
    const deleteBox = (index) => {
        // console.log('index', index)
        let copyArr = Object.assign([], member);
        copyArr.splice(index, 1);
        // console.log('copyArr', copyArr)
        setMember(copyArr);
    }

    const addMemberData = (v, i, k) => {
        let temp = Object.assign([], member);
        temp[i][k] = v;
        setMember(temp)
    }
    // console.log('member', member)

    // useEffect(() => {
    //     if (props?.user?.token)
    //         getUserData1();
    // }, [props?.user?.token])

    // const getUserData1 = () => {
    //     let body = {
    //         userId: props?.userId,
    //         token: props?.user?.token,

    //     }
    //     post('/user', body)
    //         .then((res => {
    //             // setLoading(false);
    //             if (res?.statusCode == 200) {
    //                 console.log('request on login', res);

    //                 setData(res?.data)
    //                 setValue(res?.data?.profRating?.rating)
    //             } else {
    //                 console.log(res?.error);
    //             }
    //         }))
    //         .catch(error => {
    //             // setLoading(false);
    //             console.log('error while login', error);
    //             console.log('Something went wrong');
    //         })
    // }

    function handleValidSubmit(e, v) {
        if (!loading) {
            setLoading(true)
            let body = {

                userId: props?.userId,
                token: props?.user?.token,
                approveStatus: v?.approveStatus,
                comment: v?.proComment,
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData()
                        toast.success(res?.message)

                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    return (
        <React.Fragment>
            <Loader loading={loading} />

            <Row className='mb-3'>
                <Col lg="4">
                    <Media>
                        <div className="ms-3 profile_img_pick">
                            <img
                                src={data?.profile_picture ? data?.profile_picture : file}
                                alt=""
                                className="avatar-lg rounded-circle img-thumbnail"
                            />
                        </div>
                        <Media body className="align-self-center profile-content-box px-4">
                            <div className="text-muted">
                                <h5 className='mb-1'>{data?.fname} {data?.lname}</h5>
                                <p className="mb-0">{data?.username}</p>
                                <p className="mb-0">{data?.email}</p>
                                <p className="mb-0">{data?.gender}</p>
                                <div className="form_control_2 mt-2 pt-0" style={{ width: 'fit-content', backgroundColor: '#1b005e', color: '#fff' }}>
                                    {user?.role == "Admin" &&
                                        data?.approveStatus != "Pending" ?
                                        <>
                                            {
                                                data?.approveStatus == 'Submitted' &&
                                                <p className='mb-0' style={{ backgroundColor: 'orange', color: '#fff' }} >New Request</p>
                                            }
                                            {
                                                data?.approveStatus == 'In Progress' &&
                                                <p className='mb-0' style={{ backgroundColor: 'blue', color: '#fff' }} >In Progress</p>
                                            }
                                            {
                                                data?.approveStatus == 'Accepted' &&
                                                <p className='mb-0' style={{ backgroundColor: 'green', color: '#fff' }} >Accepted</p>
                                            }
                                            {
                                                data?.approveStatus == 'Rejected' &&
                                                <p className='mb-0' style={{ backgroundColor: 'red', color: '#fff' }} >Declined</p>
                                            }
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </Media>
                    </Media>
                </Col>
                <Col lg="8">
                    <div className='profile_progress_section'>
                        <ProgressBar
                            percent={progress}
                            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                        >
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className='progress_bar_section'>
                                        <div className='step_icon'>
                                            {
                                                accomplished ? <i className="mdi mdi-check"></i> : <p>1</p>
                                            }
                                        </div>
                                        <span>Signup</span>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className='progress_bar_section'>
                                        <div className='step_icon'>
                                            {
                                                accomplished ? <i className="mdi mdi-check"></i> : <p>2</p>
                                            }
                                        </div>
                                        <span>Login</span>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className='progress_bar_section'>
                                        <div className='step_icon'>
                                            {
                                                accomplished ? <i className="mdi mdi-check"></i> : <p>3</p>
                                            }
                                        </div>
                                        <span>Email Verification</span>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <div className='progress_bar_section'>
                                        <div className='step_icon'>
                                            {
                                                accomplished ? <i className="mdi mdi-check"></i> : <p>4</p>
                                            }
                                        </div>
                                        <span>Profile Completion</span>
                                    </div>
                                )}
                            </Step>
                            {/* <Step transition="scale">
                    {({ accomplished }) => (
                      <div className='progress_bar_section'>
                        <div className='step_icon'>
                          {
                            accomplished ? <i className="mdi mdi-check"></i> : <p>5</p>
                          }
                        </div>
                        <span>Profile Activation </span>
                      </div>
                    )}
                  </Step> */}
                        </ProgressBar>
                    </div>
                </Col>
            </Row>
            <AvForm
                className="form-horizontal form_horizontal_admin"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <h5 className=" mb-3">{t("Personal Details")}</h5>
                <Row className='mb-3'>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("First Name")}</label>
                            <div
                                className="form-control"

                            >{data?.fname}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Last Name")}</label>
                            <div

                                className="form-control"

                            >{data?.lname}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Email Address")}</label>
                            <div

                                className="form-control"

                            >{data?.email}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Phone Number")}</label>
                            <div

                                className="form-control"

                            >{data?.mobile}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Date of Birth")}</label>
                            <div

                                className="form-control"

                            >{data?.dob}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Age")}</label>
                            <div

                                className="form-control"

                            >{age}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Current Position At The Company*")}</label>
                            <div className="form-control" >{data?.designation}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Gender")}</label>
                            <div className="form-control" >{data?.gender}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Status")}</label>
                            <div className="form-control" >{data?.approveStatus}</div>
                        </div>
                    </Col>
                   
                    
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3 mt-2">
                            <label>{i18n?.language == 'en' ? 'Overall Rating':'Note globale'}</label>
                            <div className="form-control" >{props?.totalRating?.toFixed(2)}</div>
                        </div>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="form-group mb-3 mt-2">
                            <label>{i18n?.language == 'en' ? 'Overall Admin Review':"Examen global de l'administrateur"}</label>
                            <div className="form-control" >{data?.comment}</div>
                        </div>
                    </Col>
                </Row>


                <h5 className=" mb-3">{t("Company Details")}</h5>

                <Row>
                    <Col lg="3">
                        <div className='company_logo_card'>
                            <div className="profile_img_pick">
                                <img
                                    src={data?.companyLogo ? data?.companyLogo : file1}
                                    alt=""
                                    className="avatar-md rounded-circle img-thumbnail"
                                />


                            </div>
                            <p>Company Logo</p>
                        </div>

                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Business Legal Name")}</label>
                            <div

                                className="form-control"

                            >
                                {data?.businessName}
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Company Name")}</label>
                            <div
                                className="form-control"
                            >
                                {data?.companyName}
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Business Start Date")}</label>
                            <div


                                className="form-control"

                            >  {data?.businessStartDate?.split('T')[0]}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Registration Number")}</label>
                            <div


                                className="form-control"

                            >
                                {data?.businessRegisterNo}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Tax Id")}</label>
                            <div

                                className="form-control"

                            >
                                {data?.businessTaxId}
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Email Address")}</label>
                            <div
                                className="form-control"

                            >
                                {data?.businessEmail}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Phone Number")}</label>
                            <div
                                className="form-control"

                            >{data?.businessPhone}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("State")}</label>
                            <div
                                className="form-control"
                            >{data?.businessState}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("City")}</label>
                            <div

                                className="form-control"

                            >{data?.businessCity}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Zip Code")}</label>
                            <div
                                className="form-control"
                            >{data?.businessZipCode}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Business Structure")}</label>
                            <div className="form-control" name="businessStructure"
                            >{data?.businessStructure}</div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Business Sector")}</label>
                            <div className="form-control" name="businessSector"
                            >{data?.businessSector}</div>
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("Website")}</label>
                            <div

                                className="form-control"

                            >{data?.businessWebsite}</div>
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="form-group mb-3">
                            <label>{t("External Accounting partner")}</label>
                            <div

                                className="form-control"

                            >{data?.businessAccountPartner}</div>
                        </div>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="form-group mb-3">
                            <label>{t("Company Address")}</label>
                            <div
                                className="form-control"

                            >
                                {data?.businessAddress}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="form-group mb-3">
                            <label>{t("Related Sustainable Development Goals (3 max)")}</label>

                            <div

                                className="form-control"

                            >{data?.developmentGoals}</div>
                        </div>
                    </Col>
                    <Col lg={12} md={12}>
                        <div className="form-group mb-3">
                            <label>{t("Activity Description")}</label>
                            <div

                                className="form-control"

                            >
                                {data?.businessDesc}
                            </div>
                        </div>
                    </Col>
                </Row>


                {
                    role == 'Investor' &&
                    <>
                        <h4 className="card-title mb-4">{t("Your Organisation Preference Details")}</h4>


                        <Row>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Supported Sustainable Development Goals")}</label>
                                    <MultiSelect
                                        options={options} // Options to display in the dropdown
                                        hasSelectAll={true}
                                        value={sdg}
                                        onChange={sdgFun}
                                        overrideStrings={{
                                            selectSomeItems: t("Select Some items..."),
                                            allItemsAreSelected: t("All Items are Selected"),
                                            selectAll: t("Select All"),
                                            search: t("Search"),
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Investment Region in Africa")}</label>
                                    <MultiSelect
                                        options={regionoptions} // Options to display in the dropdown
                                        hasSelectAll={true}
                                        value={investmentRegion}
                                        onChange={setInvestmentRegion}
                                        overrideStrings={{
                                            selectSomeItems: t("Select Some items..."),
                                            allItemsAreSelected: t("All Items are Selected"),
                                            selectAll: t("Select All"),
                                            search: t("Search"),
                                        }}

                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Investment Instrument")}</label>
                                    <MultiSelect
                                        options={investmentoptions} // Options to display in the dropdown
                                        hasSelectAll={true}
                                        value={investment}
                                        onChange={seInvestment}
                                        overrideStrings={{
                                            selectSomeItems: t("Select Some items..."),
                                            allItemsAreSelected: t("All Items are Selected"),
                                            selectAll: t("Select All"),
                                            search: t("Search"),
                                        }}

                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Sector/Industry supported")}</label>
                                    <MultiSelect
                                        options={BusinessSector} // Options to display in the dropdown
                                        hasSelectAll={true}
                                        value={businessStructure}
                                        onChange={setBusinessStructure}
                                        overrideStrings={{
                                            selectSomeItems: t("Select Some items..."),
                                            allItemsAreSelected: t("All Items are Selected"),
                                            selectAll: t("Select All"),
                                            search: t("Search"),
                                        }}

                                    />
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <label style={{ fontWeight: 'bold', fontSize: 20 }}>{t("Investment Characteristics")}</label>
                                <div className="form-group d-flex justify_content_between mb-3">

                                    <AvField type="select" name="investmentCurrency" value={data?.investmentCurrency} label={t("Currency")} >
                                        <option>{t("Select")}</option>
                                        <option>EUR</option>
                                        <option>DOLLAR</option>
                                        <option>XOF/XAF - Franc CFA</option>
                                    </AvField>
                                    <AvField
                                        name="investmentAmount"
                                        label={t("Amount to Invest")}
                                        className="form-control"
                                        value={data?.investmentAmount}
                                        // placeholder="Amount to Invest"
                                        type="number"

                                    />
                                    <AvField type="select" name="investmentUnit" value={data?.investmentUnit} label={t("Unit")} >
                                        <option>{t("Select")}</option>
                                        <option>Million</option>
                                        <option>Billion</option>
                                        <option>Hundred Thousand</option>
                                    </AvField>

                                </div>
                            </Col>

                        </Row>

                    </>
                }
                <h5 className="mb-3">{t("Management Team")}</h5>

                {
                    member?.map((item, index) => (
                        <Row>
                            <Col lg={12}>
                                <div className='card_hrader'>
                                    <h6>{t("Membre")} {index + 1}</h6>

                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("First Name")}</label>
                                    <div
                                        className="form-control"
                                    >{item?.fname}</div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Last Name")}</label>
                                    <div

                                        className="form-control"

                                    >{item?.lname}</div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Email Address")}</label>
                                    <div

                                        className="form-control"

                                    >{item?.email}</div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Phone Number")}</label>
                                    <div

                                        className="form-control"

                                    >{item?.mobile}</div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Department")}</label>
                                    <div

                                        className="form-control"

                                    >{item?.department}</div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <label>{t("Designation")}</label>
                                    <div
                                        className="form-control"
                                    >{item?.designation}</div>
                                </div>
                            </Col>
                        </Row>
                    ))
                }

               
                {/*  <div className='member_section'>
                    <Card className="">
                        <CardBody>
                            <blockquote className="card-blockquote mb-0">

                                <ul className='member_section_detail'>
                                    <li><p><strong>Full Name :</strong> Akash Bhardwaj</p></li>
                                    <li><p><strong>Email :</strong> akash@gmail.com</p></li>
                                    <li><p><strong>Phone :</strong> 9999999999</p></li>
                                    <li><p><strong>Department :</strong></p></li>
                                    <li><p><strong>Designation :</strong></p></li>
                                </ul>

                            </blockquote>
                        </CardBody>
                    </Card>
                </div> */}
            </AvForm>


        </React.Fragment>
    )
}



export default ProfileData1;

