import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Button
} from "reactstrap"
import { Modal, ModalBody } from 'reactstrap';
import Cropper from "react-cropper";
import Resizer from "react-image-file-resizer";
import "cropperjs/dist/cropper.css";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"


// actions


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';


import { get, post, put, upload } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
const AddQuestion = (props) => {
    let history = useHistory();
    const { user, setIsAdd, loading, setLoading, currentData, setCurrentData, getData, segments } = props;
    const { i18n, t } = useTranslation();

    const handleValidSubmit = async (e, values) => {
        // setLoading(true);
        if (!loading) {
            setLoading(true);
            let body = {
                ...values,
                token: user?.token
            }
            if (currentData?._id) {
                body = {
                    ...body,
                    facetId: currentData?._id
                }

                put('/nuro/facet', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
            else {
                post('/nuro/facet', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
        }
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Add Facet", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems(t("Facet Neuroprofiler"), breadcrumbItems)
    })

    return (
        <React.Fragment>
            {/* <span>Add Segment</span> */}
            <div className={'add_question_modale active'}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}
                >
                    <Row>
                        <Col md={12}>
                            <div className='mb-3'>
                                <AvField
                                    name="segmentId"
                                    className="form-control"
                                    type="select"
                                    label="Segment"
                                    value={currentData?.segmentId}
                                >
                                    <option value="">{t("Select")} Segment</option>
                                    {segments?.map((item) => (
                                        <option value={item?._id}>{i18n?.language == 'en' ? item?.title:item?.titleFs}</option>
                                    ))}
                                </AvField>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="title"
                                    className="form-control"
                                    type="text"
                                    label="Title (English)"
                                    value={currentData?.title}
                                    placeholder="Enter the segment title for english"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <AvField
                                    name="titleFs"
                                    className="form-control"
                                    type="text"
                                    label={t("Title")+"(French)"}
                                    value={currentData?.titleFs}
                                    placeholder="Enter the segment title for french"
                                />
                            </div>
                        </Col>
                        <hr></hr>
                    </Row>
                    <div className="text-center">
                        <Button type="reset" onClick={() => setIsAdd(false)} className='btn_bg my-4' color="">
                            {t("Cancel")}
                        </Button>
                        <Button type="submit" className='btn_bg my-4 mx-4' color="">
                            {t(currentData?._id ? "Update" : "Save")}
                        </Button>
                    </div>
                </AvForm>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(AddQuestion)
)