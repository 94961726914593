import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { AvField, AvForm, AvInput, AvRadio, AvRadioGroup, AvCheckboxGroup, AvCheckbox, AvGroup } from 'availity-reactstrap-validation'
import { useTranslation } from 'react-i18next';
import { MultiSelect } from "react-multi-select-component";
import Loader from 'components/Loader';
import { post } from 'helpers/helper_api';
import { Label } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Utils from '../pages/Utils'
import { Helmet } from "react-helmet";
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';

export default function Contact(props) {
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1')
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [html, setHtml] = useState('');
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);

    const options = Utils?.getSDG();
    const regionoptions = Utils?.getRegions();

    const sdgFun = (e) => {
        setSdg(e)
        // console.log(e)
    }

    const handleValidSubmit = (e, values) => {

        // setLoading(true);

        let regionTemp = Object.assign([], region);
        let temp = [];
        if (regionTemp?.length > 0) {
            regionTemp?.map(item => {
                temp.push(item.value);
            })
            // return temp;

        } else {
            return toast.error('Please Select Investment Region in Africa')
        }
        let goals = Object.assign([], sdg);
        let temp1 = [];
        if (goals?.length > 0) {
            goals?.map(item => {
                temp1.push(item.value);
            })
            // return temp;

        } else {
            return toast.error('Please Select Supported Sustainable Development Goals')
        }

        if (!loading) {
            setLoading(true)
            const body = {
                ...values,
                investmentRegion: temp,
                developmentGoals: temp1,

            }

            post('/enquiry/add', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res?.data);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        setSdg([])
                        setRegion([])
                        ref?.current?.reset();
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }



    return (
        <div className='body_front'>
            <Helmet>

                <title>Funds for SMEs in Africa | Business Growth -Contact Us</title>
                <meta name="description" content="  Access funds for African SMEs to fuel business growth and expansion. Start your journey now.
" />
                <link rel="canonical" href="https://demleen.com/contact" />
            </Helmet>
            <Loader loading={loading} />
            <Header />

            <div class="breadcrumb-area shadow dark bg-fixed text-light inner_banner">
                <div class="container">
                    <div class="row align-center">
                        <div class="col-lg-6">
                            <h2>{t("Contact Us")}</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="contact-area overflow-hidden default-padding">
                <div class="container-fluid">
                    <div class="row">

                        <div class="col-lg-7 contact-form-box">
                            <div class="content">
                                <div class="heading">
                                    <h2>{t("ContactsHeading")}</h2>
                                    <p>{t("ContactsParagraph")}</p>
                                </div>
                                <AvForm
                                    ref={ref}
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                    class="contact-form">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="name" name="fname" placeholder={t("First Name")} type="text" required />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="name" name="lname" placeholder={t("Last Name")} type="text" required />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="email" name="email" placeholder="Email*" type="email" required />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="text" name="companyName" placeholder={t("Company name")} type="text" required />
                                                <span class="alert-error"></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-group form_group">
                                                <label className='label_text'>{t("Investment Region in Africa")}</label>
                                                <MultiSelect
                                                    options={regionoptions} // Options to display in the dropdown
                                                    hasSelectAll={true}
                                                    value={region}
                                                    onChange={setRegion}

                                                    overrideStrings={{
                                                        selectSomeItems: t("Select Some items..."),
                                                        allItemsAreSelected: t("All Items are Selected"),
                                                        selectAll: t("Select All"),
                                                        search: t("Search"),
                                                    }}

                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-group form_group">
                                                <label className='label_text'>{t("Supported Sustainable Development Goals")}</label>
                                                <MultiSelect
                                                    options={options} // Options to display in the dropdown
                                                    hasSelectAll={true}
                                                    value={sdg}
                                                    onChange={sdgFun}
                                                    overrideStrings={{
                                                        selectSomeItems: t("Select Some items..."),
                                                        allItemsAreSelected: t("All Items are Selected"),
                                                        selectAll: t("Select All"),
                                                        search: t("Search"),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div class="col-lg-6">
                                            <div class="form-group">
                                                <AvField class="form-control" id="phone" name="phone" placeholder="Phone" type="text"  />
                                                    <span class="alert-error"></span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='row mt-2'>

                                        <div class="col-lg-12">
                                            <div className="form-group form_group">
                                                <label class="label_text">{t("You are an *")}</label>
                                                <AvRadioGroup inline name="role" required>
                                                    <AvRadio label={t("SME")} value="SME" />
                                                    <AvRadio label={t("investor")} value="investor" />
                                                    <AvRadio label={t("other")} value="other" />
                                                </AvRadioGroup>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>

                                        <div class="col-lg-12">
                                            <div className="form-group form_group form_group_label">
                                                {/* <AvGroup> */}
                                                <AvField type="checkbox" name="subscribe" id="subscribe" label={t("Subscribe to the newsletter")} required />
                                                {/* <Label className='label_text' style={{ marginLeft: 8 }} for="subscribe">Subscribe to the newsletter</Label> */}
                                                {/* </AvGroup> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group comments">
                                                <AvField type="textarea" rows={5} class="form-control" id="comments" name="message" required placeholder={t("Please describe your requirement precisely")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <button type="submit" className='btn' name="submit" id="submit">
                                                {t("Ask for a demo")}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-md-12 alert-notification">
                                        <div id="message" class="alert-msg"></div>

                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{__html:html}} id='box_content'></div> */}
                                </AvForm>
                            </div>
                        </div>

                        <div class="col-lg-5 info">
                            <div class="contact-tabs">
                                <ul id="tabs" class="nav nav-tabs">
                                    <li className="nav-item">
                                        <a data-target="#tab1" onClick={() => setActiveTab('1')} data-toggle="tab" className={activeTab == '1' ? 'active nav-link' : 'nav-link'}>
                                            Address
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a data-target="#tab2" data-toggle="tab" onClick={() => setActiveTab('2')} className={activeTab == '2' ? 'active nav-link' : 'nav-link'}>
                                            Google Maps
                                        </a>
                                    </li>
                                </ul>
                                <div id="tabsContent" class="tab-content">
                                    <div id="tab1" className={activeTab == '1' ? 'tab-pane fade active show' : 'tab-pane fade'}>
                                        <ul>
                                            <li>
                                                <div class="icon">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </div>
                                                <div class="info">
                                                    <p>
                                                        Our Location
                                                        <span>5 avenue du Général de Gaulle, 94160 Saint-Mandé</span>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <i class="fas fa-envelope-open"></i>
                                                </div>
                                                <div class="info">
                                                    <p>
                                                        Send Us Mail
                                                        <span>contact@demleen.com</span>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <i class="fas fa-phone"></i>
                                                </div>
                                                <div class="info">
                                                    <p>
                                                        Call Us
                                                        <span>+33632049668</span>
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="tab2" className={activeTab == '2' ? 'tab-pane fade active show' : 'tab-pane fade'}>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.6621649321687!2d2.4177968!3d48.84558239999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67285ec0f044f%3A0xbd86e0e961e29987!2s5%20Av.%20du%20G%C3%A9n%C3%A9ral%20de%20Gaulle%2C%2094160%20Saint-Mand%C3%A9%2C%20France!5e0!3m2!1sen!2sin!4v1694755499051!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
