import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"


// actions


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';

import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
const EsgList = (props) => {
    let history = useHistory();
    const [businessStructure, setBusinessStructure] = useState([]);
    const [region, setRegion] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [sdg, setSdg] = useState([]);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [segment, setSegment] = useState([]);
     const {user} = props;
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [userData,setUserData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { i18n, t } = useTranslation();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "ESG Answer", link: "#" },
    ]

    useEffect(() => {
        if (props?.user?.token)
            getUserData()
    }, [props])
    useEffect(() => {
        if (props?.user?.token)
        getSegmentData()
    }, [props])

    useEffect(()=>{
        if(!profileModal)
          setCurrentData(null)
        },[profileModal])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
        let body = {
            userId: user?._id,
            token: user?.token
        }
        get('/esg/answer/list', body)
            .then((res => {
                setLoading(false);
                console.log('answer list', res?.data)
                if (res?.statusCode == 200) {
                    setData(res?.data)
                    setUserData(res?.data[0]?.user)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
        }
    }
    const getSegmentData = () => {
        let body = {
            userId: user?._id,
            token: user?.token
        }
        get('/esg/segment', body)
            .then((res => {
                // setLoading(false);
                console.log('segment list', res)
                if (res?.statusCode == 200) {
                    setSegment(res?.data)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                // setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }
    useEffect(() => {
        props.setBreadcrumbItems('ESG Answer List', breadcrumbItems)
    })

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    useEffect(()=>{
       setColumns([


        {
            name: 'Name',
            selector: row => row?.user[0]?.fname+' '+row?.user[0]?.lname,
        },
        {
            name: t('Email'),
            selector: row =>row?.user[0]?.email,
        },
        {
            name: t('Role'),
            selector: row => row?.user[0]?.role,

        },
        {
            name: t('Status'),
            selector: row =>
                <div className='status_box'>

                    {
                        <button className={row?.isActive == true ? 'active' : 'Inactive'} onClick={() => { setCurrentData({ ...row, actionType: "Status" }); setDeleteModal(true) }}>{row?.isActive == true ? 'Active' : 'Inactive'}</button>
                    }


                </div>
            ,
        },
        {
            name: 'Action',
            selector: row =>
                <div className='action_box'>

                    <button onClick={()=>history.push('/esg-details', { data: row?.form })}><i class="mdi mdi-eye" data-bs-toggle="tooltip" data-bs-placement="top" title={t("View")} onClick={ExpandedComponent}></i></button>
                    <button onClick={() => { setCurrentData(row); setDeleteModal(true) }}><i class="mdi mdi-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t('Delete')}></i></button>
                    {/* <button ><i class="mdi mdi-heart" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Company"></i></button> */}
                </div>,

        },
    ])
    },[data])




    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;

    const handleValidSubmit = (e, values) => {

        // setLoading(true);



        if (!loading) {
            setLoading(true)
            let body = {
                ...values,
                token: user?.token
            }
            if (currentData) {
                body = {
                    ...body,
                    quesId: currentData?._id
                }
                put('/esg/question', body)
                    .then((res => {
                        setLoading(false);
                        
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message)
                            getUserData()
                            setProfileModal(false)
                            // history.push('/dashboard')
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
            else {
                post('/esg/question', body)
                    .then((res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            // console.log('request on login', res);
                            toast.success(res?.message)
                            // history.push('/dashboard')
                            getUserData()
                            setProfileModal(false)
                        } else if (res?.statusCode == 401) {
                            history?.push('/logout')
                        } else {
                            console.log(res?.error);
                            toast.error(res?.error)
                        }
                    }))
                    .catch(error => {
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                        toast.error('Something went wrong')
                    })
            }
        }
    }


    const statusChange = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                isActive: !currentData?.isActive,
                quesId: currentData?._id
            }

            put('/esg/question', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const onDelete = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                quesId: currentData?._id
            }

            post('/esg/question/delete', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }



    return (
        <React.Fragment>
            <Loader loading={loading} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={currentData?.actionType == 'Status' ? statusChange : onDelete}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <MetaTags>
                <title>Esg Question | Demleen </title>
            </MetaTags>
            <Modal centered show={profileModal} className='model_profile' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='add_segment'>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >

                            <div className='mb-3'>
                                <AvField
                                    name="segmentId"
                                    className="form-control"
                                    type="select"
                                    label="Segment"
                                    value={currentData?.type}
                                    required
                                >
                                    <option value="">{t("Select Segment")}</option>
                                    {
                                        segment?.map((item,index)=>{
                                            if(item?.isActive)
                                            return(
                                                <option value={item?._id}>{i18n?.language == 'en' ? item?.title:item?.titleFs}</option>
                                            )
                                        })
                                    }
                                </AvField>
                            </div>
                            <div className='mb-3'>
                                <AvField
                                    name="type"

                                    className="form-control"
                                    // placeholder="Sales of goods for resale"

                                    type="select"
                                    label="Question Type"
                                    value={currentData?.type}

                                    required
                                >
                                    <option value="">{t("Select")}</option>
                                    <option value="text">Text</option>
                                    <option value="Yes or No with specify">Yes or No with specify</option>
                                </AvField>
                            </div>
                            <h4>English</h4>
                            <div className='mb-3'>

                                <AvField
                                    name="title"

                                    className="form-control"
                                    // placeholder="Sales of goods for resale
                                    type="text"
                                    label="Title"
                                    value={currentData?.title}

                                // required
                                />
                            </div>

                            <h4>French</h4>
                            <div className='mb-3'>

                                <AvField
                                    name="titleFs"

                                    className="form-control"
                                    // placeholder="Sales of goods for resale
                                    type="text"
                                    label="Title"
                                    value={currentData?.titleFs}

                                // required
                                />
                            </div>

                            <div className="text-center">
                                <Button type="submit" className='btn_bg' color="">
                                    {t(currentData ? "Update" : "Save")}
                                </Button>
                            </div>
                        </AvForm>
                    </div>

                </Modal.Body>

            </Modal>

            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody >

                            <div className='investor_component'>
                               
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    className='esgTable_section_new'
                                    // expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap

                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(EsgList)
)