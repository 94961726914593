import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"


// actions


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';

import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from './DeleteModal';
const SubscribeList = (props) => {
    let history = useHistory();
    const [businessStructure, setBusinessStructure] = useState([]);
    const [region, setRegion] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [sdg, setSdg] = useState([]);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [segment, setSegment] = useState([]);
    const { user } = props;
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const { i18n, t } = useTranslation();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Subscription List"), link: "#" },
    ]

    useEffect(() => {
        if (props?.user?.token)
            getUserData()
    }, [props])


    useEffect(() => {
        if (!profileModal)
            setCurrentData(null)
    }, [profileModal])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/subscribe', body)
                .then((res => {
                    setLoading(false);
                    console.log('segment list', res)
                    if (res?.statusCode == 200) {
                        setData(res?.data)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    useEffect(() => {
        props.setBreadcrumbItems(t("Subscription List"), breadcrumbItems)
    })

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    const columns = [

        {
            name: t('email_t'),
            selector: row => row?.email,
            maxWidth: '70%'

        },
        {
            name: t('Date'),
            selector: row => row?.createdAt,
            maxWidth: '200px'

        },
    ];


    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;


    const statusChange = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                isActive: !currentData?.isActive,
                enquiryId: currentData?._id
            }

            put('/enquiry', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const onDelete = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                enquiryId: currentData?._id
            }

            post('/enquiry/delete', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }



    return (
        <React.Fragment>
            <Loader loading={loading} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={currentData?.actionType == 'Status' ? statusChange : onDelete}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Modal centered show={profileModal} className='model_profile' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquiry Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='profile_d'>
                        <ul className='profile_details_box'>
                            <li>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    First Name
                                </h4>
                                <span>{currentData?.fname}</span>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Last Name
                                </h4>
                                <span>{currentData?.lname}</span>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Email
                                </h4>
                                <span>{currentData?.email}</span>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Investment Region in Africa
                                </h4>
                                {
                                    currentData?.investmentRegion?.map((item) => (
                                        <span>{t(item)} ,</span>
                                    ))
                                }
                            </li>
                            <li style={{ width: '100%' }}>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Supported Sustainable Development Goals
                                </h4>
                                {
                                    currentData?.developmentGoals?.map((item) => (
                                        <span>{t(item)} ,</span>
                                    ))
                                }
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Role
                                </h4>
                                {currentData?.role}

                            </li>
                            <li style={{ width: '100%' }}>
                                <h4 className="mt-0 font-size-16 mb-0">
                                    Message
                                </h4>
                                <span>{currentData?.message}</span>
                            </li>

                        </ul>

                        {/* <hr /> */}
                    </div>

                </Modal.Body>

            </Modal>

            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody >

                            <div className='investor_component'>

                                <DataTable
                                    columns={columns}
                                    data={data}
                                    className=''
                                    // expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap

                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(SubscribeList)
)