import PropTypes, { number } from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import * as Utils from "../Utils";
import { Space, Table, Tag } from 'antd';
const { Column, ColumnGroup } = Table;
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useLocation } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { setBreadcrumbItems, profileSuccess } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import ExcelFile from '../../assets/excel/sample.xlsx'
import { FileUploader } from "react-drag-drop-files";

import { upload, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import MyPrompt from 'components/MyPrompt';
import DeleteModal from 'pages/Admin/DeleteModal';
import Loader from 'components/Loader';

const BalanceSheet = props => {
    const location = useLocation();
    const scrollContainerRef = useRef(null);
    const fileTypes = ["xlsx"];
    const years = Utils.getYears();
    const [type, setType] = useState('save');
    const [helpModal, setHelpModal] = useState(false);
    const [intangiblefixedassetsGross1, setIntangiblefixedassetsGross1] = useState(0)
    const [intangiblefixedassetsDepreciation1, setIntangiblefixedassetsDepreciation1] = useState(0)
    const [intangiblefixedassetsNet1, setIntangiblefixedassetsNet1] = useState(0)
    const [intangiblefixedassetsGross2, setIntangiblefixedassetsGross2] = useState(0)
    const [intangiblefixedassetsDepreciation2, setIntangiblefixedassetsDepreciation2] = useState(0)
    const [intangiblefixedassetsNet2, setIntangiblefixedassetsNet2] = useState(0)
    const [intangiblefixedassetsGross3, setIntangiblefixedassetsGross3] = useState(0)
    const [intangiblefixedassetsDepreciation3, setIntangiblefixedassetsDepreciation3] = useState(0)
    const [intangiblefixedassetsNet3, setIntangiblefixedassetsNet3] = useState(0)

    const [tangiblefixedassetsGross1, setTangiblefixedassetsGross1] = useState(0)
    const [tangiblefixedassetsDepreciation1, setTangiblefixedassetsDepreciation1] = useState(0)
    const [tangiblefixedassetsNet1, setTangiblefixedassetsNet1] = useState(0)
    const [tangiblefixedassetsGross2, setTangiblefixedassetsGross2] = useState(0)
    const [tangiblefixedassetsDepreciation2, setTangiblefixedassetsDepreciation2] = useState(0)
    const [tangiblefixedassetsNet2, setTangiblefixedassetsNet2] = useState(0)
    const [tangiblefixedassetsGross3, setTangiblefixedassetsGross3] = useState(0)
    const [tangiblefixedassetsDepreciation3, setTangiblefixedassetsDepreciation3] = useState(0)
    const [tangiblefixedassetsNet3, setTangiblefixedassetsNet3] = useState(0)

    const [financialFixedAssetsGross1, setFinancialFixedAssetsGross1] = useState(0)
    const [financialFixedAssetsDepreciation1, setFinancialFixedAssetsDepreciation1] = useState(0)
    const [financialFixedAssetsNet1, setFinancialFixedAssetsNet1] = useState(0)
    const [financialFixedAssetsGross2, setFinancialFixedAssetsGross2] = useState(0)
    const [financialFixedAssetsDepreciation2, setFinancialFixedAssetsDepreciation2] = useState(0)
    const [financialFixedAssetsNet2, setFinancialFixedAssetsNet2] = useState(0)
    const [financialFixedAssetsGross3, setFinancialFixedAssetsGross3] = useState(0)
    const [financialFixedAssetsDepreciation3, setFinancialFixedAssetsDepreciation3] = useState(0)
    const [financialFixedAssetsNet3, setFinancialFixedAssetsNet3] = useState(0)


    const [totalFixedAssetsGross1, setTotalFixedAssetsGross1] = useState(0)
    const [totalFixedAssetsDepreciation1, setTotalFixedAssetsDepreciation1] = useState(0)
    const [totalFixedAssetsNet1, setTotalFixedAssetsNet1] = useState(0)
    const [totalFixedAssetsGross2, setTotalFixedAssetsGross2] = useState(0)
    const [totalFixedAssetsDepreciation2, setTotalFixedAssetsDepreciation2] = useState(0)
    const [totalFixedAssetsNet2, setTotalFixedAssetsNet2] = useState(0)
    const [totalFixedAssetsGross3, setTotalFixedAssetsGross3] = useState(0)
    const [totalFixedAssetsDepreciation3, setTotalFixedAssetsDepreciation3] = useState(0)
    const [totalFixedAssetsNet3, setTotalFixedAssetsNet3] = useState(0)

    const [suppliersAccountsPayableGross1, setSuppliersAccountsPayableGross1] = useState(0)
    const [suppliersAccountsPayableDepreciation1, setSuppliersAccountsPayableDepreciation1] = useState(0)
    const [suppliersAccountsPayableNet1, setSuppliersAccountsPayableNet1] = useState(0)
    const [suppliersAccountsPayableGross2, setSuppliersAccountsPayableGross2] = useState(0)
    const [suppliersAccountsPayableDepreciation2, setSuppliersAccountsPayableDepreciation2] = useState(0)
    const [suppliersAccountsPayableNet2, setSuppliersAccountsPayableNet2] = useState(0)
    const [suppliersAccountsPayableGross3, setSuppliersAccountsPayableGross3] = useState(0)
    const [suppliersAccountsPayableDepreciation3, setSuppliersAccountsPayableDepreciation3] = useState(0)
    const [suppliersAccountsPayableNet3, setSuppliersAccountsPayableNet3] = useState(0)

    const [totalCurrentAssetsGross1, setTotalCurrentAssetsGross1] = useState(0)
    const [totalCurrentAssetsDepreciation1, setTotalCurrentAssetsDepreciation1] = useState(0)
    const [totalCurrentAssetsNet1, setTotalCurrentAssetsNet1] = useState(0)
    const [totalCurrentAssetsGross2, setTotalCurrentAssetsGross2] = useState(0)
    const [totalCurrentAssetsDepreciation2, setTotalCurrentAssetsDepreciation2] = useState(0)
    const [totalCurrentAssetsNet2, setTotalCurrentAssetsNet2] = useState(0)
    const [totalCurrentAssetsGross3, setTotalCurrentAssetsGross3] = useState(0)
    const [totalCurrentAssetsDepreciation3, setTotalCurrentAssetsDepreciation3] = useState(0)
    const [totalCurrentAssetsNet3, setTotalCurrentAssetsNet3] = useState(0)

    const [totalCurrentAssetsGross_1, setTotalCurrentAssetsGross_1] = useState(0)
    const [totalCurrentAssetsDepreciation_1, setTotalCurrentAssetsDepreciation_1] = useState(0)
    const [totalCurrentAssetsNet_1, setTotalCurrentAssetsNet_1] = useState(0)
    const [totalCurrentAssetsGross_2, setTotalCurrentAssetsGross_2] = useState(0)
    const [totalCurrentAssetsDepreciation_2, setTotalCurrentAssetsDepreciation_2] = useState(0)
    const [totalCurrentAssetsNet_2, setTotalCurrentAssetsNet_2] = useState(0)
    const [totalCurrentAssetsGross_3, setTotalCurrentAssetsGross_3] = useState(0)
    const [totalCurrentAssetsDepreciation_3, setTotalCurrentAssetsDepreciation_3] = useState(0)
    const [totalCurrentAssetsNet_3, setTotalCurrentAssetsNet_3] = useState(0)


    const [cashandFinancialIInstrumentsGross1, setCashandFinancialIInstrumentsGross1] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation1, setCashandFinancialIInstrumentsDepreciation1] = useState(0)
    const [cashandFinancialIInstrumentsNet1, setCashandFinancialIInstrumentsNet1] = useState(0)
    const [cashandFinancialIInstrumentsGross2, setCashandFinancialIInstrumentsGross2] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation2, setCashandFinancialIInstrumentsDepreciation2] = useState(0)
    const [cashandFinancialIInstrumentsNet2, setCashandFinancialIInstrumentsNet2] = useState(0)
    const [cashandFinancialIInstrumentsGross3, setCashandFinancialIInstrumentsGross3] = useState(0)
    const [cashandFinancialIInstrumentsDepreciation3, setCashandFinancialIInstrumentsDepreciation3] = useState(0)
    const [cashandFinancialIInstrumentsNet3, setCashandFinancialIInstrumentsNet3] = useState(0)

    const [valuationDifferenceAssetsGross1, setValuationDifferenceAssetsGross1] = useState(0)
    const [valuationDifferenceAssetsDepreciation1, setValuationDifferenceAssetsDepreciation1] = useState(0)
    const [valuationDifferenceAssetsNet1, setValuationDifferenceAssetsNet1] = useState(0)
    const [valuationDifferenceAssetsGross2, setValuationDifferenceAssetsGross2] = useState(0)
    const [valuationDifferenceAssetsDepreciation2, setValuationDifferenceAssetsDepreciation2] = useState(0)
    const [valuationDifferenceAssetsNet2, setValuationDifferenceAssetsNet2] = useState(0)
    const [valuationDifferenceAssetsGross3, setValuationDifferenceAssetsGross3] = useState(0)
    const [valuationDifferenceAssetsDepreciation3, setValuationDifferenceAssetsDepreciation3] = useState(0)
    const [valuationDifferenceAssetsNet3, setValuationDifferenceAssetsNet3] = useState(0)

    const [totalAssetsGross1, setTotalAssetsGross1] = useState(0)
    const [totalAssetsDepreciation1, setTotalAssetsDepreciation1] = useState(0)
    const [totalAssetsNet1, setTotalAssetsNet1] = useState(0)
    const [totalAssetsGross2, setTotalAssetsGross2] = useState(0)
    const [totalAssetsDepreciation2, setTotalAssetsDepreciation2] = useState(0)
    const [totalAssetsNet2, setTotalAssetsNet2] = useState(0)
    const [totalAssetsGross3, setTotalAssetsGross3] = useState(0)
    const [totalAssetsDepreciation3, setTotalAssetsDepreciation3] = useState(0)
    const [totalAssetsNet3, setTotalAssetsNet3] = useState(0)


    const { i18n, t } = useTranslation();
    const [excelUploadModal, setExcelUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [allFieldsNotEmpty, setAllFieldsNotEmpty] = useState(false)
    const [formValue, setFormValue] = useState();
    const [loading, setLoading] = useState(false);
    const [year1, setYear1] = useState(undefined);
    const [year2, setYear2] = useState(undefined);
    const [year3, setYear3] = useState(undefined);

    const dataReset = {
        "landDepreciation": 0,
        "landGross": 0,
        "landNet": 0,
        "advancePaymentsDepreciation": 0,
        "advancePaymentsGross": 0,
        "advancePaymentsNet": 0,
        "banksFinancialDepreciation": 0,
        "banksFinancialGross": 0,
        "banksFinancialNet": 0,
        "cashAndCashEquivalentsDepreciation": 0,
        "cashAndCashEquivalentsGross": 0,
        "cashAndCashEquivalentsNet": 0,
        "cashandFinancialIInstrumentsDepreciation": 0,
        "cashandFinancialIInstrumentsGross": 0,
        "cashandFinancialIInstrumentsNet": 0,
        "concessionsAndSimilarRightsDepreciation": 0,
        "concessionsAndSimilarRightsGross": 0,
        "concessionsAndSimilarRightsNet": 0,
        "constructionsDepreciation": 0,
        "constructionsGross": 0,
        "constructionsNet": 0,
        "currentAssetsDepreciation": 0,
        "currentAssetsGross": 0,
        "currentAssetsNet": 0,
        "equityInvestmentsDepreciation": 0,
        "equityInvestmentsGross": 0,
        "equityInvestmentsNet": 0,
        "financialFixedAssetsDepreciation": 0,
        "financialFixedAssetsGross": 0,
        "financialFixedAssetsNet": 0,
        "goodwillLeasholdRightsDepreciation": 0,
        "goodwillLeasholdRightsGross": 0,
        "goodwillLeasholdRightsNet": 0,
        "intangiblefixedassetsDepreciation": 0,
        "intangiblefixedassetsGross": 0,
        "intangiblefixedassetsNet": 0,
        "inventoryAndWorkInProgressDepreciation": 0,
        "inventoryAndWorkInProgressGross": 0,
        "inventoryAndWorkInProgressNet": 0,
        "officeEquipmentDepreciation": 0,
        "officeEquipmentGross": 0,
        "officeEquipmentNet": 0,
        "otherFinancialFixedAssetsDepreciation": 0,
        "otherFinancialFixedAssetsGross": 0,
        "otherFinancialFixedAssetsNet": 0,
        "otherIntangibleFixedAssetsDepreciation": 0,
        "otherIntangibleFixedAssetsGross": 0,
        "otherIntangibleFixedAssetsNet": 0,
        "otherReceivablesDepreciation": 0,
        "otherReceivablesGross": 0,
        "otherReceivablesNet": 0,
        "researchAndDevelopmentCostsDepreciation": 0,
        "researchAndDevelopmentCostsGross": 0,
        "researchAndDevelopmentCostsNet": 0,
        "sharesAndMarketableSecuritiesDepreciation": 0,
        "sharesAndMarketableSecuritiesGross": 0,
        "sharesAndMarketableSecuritiesNet": 0,
        "suppliersAccountsDepreciation": 0,
        "suppliersAccountsGross": 0,
        "suppliersAccountsNet": 0,
        "suppliersAccountsPayableDepreciation": 0,
        "suppliersAccountsPayableGross": 0,
        "suppliersAccountsPayableNet": 0,
        "suppliersAdvancePaymentsDepreciation": 0,
        "suppliersAdvancePaymentsGross": 0,
        "suppliersAdvancePaymentsNet": 0,
        "tangiblefixedassetsDepreciation": 0,
        "tangiblefixedassetsGross": 0,
        "tangiblefixedassetsNet": 0,
        "technicalInstallationsDepreciation": 0,
        "technicalInstallationsGross": 0,
        "technicalInstallationsNet": 0,
        "totalAssetsDepreciation": 0,
        "totalAssetsGross": 0,
        "totalAssetsNet": 0,
        "totalCurrentAssetsDepreciation": 0,
        "totalCurrentAssetsGross": 0,
        "totalCurrentAssetsNet": 0,
        "totalFixedAssetsDepreciation": 0,
        "totalFixedAssetsGross": 0,
        "totalFixedAssetsNet": 0,
        "tradeReceivableDepreciation": 0,
        "tradeReceivableGross": 0
    }


    // useEffect(() => {
    //     IntangiblefixedassetsGrossFun1();
    //     tangiblefixedassetsFun();
    //     financialFixedAssetsFun();
    //     suppliersAccountsPayableFun();
    //     totalCurrentAssetsFun();
    //     cashandFinancialIInstrumentsFun();
    //     totalAssetsFun();
    // }, [year1, year2, year3])


    const handleChange = (file) => {
        setFile(file);
    };
    const toggle = () => setExcelUploadModal(!excelUploadModal);
    const formRef = useRef();

    const { user } = props;
    const scrollAmount = 300;

    const scrollLeftHandler = () => {
        scrollContainerRef.current.scrollLeft -= scrollAmount;
    };

    const scrollRightHandler = () => {
        scrollContainerRef.current.scrollLeft += scrollAmount;
    };


    const getUserData = (year, setter) => {
        if (year)
            if (!loading) {
                setter(dataReset)
                setLoading(true);
                let body = {
                    year,
                    userId: user?._id,
                    token: user?.token
                }
                post('/balance_sheet', body)
                    .then((res => {
                        setLoading(false);
                        // formRef.current.reset();
                        if (res?.statusCode == 200) {
                            // console.log('res for balance sheet', res);
                            setter(res?.data);
                            IntangiblefixedassetsGrossFun1();
                            tangiblefixedassetsFun();
                            financialFixedAssetsFun();
                            suppliersAccountsPayableFun();
                            totalCurrentAssetsFun();
                            cashandFinancialIInstrumentsFun();
                            totalAssetsFun();
                        } else if (res?.statusCode == 404) {
                            console.log("data not found!");
                            setter({ year });
                            resetValues();
                        } else {
                            setter({ year });
                            resetValues();
                            console.log("data not found else!", res?.error);
                        }
                    }))
                    .catch(error => {
                        setter({ year });
                        resetValues();
                        setLoading(false);
                        console.log('error while login', error);
                        console.log('Something went wrong');
                    });
            }
            else {
                setter({ year });
                resetValues();
            }
    }

    useEffect(() => {
        // const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        // if (isEmpty) {
        //    setButtonStatus(false)
        // } else {
        //     setButtonStatus(true)
        // }
        const form = document.getElementById('myForm'); // Replace 'myForm' with your form's ID
        const formElements = form.getElementsByTagName('input');
        let allFieldsNotEmpty1 = true;
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            // console.log('element', element.value)
            if (element.tagName === 'INPUT' && element.value === '') {
                allFieldsNotEmpty1 = false;
                break;
            }
        }
        setAllFieldsNotEmpty(allFieldsNotEmpty1)

    }, [formValue])

    useEffect(() => {
        setTotalFixedAssetsGross1(intangiblefixedassetsGross1 + tangiblefixedassetsGross1 + financialFixedAssetsGross1)
    }, [intangiblefixedassetsGross1, tangiblefixedassetsGross1, financialFixedAssetsGross1])

    useEffect(() => {
        setTotalFixedAssetsDepreciation1(intangiblefixedassetsDepreciation1 + tangiblefixedassetsDepreciation1 + financialFixedAssetsDepreciation1)
    }, [intangiblefixedassetsDepreciation1, tangiblefixedassetsDepreciation1, financialFixedAssetsDepreciation1])

    useEffect(() => {
        setTotalFixedAssetsNet1(totalFixedAssetsGross1 - totalFixedAssetsDepreciation1)
    }, [totalFixedAssetsGross1, totalFixedAssetsDepreciation1])


    useEffect(() => {
        setTotalFixedAssetsGross2(intangiblefixedassetsGross2 + tangiblefixedassetsGross2 + financialFixedAssetsGross2)
    }, [intangiblefixedassetsGross2, tangiblefixedassetsGross2, financialFixedAssetsGross2])

    useEffect(() => {
        setTotalFixedAssetsDepreciation2(intangiblefixedassetsDepreciation2 + tangiblefixedassetsDepreciation2 + financialFixedAssetsDepreciation2)
    }, [intangiblefixedassetsDepreciation2, tangiblefixedassetsDepreciation2, financialFixedAssetsDepreciation2])

    useEffect(() => {
        setTotalFixedAssetsNet2(totalFixedAssetsGross2 - totalFixedAssetsDepreciation2)
    }, [totalFixedAssetsGross2, totalFixedAssetsDepreciation2])

    useEffect(() => {
        setTotalFixedAssetsGross3(intangiblefixedassetsGross3 + tangiblefixedassetsGross3 + financialFixedAssetsGross3)
    }, [intangiblefixedassetsGross3, tangiblefixedassetsGross3, financialFixedAssetsGross3])

    useEffect(() => {
        setTotalFixedAssetsDepreciation3(intangiblefixedassetsDepreciation3 + tangiblefixedassetsDepreciation3 + financialFixedAssetsDepreciation3)
    }, [intangiblefixedassetsDepreciation3, tangiblefixedassetsDepreciation3, financialFixedAssetsDepreciation3])

    useEffect(() => {
        setTotalFixedAssetsNet3(totalFixedAssetsGross3 - totalFixedAssetsDepreciation3)
    }, [totalFixedAssetsGross3, totalFixedAssetsDepreciation3])

    useEffect(() => {
        setTotalCurrentAssetsGross1(totalCurrentAssetsGross_1 + suppliersAccountsPayableGross1)
    }, [totalCurrentAssetsGross_1, suppliersAccountsPayableGross1])
    useEffect(() => {
        setTotalCurrentAssetsGross2(totalCurrentAssetsGross_2 + suppliersAccountsPayableGross2)
    }, [totalCurrentAssetsGross_2, suppliersAccountsPayableGross2])
    useEffect(() => {
        setTotalCurrentAssetsGross3(totalCurrentAssetsGross_3 + suppliersAccountsPayableGross3)
    }, [totalCurrentAssetsGross_3, suppliersAccountsPayableGross3])

    useEffect(() => {
        setTotalCurrentAssetsDepreciation1(totalCurrentAssetsDepreciation_1 + suppliersAccountsPayableDepreciation1)
    }, [totalCurrentAssetsDepreciation_1, suppliersAccountsPayableDepreciation1])
    useEffect(() => {
        setTotalCurrentAssetsDepreciation2(totalCurrentAssetsDepreciation_2 + suppliersAccountsPayableDepreciation2)
    }, [totalCurrentAssetsDepreciation_2, suppliersAccountsPayableDepreciation2])
    useEffect(() => {
        setTotalCurrentAssetsDepreciation3(totalCurrentAssetsDepreciation_3 + suppliersAccountsPayableDepreciation3)
    }, [totalCurrentAssetsDepreciation_3, suppliersAccountsPayableDepreciation3])


    useEffect(() => {
        setTotalCurrentAssetsNet1(totalCurrentAssetsGross1 - totalCurrentAssetsDepreciation1)
    }, [totalCurrentAssetsGross1, totalCurrentAssetsDepreciation1])
    useEffect(() => {
        setTotalCurrentAssetsNet2(totalCurrentAssetsGross2 - totalCurrentAssetsDepreciation2)
    }, [totalCurrentAssetsGross2, totalCurrentAssetsDepreciation2])
    useEffect(() => {
        setTotalCurrentAssetsNet3(totalCurrentAssetsGross3 - totalCurrentAssetsDepreciation3)
    }, [totalCurrentAssetsGross3, totalCurrentAssetsDepreciation3])

    useEffect(() => {
        setCashandFinancialIInstrumentsNet1(cashandFinancialIInstrumentsGross1 - cashandFinancialIInstrumentsDepreciation1)
    }, [cashandFinancialIInstrumentsGross1, cashandFinancialIInstrumentsDepreciation1])

    useEffect(() => {
        setCashandFinancialIInstrumentsNet2(cashandFinancialIInstrumentsGross2 - cashandFinancialIInstrumentsDepreciation2)
    }, [cashandFinancialIInstrumentsGross2, cashandFinancialIInstrumentsDepreciation2])

    useEffect(() => {
        setCashandFinancialIInstrumentsNet3(cashandFinancialIInstrumentsGross3 - cashandFinancialIInstrumentsDepreciation3)
    }, [cashandFinancialIInstrumentsGross3, cashandFinancialIInstrumentsDepreciation3])


    useEffect(() => {
        setTotalAssetsGross1(totalFixedAssetsGross1 + totalCurrentAssetsGross1 + cashandFinancialIInstrumentsGross1 + valuationDifferenceAssetsGross1)
    }, [totalFixedAssetsGross1, totalCurrentAssetsGross1, cashandFinancialIInstrumentsGross1, valuationDifferenceAssetsGross1])

    useEffect(() => {
        setTotalAssetsGross2(totalFixedAssetsGross2 + totalCurrentAssetsGross2 + cashandFinancialIInstrumentsGross2 + valuationDifferenceAssetsGross2)
    }, [totalFixedAssetsGross2, totalCurrentAssetsGross2, cashandFinancialIInstrumentsGross2, valuationDifferenceAssetsGross2])
    useEffect(() => {
        setTotalAssetsGross3(totalFixedAssetsGross3 + totalCurrentAssetsGross3 + cashandFinancialIInstrumentsGross3 + valuationDifferenceAssetsGross3)
    }, [totalFixedAssetsGross3, totalCurrentAssetsGross3, cashandFinancialIInstrumentsGross3, valuationDifferenceAssetsGross3])

    useEffect(() => {
        setTotalAssetsDepreciation1(totalFixedAssetsDepreciation1 + totalCurrentAssetsDepreciation1 + cashandFinancialIInstrumentsDepreciation1 + valuationDifferenceAssetsDepreciation1)
    }, [totalFixedAssetsDepreciation1, totalCurrentAssetsDepreciation1, cashandFinancialIInstrumentsDepreciation1, valuationDifferenceAssetsDepreciation1])

    useEffect(() => {
        setTotalAssetsDepreciation2(totalFixedAssetsDepreciation2 + totalCurrentAssetsDepreciation2 + cashandFinancialIInstrumentsDepreciation2 + valuationDifferenceAssetsDepreciation2)
    }, [totalFixedAssetsDepreciation2, totalCurrentAssetsDepreciation2, cashandFinancialIInstrumentsDepreciation2, valuationDifferenceAssetsDepreciation2])
    useEffect(() => {
        setTotalAssetsDepreciation3(totalFixedAssetsDepreciation3 + totalCurrentAssetsDepreciation3 + cashandFinancialIInstrumentsDepreciation3 + valuationDifferenceAssetsDepreciation3)
    }, [totalFixedAssetsDepreciation3, totalCurrentAssetsDepreciation3, cashandFinancialIInstrumentsDepreciation3, valuationDifferenceAssetsDepreciation3])

    useEffect(() => {
        setTotalAssetsNet1(totalAssetsGross1 - totalAssetsDepreciation1)
    }, [totalAssetsGross1, totalAssetsDepreciation1])

    useEffect(() => {
        setTotalAssetsNet2(totalAssetsGross2 - totalAssetsDepreciation2)
    }, [totalAssetsGross2, totalAssetsDepreciation2])

    useEffect(() => {
        setTotalAssetsNet3(totalAssetsGross3 - totalAssetsDepreciation3)
    }, [totalAssetsGross3, totalAssetsDepreciation3])


    // console.log('formValue', formValue)
    const onChangeSetValue = (val) => {
        IntangiblefixedassetsGrossFun1();
        tangiblefixedassetsFun();
        financialFixedAssetsFun();
        suppliersAccountsPayableFun();
        totalCurrentAssetsFun();
        cashandFinancialIInstrumentsFun();
        totalAssetsFun();
    }

    const onFocusRemoveZero = (val) => {
        // console.log('onFocusRemoveZero', val)
        // if (val == 0)
        //     return "";
        // else
        //     return val
    }

    const IntangiblefixedassetsGrossFun1 = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.goodwillLeasholdRightsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross1?.value);

        let depreciation1 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation1?.value);

        // let Net1 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.goodwillLeasholdRightsNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsNet1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.goodwillLeasholdRightsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross2?.value);

        let depreciation2 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation2?.value);

        // let Net2 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.goodwillLeasholdRightsNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.goodwillLeasholdRightsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross3?.value);

        let depreciation3 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation3?.value);

        // let Net3 = Number(formRef.current?._inputs?.researchAndDevelopmentCostsNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.concessionsAndSimilarRightsNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.goodwillLeasholdRightsNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.otherIntangibleFixedAssetsNet3?.value);

        setIntangiblefixedassetsDepreciation1(depreciation1)
        setIntangiblefixedassetsNet1(grosstotal1 - depreciation1)
        setIntangiblefixedassetsGross1(grosstotal1)

        setIntangiblefixedassetsDepreciation2(depreciation2)
        setIntangiblefixedassetsNet2(grosstotal2 - depreciation2)
        setIntangiblefixedassetsGross2(grosstotal2)

        setIntangiblefixedassetsDepreciation3(depreciation3)
        setIntangiblefixedassetsNet3(grosstotal3 - depreciation3)
        setIntangiblefixedassetsGross3(grosstotal3)

    }

    const tangiblefixedassetsFun = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.landGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.constructionsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.technicalInstallationsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.officeEquipmentGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.transportEquipmentGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.advancePaymentsGross1?.value);


        let depreciation1 = 0;
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.landDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.constructionsDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.technicalInstallationsDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.officeEquipmentDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.transportEquipmentDepreciation1?.value);
        depreciation1 = depreciation1 + Number(formRef.current?._inputs?.advancePaymentsDepreciation1?.value);

        // let Net1 = Number(formRef.current?._inputs?.landNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.constructionsNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.technicalInstallationsNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.officeEquipmentNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.transportEquipmentNet1?.value);
        // Net1 = Net1 + Number(formRef.current?._inputs?.advancePaymentsNet1?.value);


        let grosstotal2 = Number(formRef.current?._inputs?.landGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.constructionsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.technicalInstallationsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.officeEquipmentGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.transportEquipmentGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.advancePaymentsGross2?.value);

        let depreciation2 = Number(formRef.current?._inputs?.landDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.constructionsDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.technicalInstallationsDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.officeEquipmentDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.transportEquipmentDepreciation2?.value);
        depreciation2 = depreciation2 + Number(formRef.current?._inputs?.advancePaymentsDepreciation2?.value);

        // let Net2 = Number(formRef.current?._inputs?.landNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.constructionsNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.technicalInstallationsNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.officeEquipmentNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.transportEquipmentNet2?.value);
        // Net2 = Net2 + Number(formRef.current?._inputs?.advancePaymentsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.landGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.constructionsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.technicalInstallationsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.officeEquipmentGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.transportEquipmentGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.advancePaymentsGross3?.value);


        let depreciation3 = Number(formRef.current?._inputs?.landDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.constructionsDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.technicalInstallationsDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.officeEquipmentDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.transportEquipmentDepreciation3?.value);
        depreciation3 = depreciation3 + Number(formRef.current?._inputs?.advancePaymentsDepreciation3?.value);


        // let Net3 = Number(formRef.current?._inputs?.landNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.constructionsNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.technicalInstallationsNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.officeEquipmentNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.transportEquipmentNet3?.value);
        // Net3 = Net3 + Number(formRef.current?._inputs?.advancePaymentsNet3?.value);


        setTangiblefixedassetsDepreciation1(depreciation1)
        setTangiblefixedassetsNet1(grosstotal1 - depreciation1)
        setTangiblefixedassetsGross1(grosstotal1)

        setTangiblefixedassetsDepreciation2(depreciation2)
        setTangiblefixedassetsNet2(grosstotal2 - depreciation2)
        setTangiblefixedassetsGross2(grosstotal2)

        setTangiblefixedassetsDepreciation3(depreciation3)
        setTangiblefixedassetsNet3(grosstotal3 - depreciation3)
        setTangiblefixedassetsGross3(grosstotal3)
    }

    // console.log(tangiblefixedassetsGross1)

    const financialFixedAssetsFun = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.equityInvestmentsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross1?.value);

        let depreciationtotal1 = Number(formRef.current?._inputs?.equityInvestmentsDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation1?.value);

        // let nettotal1 = Number(formRef.current?._inputs?.equityInvestmentsNet1?.value);
        // nettotal1 = nettotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.equityInvestmentsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross2?.value);

        let depreciationtotal2 = Number(formRef.current?._inputs?.equityInvestmentsDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation2?.value);

        // let nettotal2 = Number(formRef.current?._inputs?.equityInvestmentsNet2?.value);
        // nettotal2 = nettotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.equityInvestmentsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross3?.value);

        let depreciationtotal3 = Number(formRef.current?._inputs?.equityInvestmentsDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation3?.value);

        // let nettotal3 = Number(formRef.current?._inputs?.equityInvestmentsNet3?.value);
        // nettotal3 = nettotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet3?.value);


        setFinancialFixedAssetsDepreciation1(depreciationtotal1)
        setFinancialFixedAssetsNet1(grosstotal1 - depreciationtotal1)
        setFinancialFixedAssetsGross1(grosstotal1)

        setFinancialFixedAssetsDepreciation2(depreciationtotal2)
        setFinancialFixedAssetsNet2(grosstotal2 - depreciationtotal2)
        setFinancialFixedAssetsGross2(grosstotal2)

        setFinancialFixedAssetsDepreciation3(depreciationtotal3)
        setFinancialFixedAssetsNet3(grosstotal3 - depreciationtotal3)
        setFinancialFixedAssetsGross3(grosstotal3)
    }


    const suppliersAccountsPayableFun = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.tradeReceivableGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.otherReceivablesGross1?.value);

        let depreciationtotal1 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.tradeReceivableDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.otherReceivablesDepreciation1?.value);

        // let nettotal1 = Number(formRef.current?._inputs?.equityInvestmentsNet1?.value);
        // nettotal1 = nettotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.tradeReceivableGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.otherReceivablesGross2?.value);

        let depreciationtotal2 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.tradeReceivableDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.otherReceivablesDepreciation2?.value);

        // let nettotal2 = Number(formRef.current?._inputs?.equityInvestmentsNet2?.value);
        // nettotal2 = nettotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.tradeReceivableGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.otherReceivablesGross3?.value);

        let depreciationtotal3 = Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.tradeReceivableDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.otherReceivablesDepreciation3?.value);

        // let nettotal3 = Number(formRef.current?._inputs?.equityInvestmentsNet3?.value);
        // nettotal3 = nettotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet3?.value);


        setSuppliersAccountsPayableDepreciation1(depreciationtotal1)
        // setFinancialFixedAssetsNet1(nettotal1)
        setSuppliersAccountsPayableGross1(grosstotal1)

        setSuppliersAccountsPayableDepreciation2(depreciationtotal2)
        // setSuppliersAccountsPayableNet2(nettotal2)
        setSuppliersAccountsPayableGross2(grosstotal2)

        setSuppliersAccountsPayableDepreciation3(depreciationtotal3)
        // setSuppliersAccountsPayableNet3(nettotal3)
        setSuppliersAccountsPayableGross3(grosstotal3)
    }

    const totalCurrentAssetsFun = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.currentAssetsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross1?.value);


        let depreciationtotal1 = Number(formRef.current?._inputs?.currentAssetsDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation1?.value);

        // let nettotal1 = Number(formRef.current?._inputs?.equityInvestmentsNet1?.value);
        // nettotal1 = nettotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.currentAssetsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross2?.value);

        let depreciationtotal2 = Number(formRef.current?._inputs?.currentAssetsDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation2?.value);

        // let nettotal2 = Number(formRef.current?._inputs?.equityInvestmentsNet2?.value);
        // nettotal2 = nettotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.currentAssetsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross3?.value);

        let depreciationtotal3 = Number(formRef.current?._inputs?.currentAssetsDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation3?.value);

        // let nettotal3 = Number(formRef.current?._inputs?.equityInvestmentsNet3?.value);
        // nettotal3 = nettotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet3?.value);


        setTotalCurrentAssetsDepreciation_1(depreciationtotal1)

        setTotalCurrentAssetsGross_1(grosstotal1)

        setTotalCurrentAssetsDepreciation_2(depreciationtotal2)
        // setTotalCurrentAssetsNet2(totalCurrentAssetsGross2 - totalCurrentAssetsDepreciation2)
        setTotalCurrentAssetsGross_2(grosstotal2)

        setTotalCurrentAssetsDepreciation_3(depreciationtotal3)
        // setTotalCurrentAssetsNet3(totalCurrentAssetsGross3 - totalCurrentAssetsDepreciation3)
        setTotalCurrentAssetsGross_3(grosstotal3)
    }

    const cashandFinancialIInstrumentsFun = () => {

        let grosstotal1 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.cashAndCashEquivalentsGross1?.value);
        grosstotal1 = grosstotal1 + Number(formRef.current?._inputs?.banksFinancialGross1?.value);


        let depreciationtotal1 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation1?.value);
        depreciationtotal1 = depreciationtotal1 + Number(formRef.current?._inputs?.banksFinancialDepreciation1?.value);

        // let nettotal1 = Number(formRef.current?._inputs?.equityInvestmentsNet1?.value);
        // nettotal1 = nettotal1 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.cashAndCashEquivalentsGross2?.value);
        grosstotal2 = grosstotal2 + Number(formRef.current?._inputs?.banksFinancialGross2?.value);

        let depreciationtotal2 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation2?.value);
        depreciationtotal2 = depreciationtotal2 + Number(formRef.current?._inputs?.banksFinancialDepreciation2?.value);

        // let nettotal2 = Number(formRef.current?._inputs?.equityInvestmentsNet2?.value);
        // nettotal2 = nettotal2 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.cashAndCashEquivalentsGross3?.value);
        grosstotal3 = grosstotal3 + Number(formRef.current?._inputs?.banksFinancialGross3?.value);

        let depreciationtotal3 = Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation3?.value);
        depreciationtotal3 = depreciationtotal3 + Number(formRef.current?._inputs?.banksFinancialDepreciation3?.value);

        // let nettotal3 = Number(formRef.current?._inputs?.equityInvestmentsNet3?.value);
        // nettotal3 = nettotal3 + Number(formRef.current?._inputs?.otherFinancialFixedAssetsNet3?.value);


        setCashandFinancialIInstrumentsDepreciation1(depreciationtotal1)

        setCashandFinancialIInstrumentsGross1(grosstotal1)

        setCashandFinancialIInstrumentsDepreciation2(depreciationtotal2)
        // setCashandFinancialIInstrumentsNet2(totalCurrentAssetsGross2)
        setCashandFinancialIInstrumentsGross2(grosstotal2)

        setCashandFinancialIInstrumentsDepreciation3(depreciationtotal3)
        // setCashandFinancialIInstrumentsNet3(totalCurrentAssetsGross3 - totalCurrentAssetsDepreciation3)
        setCashandFinancialIInstrumentsGross3(grosstotal3)

    }

    const totalAssetsFun = () => {
        let grosstotal1 = Number(formRef.current?._inputs?.valuationDifferenceAssetsGross1?.value);

        let depreciationtotal1 = Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation1?.value);

        let grosstotal2 = Number(formRef.current?._inputs?.valuationDifferenceAssetsGross2?.value);

        let depreciationtotal2 = Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation2?.value);

        let grosstotal3 = Number(formRef.current?._inputs?.valuationDifferenceAssetsGross3?.value);

        let depreciationtotal3 = Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation3?.value);

        setValuationDifferenceAssetsGross1(grosstotal1)
        setValuationDifferenceAssetsDepreciation1(depreciationtotal1)
        setValuationDifferenceAssetsGross2(grosstotal2)
        setValuationDifferenceAssetsDepreciation2(depreciationtotal2)
        setValuationDifferenceAssetsGross3(grosstotal3)
        setValuationDifferenceAssetsDepreciation3(depreciationtotal3)


    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Balance Sheet", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Balance Sheet', breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        try {
            if (!loading) {
                setLoading(true)
                if (values?.year_one) {
                    let data = [];
                    if (values?.year_one) {
                        data.push({
                            ...renameKeys(values, 1),
                            userId: user?._id,
                            contentId: year1?._id,
                            token: user.token,
                            year: values?.year_one,
                            isSubmit: type == 'submit' ? true : undefined,
                            intangiblefixedassetsGross: intangiblefixedassetsGross1 ? Number(intangiblefixedassetsGross1) : 0,
                            intangiblefixedassetsDepreciation: intangiblefixedassetsDepreciation1 ? Number(intangiblefixedassetsDepreciation1) : 0,
                            intangiblefixedassetsNet: intangiblefixedassetsNet1 ? Number(intangiblefixedassetsNet1) : 0,
                            tangiblefixedassetsGross: tangiblefixedassetsGross1 ? Number(tangiblefixedassetsGross1) : 0,
                            tangiblefixedassetsDepreciation: tangiblefixedassetsDepreciation1 ? Number(tangiblefixedassetsDepreciation1) : 0,
                            tangiblefixedassetsNet: tangiblefixedassetsNet1 ? Number(tangiblefixedassetsNet1) : 0,
                            financialFixedAssetsGross: financialFixedAssetsGross1 ? Number(financialFixedAssetsGross1) : 0,
                            financialFixedAssetsDepreciation: financialFixedAssetsGross1 ? Number(financialFixedAssetsDepreciation1) : 0,
                            financialFixedAssetsNet: financialFixedAssetsNet1 ? Number(financialFixedAssetsNet1) : 0,
                            totalFixedAssetsGross: totalFixedAssetsGross1 ? Number(totalFixedAssetsGross1) : 0,
                            totalFixedAssetsDepreciation: totalFixedAssetsDepreciation1 ? Number(totalFixedAssetsDepreciation1) : 0,
                            totalFixedAssetsNet: totalFixedAssetsNet1 ? Number(totalFixedAssetsNet1) : 0,
                            suppliersAccountsPayableGross: suppliersAccountsPayableGross1 ? Number(suppliersAccountsPayableGross1) : 0,
                            suppliersAccountsPayableDepreciation: suppliersAccountsPayableDepreciation1 ? Number(suppliersAccountsPayableDepreciation1) : 0,
                            suppliersAccountsPayableNet: suppliersAccountsPayableNet1 ? Number(suppliersAccountsPayableNet1) : 0,
                            totalCurrentAssetsGross: totalCurrentAssetsGross1 ? Number(totalCurrentAssetsGross1) : 0,
                            totalCurrentAssetsDepreciation: totalCurrentAssetsDepreciation1 ? Number(totalCurrentAssetsDepreciation1) : 0,
                            totalCurrentAssetsNet: totalCurrentAssetsNet1 ? Number(totalCurrentAssetsNet1) : 0,
                            cashandFinancialIInstrumentsGross: cashandFinancialIInstrumentsGross1 ? Number(cashandFinancialIInstrumentsGross1) : 0,
                            cashandFinancialIInstrumentsDepreciation: cashandFinancialIInstrumentsDepreciation1 ? Number(cashandFinancialIInstrumentsDepreciation1) : 0,
                            cashandFinancialIInstrumentsNet: cashandFinancialIInstrumentsNet1 ? Number(cashandFinancialIInstrumentsNet1) : 0,
                            totalAssetsGross: totalAssetsGross1 ? Number(totalAssetsGross1) : 0,
                            totalAssetsDepreciation: totalAssetsDepreciation1 ? Number(totalAssetsDepreciation1) : 0,
                            totalAssetsNet: totalAssetsNet1 ? Number(totalAssetsNet1) : 0,
                        });
                    }
                    // if (values?.year_two) {
                    //     data.push({
                    //         ...renameKeys(values, 2),
                    //         userId: user?._id,
                    //         contentId: year2?._id,
                    //         token: user.token,
                    //         year: values?.year_two,
                    //         isSubmit: type == 'submit' ? true : undefined,
                    //         intangiblefixedassetsGross: Number(intangiblefixedassetsGross2),
                    //         intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation2),
                    //         intangiblefixedassetsNet: Number(intangiblefixedassetsNet2),
                    //         tangiblefixedassetsGross: Number(tangiblefixedassetsGross2),
                    //         tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation2),
                    //         tangiblefixedassetsNet: Number(tangiblefixedassetsNet2),
                    //         financialFixedAssetsGross: Number(financialFixedAssetsGross2),
                    //         financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation2),
                    //         financialFixedAssetsNet: Number(financialFixedAssetsNet2),
                    //         totalFixedAssetsGross: Number(totalFixedAssetsGross2),
                    //         totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation2),
                    //         totalFixedAssetsNet: Number(totalFixedAssetsNet2),
                    //         suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross2),
                    //         suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation2),
                    //         suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet2),
                    //         totalCurrentAssetsGross: Number(totalCurrentAssetsGross2),
                    //         totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation2),
                    //         totalCurrentAssetsNet: Number(totalCurrentAssetsNet2),
                    //         cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross2),
                    //         cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation2),
                    //         cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet2),
                    //         totalAssetsGross: Number(totalAssetsGross2),
                    //         totalAssetsDepreciation: Number(totalAssetsDepreciation2),
                    //         totalAssetsNet: Number(totalAssetsNet2),
                    //     });
                    // }
                    // if (values?.year_three) {
                    //     data.push({
                    //         ...renameKeys(values, 3),
                    //         userId: user?._id,
                    //         contentId: year3?._id,
                    //         token: user.token,
                    //         year: values?.year_three,
                    //         isSubmit: type == 'submit' ? true : undefined,
                    //         intangiblefixedassetsGross: Number(intangiblefixedassetsGross3),
                    //         intangiblefixedassetsDepreciation: Number(intangiblefixedassetsDepreciation3),
                    //         intangiblefixedassetsNet: Number(intangiblefixedassetsNet3),
                    //         tangiblefixedassetsGross: Number(tangiblefixedassetsGross3),
                    //         tangiblefixedassetsDepreciation: Number(tangiblefixedassetsDepreciation3),
                    //         tangiblefixedassetsNet: Number(tangiblefixedassetsNet3),
                    //         financialFixedAssetsGross: Number(financialFixedAssetsGross3),
                    //         financialFixedAssetsDepreciation: Number(financialFixedAssetsDepreciation3),
                    //         financialFixedAssetsNet: Number(financialFixedAssetsNet3),
                    //         totalFixedAssetsGross: Number(totalFixedAssetsGross3),
                    //         totalFixedAssetsDepreciation: Number(totalFixedAssetsDepreciation3),
                    //         totalFixedAssetsNet: Number(totalFixedAssetsNet3),
                    //         suppliersAccountsPayableGross: Number(suppliersAccountsPayableGross3),
                    //         suppliersAccountsPayableDepreciation: Number(suppliersAccountsPayableDepreciation3),
                    //         suppliersAccountsPayableNet: Number(suppliersAccountsPayableNet3),
                    //         totalCurrentAssetsGross: Number(totalCurrentAssetsGross3),
                    //         totalCurrentAssetsDepreciation: Number(totalCurrentAssetsDepreciation3),
                    //         totalCurrentAssetsNet: Number(totalCurrentAssetsNet3),
                    //         cashandFinancialIInstrumentsGross: Number(cashandFinancialIInstrumentsGross3),
                    //         cashandFinancialIInstrumentsDepreciation: Number(cashandFinancialIInstrumentsDepreciation3),
                    //         cashandFinancialIInstrumentsNet: Number(cashandFinancialIInstrumentsNet3),
                    //         totalAssetsGross: Number(totalAssetsGross3),
                    //         totalAssetsDepreciation: Number(totalAssetsDepreciation3),
                    //         totalAssetsNet: Number(totalAssetsNet3),
                    //     });
                    // }
                    data?.forEach((body, index) => {
                        post('/balance_sheet/add', body)
                            .then((res) => {
                                if (data?.length - 1 === index) {
                                    // setLoading(false)
                                    if (res?.statusCode == 200) {
                                        // console.log('request on login', res);
                                        toast.success(res?.message);
                                        let body = {
                                            userId: user?._id,
                                            token: user?.token
                                        }
                                        post('/user/profile/status', body)
                                            .then((json => {
                                                if (json?.statusCode == 200) {
                                                    if (json?.data?.cashFlow) {
                                                        if (json?.data?.profit) {
                                                            if (json?.data?.balance) {
                                                                updateProfile();
                                                            } else
                                                                setLoading(false);
                                                        } else
                                                            setLoading(false);
                                                    } else
                                                        setLoading(false);
                                                } else {
                                                    setLoading(false);
                                                }
                                            }));
                                    } else {
                                        setLoading(false);
                                        console.log(res?.error);
                                        toast.error(res?.error)
                                    }
                                }
                            })
                            .catch((err) => {
                                setLoading(false)
                                console.log(err)
                            })
                    })
                }
                else {
                    setLoading(false)
                    toast?.error('First Year is Required')
                }
            }
        } catch (err) {
            console.error("Error while submitting balance sheet", err);
        }
    }

    const updateProfile = () => {
        let body = {
            userId: user?._id,
            token: user?.token,
            step: 6
        }
        put('/user', body)
            .then((res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    props?.profileSuccess({ ...user, ...body });
                    localStorage.setItem("user", JSON.stringify({ ...user, ...body }));
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
            })
    }

    const uploadExcel = () => {
        if (file) {
            const form = new FormData();
            form.append("sample", file[0]);

            upload('/profit_loss/sample_upload', form, user?.token)
                .then((res) => {
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        toast.success(res?.message)
                        setExcelUploadModal(false)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toast.error('Please choose file')
        }
    }

    function renameKeys(obj, to) {
        const newKeys = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const regexPattern = new RegExp(to + "$");
                const newKey = key.replace(regexPattern, ''); // Remove trailing digits
                newKeys[newKey] = obj[key];
            }
        }
        return newKeys;
    }

    const columns = [
        {
            title: 'Asset',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
        },
        {
            title: 'Other',
            render: (item) => <p>action</p>,
            children: [
                {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age',
                    width: 150,
                    sorter: (a, b) => a.age - b.age,
                },
                {
                    title: 'Address',
                    children: [
                        {
                            title: 'Street',
                            dataIndex: 'street',
                            key: 'street',
                            width: 150,
                        },
                        {
                            title: 'Block',
                            children: [
                                {
                                    title: 'Building',
                                    dataIndex: 'building',
                                    key: 'building',
                                    width: 100,
                                },
                                {
                                    title: 'Door No.',
                                    dataIndex: 'number',
                                    key: 'number',
                                    width: 100,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Company',
            children: [
                {
                    title: 'Company Address',
                    dataIndex: 'companyAddress',
                    key: 'companyAddress',
                    width: 200,
                },
                {
                    title: 'Company Name',
                    dataIndex: 'companyName',
                    key: 'companyName',
                },
            ],
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            fixed: 'right',
        },
    ];

    const data = [
        {
            key: '1',
            asset: <strong>{t("Intangible fixed assets")}</strong>,
            gross1: <strong>{intangiblefixedassetsGross1 ? intangiblefixedassetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{intangiblefixedassetsGross2 ? intangiblefixedassetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{intangiblefixedassetsGross3 ? intangiblefixedassetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{intangiblefixedassetsDepreciation1 ? intangiblefixedassetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{intangiblefixedassetsDepreciation2 ? intangiblefixedassetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{intangiblefixedassetsDepreciation3 ? intangiblefixedassetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{intangiblefixedassetsNet1 ? intangiblefixedassetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{intangiblefixedassetsNet2 ? intangiblefixedassetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{intangiblefixedassetsNet3 ? intangiblefixedassetsNet3?.toLocaleString() : 0}</strong>,

        },
        {
            key: '2',
            asset: <span>{t("Research and development costs")}</span>,
            gross1: <AvField
                name="researchAndDevelopmentCostsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.researchAndDevelopmentCostsGross}

            // required
            />,
            // gross2: <AvField
            //     name="researchAndDevelopmentCostsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.researchAndDevelopmentCostsGross}
            // // required
            // />,
            // gross3: <AvField
            //     name="researchAndDevelopmentCostsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.researchAndDevelopmentCostsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="researchAndDevelopmentCostsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.researchAndDevelopmentCostsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="researchAndDevelopmentCostsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.researchAndDevelopmentCostsDepreciation}


            // // required
            // />,
            // depreciation3: <AvField
            //     name="researchAndDevelopmentCostsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.researchAndDevelopmentCostsDepreciation}


            // // required
            // />,
            net1: <AvField
                name="researchAndDevelopmentCostsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross1?.value) - Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="researchAndDevelopmentCostsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross2?.value) - Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="researchAndDevelopmentCostsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.researchAndDevelopmentCostsGross3?.value) - Number(formRef.current?._inputs?.researchAndDevelopmentCostsDepreciation3?.value)}

            // // required
            // />,
        },
        {
            key: '3',
            asset: <span>{t("Concessions and similar rights, patents, licenses, trademarks, processes, software, rights and similar assets")}</span>,
            gross1: <AvField
                name="concessionsAndSimilarRightsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.concessionsAndSimilarRightsGross}

            // required
            />,
            // gross2: <AvField
            //     name="concessionsAndSimilarRightsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.concessionsAndSimilarRightsGross}


            // // required
            // />,
            // gross3: <AvField
            //     name="concessionsAndSimilarRightsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.concessionsAndSimilarRightsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="concessionsAndSimilarRightsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.concessionsAndSimilarRightsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="concessionsAndSimilarRightsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.concessionsAndSimilarRightsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="concessionsAndSimilarRightsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.concessionsAndSimilarRightsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="concessionsAndSimilarRightsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross1?.value) - Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="concessionsAndSimilarRightsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross2?.value) - Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="concessionsAndSimilarRightsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.concessionsAndSimilarRightsGross3?.value) - Number(formRef.current?._inputs?.concessionsAndSimilarRightsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '4',
            asset: <span>{t("Goodwill & Leashold Rights")}</span>,
            gross1: <AvField
                name="goodwillLeasholdRightsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.goodwillLeasholdRightsGross}

            // required
            />,
            // gross2: <AvField
            //     name="goodwillLeasholdRightsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.goodwillLeasholdRightsGross}


            // // required
            // />,
            // gross3: <AvField
            //     name="goodwillLeasholdRightsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.goodwillLeasholdRightsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="goodwillLeasholdRightsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.goodwillLeasholdRightsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="goodwillLeasholdRightsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.goodwillLeasholdRightsDepreciation}
            // // required
            // />,
            // depreciation3: <AvField
            //     name="goodwillLeasholdRightsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.goodwillLeasholdRightsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="goodwillLeasholdRightsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.goodwillLeasholdRightsGross1?.value) - Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="goodwillLeasholdRightsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.goodwillLeasholdRightsGross2?.value) - Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="goodwillLeasholdRightsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.goodwillLeasholdRightsGross3?.value) - Number(formRef.current?._inputs?.goodwillLeasholdRightsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '5',
            asset: <span> {t("Other intangible fixed assets")}</span>,
            gross1: <AvField
                name="otherIntangibleFixedAssetsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherIntangibleFixedAssetsGross}

            // required
            />,
            // gross2: <AvField
            //     name="otherIntangibleFixedAssetsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.otherIntangibleFixedAssetsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="otherIntangibleFixedAssetsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.otherIntangibleFixedAssetsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="otherIntangibleFixedAssetsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherIntangibleFixedAssetsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="otherIntangibleFixedAssetsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.otherIntangibleFixedAssetsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="otherIntangibleFixedAssetsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.otherIntangibleFixedAssetsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="otherIntangibleFixedAssetsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross1?.value) - Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="otherIntangibleFixedAssetsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross2?.value) - Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="otherIntangibleFixedAssetsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.otherIntangibleFixedAssetsGross3?.value) - Number(formRef.current?._inputs?.otherIntangibleFixedAssetsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '6',
            asset: <strong>{t("Tangible fixed assets")}</strong>,
            gross1: <strong>{tangiblefixedassetsGross1 ? tangiblefixedassetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{tangiblefixedassetsGross2 ? tangiblefixedassetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{tangiblefixedassetsGross3 ? tangiblefixedassetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{tangiblefixedassetsDepreciation1 ? tangiblefixedassetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{tangiblefixedassetsDepreciation2 ? tangiblefixedassetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{tangiblefixedassetsDepreciation3 ? tangiblefixedassetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{tangiblefixedassetsNet1 ? tangiblefixedassetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{tangiblefixedassetsNet2 ? tangiblefixedassetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{tangiblefixedassetsNet3 ? tangiblefixedassetsNet3?.toLocaleString() : 0}</strong>,
        },
        {
            key: '7',
            asset: <span>{t("Land")}</span>,
            gross1: <AvField
                name="landGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.landGross}

            // required
            />,
            // gross2: <AvField
            //     name="landGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.landGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="landGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.landGross}

            // // required
            // />,
            depreciation1: <AvField
                name="landDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.landDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="landDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.landDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="landDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.landDepreciation}

            // // required
            // />,
            net1: <AvField
                name="landNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.landGross1?.value) - Number(formRef.current?._inputs?.landDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="landNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.landGross2?.value) - Number(formRef.current?._inputs?.landDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="landNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.landGross3?.value) - Number(formRef.current?._inputs?.landDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '8',
            asset: <span>{t("Constructions")}</span>,
            gross1: <AvField
                name="constructionsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.constructionsGross}

            // required
            />,
            // gross2: <AvField
            //     name="constructionsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.constructionsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="constructionsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.constructionsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="constructionsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}

                value={year1?.constructionsDepreciation}
            // required
            />,
            // depreciation2: <AvField
            //     name="constructionsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.constructionsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="constructionsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.constructionsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="constructionsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.constructionsGross1?.value) - Number(formRef.current?._inputs?.constructionsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="constructionsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.constructionsGross2?.value) - Number(formRef.current?._inputs?.constructionsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="constructionsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.constructionsGross3?.value) - Number(formRef.current?._inputs?.constructionsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '9',
            asset: <span>{t("Technical installations, industrial machinery, equipment and tools")}</span>,
            gross1: <AvField
                name="technicalInstallationsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.technicalInstallationsGross}

            // required
            />,
            // gross2: <AvField
            //     name="technicalInstallationsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.technicalInstallationsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="technicalInstallationsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.technicalInstallationsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="technicalInstallationsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.technicalInstallationsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="technicalInstallationsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.technicalInstallationsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="technicalInstallationsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year3?.technicalInstallationsDepreciation}
            // // required
            // />,
            net1: <AvField
                name="technicalInstallationsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.technicalInstallationsGross1?.value) - Number(formRef.current?._inputs?.technicalInstallationsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="technicalInstallationsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.technicalInstallationsGross2?.value) - Number(formRef.current?._inputs?.technicalInstallationsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="technicalInstallationsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.technicalInstallationsGross3?.value) - Number(formRef.current?._inputs?.technicalInstallationsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '10',
            asset: <span>{t("Office equipment and computer hardware, Furniture, Livestock, Cheptel, Returnable packaging")}</span>,
            gross1: <AvField
                name="officeEquipmentGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}

                value={year1?.officeEquipmentGross}
            // required
            />,
            // gross2: <AvField
            //     name="officeEquipmentGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.officeEquipmentGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="officeEquipmentGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.officeEquipmentGross}

            // // required
            // />,
            depreciation1: <AvField
                name="officeEquipmentDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.officeEquipmentDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="officeEquipmentDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.officeEquipmentDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="officeEquipmentDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.officeEquipmentDepreciation}

            // // required
            // />,
            net1: <AvField
                name="officeEquipmentNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.officeEquipmentGross1?.value) - Number(formRef.current?._inputs?.officeEquipmentDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="officeEquipmentNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.officeEquipmentGross2?.value) - Number(formRef.current?._inputs?.officeEquipmentDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="officeEquipmentNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.officeEquipmentGross3?.value) - Number(formRef.current?._inputs?.officeEquipmentDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '11',
            asset: <span>{t("Transport equipment")}</span>,
            gross1: <AvField
                name="transportEquipmentGross1"
                className="form-control"
                // placeholder="Sales of goods for resale"
                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.transportEquipmentGross}

            // required
            />,
            // gross2: <AvField
            //     name="transportEquipmentGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.transportEquipmentGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="transportEquipmentGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.transportEquipmentGross}

            // // required
            // />,
            depreciation1: <AvField
                name="transportEquipmentDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.transportEquipmentDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="transportEquipmentDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.transportEquipmentDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="transportEquipmentDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.transportEquipmentDepreciation}

            // // required
            // />,
            net1: <AvField
                name="transportEquipmentNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.transportEquipmentGross1?.value) - Number(formRef.current?._inputs?.transportEquipmentDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="transportEquipmentNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.transportEquipmentGross2?.value) - Number(formRef.current?._inputs?.transportEquipmentDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="transportEquipmentNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     // onChange={(e) => onChangeSetValue(e.target.value)}
            //     // onWheel={(event) => event.currentTarget.blur()}
            //     disabled
            //     value={Number(formRef.current?._inputs?.transportEquipmentGross3?.value) - Number(formRef.current?._inputs?.transportEquipmentDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '12',
            asset: <span>{t("Advance payments on account on orders for intangible fixed assets")}</span>,
            gross1: <AvField
                name="advancePaymentsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.advancePaymentsGross}

            // required
            />,
            // gross2: <AvField
            //     name="advancePaymentsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.advancePaymentsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="advancePaymentsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.advancePaymentsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="advancePaymentsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.advancePaymentsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="advancePaymentsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.advancePaymentsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="advancePaymentsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.advancePaymentsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="advancePaymentsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.advancePaymentsGross1?.value) - Number(formRef.current?._inputs?.advancePaymentsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="advancePaymentsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.advancePaymentsGross2?.value) - Number(formRef.current?._inputs?.advancePaymentsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="advancePaymentsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.advancePaymentsGross3?.value) - Number(formRef.current?._inputs?.advancePaymentsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '13',
            asset: <strong>{t("Financial fixed assets")}</strong>,
            gross1: <strong>{financialFixedAssetsGross1 ? financialFixedAssetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{financialFixedAssetsGross2 ? financialFixedAssetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{financialFixedAssetsGross3 ? financialFixedAssetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{financialFixedAssetsDepreciation1 ? financialFixedAssetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{financialFixedAssetsDepreciation2 ? financialFixedAssetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{financialFixedAssetsDepreciation3 ? financialFixedAssetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{financialFixedAssetsNet1 ? financialFixedAssetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{financialFixedAssetsNet2 ? financialFixedAssetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{financialFixedAssetsNet3 ? financialFixedAssetsNet3?.toLocaleString() : 0}</strong>,

        },
        {
            key: '14',
            asset: <span>{t("Equity investments")}</span>,
            gross1: <AvField
                name="equityInvestmentsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.equityInvestmentsGross}

            // required
            />,
            // gross2: <AvField
            //     name="equityInvestmentsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.equityInvestmentsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="equityInvestmentsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.equityInvestmentsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="equityInvestmentsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.equityInvestmentsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="equityInvestmentsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.equityInvestmentsDepreciation}
            // // required
            // />,
            // depreciation3: <AvField
            //     name="equityInvestmentsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.equityInvestmentsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="equityInvestmentsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.equityInvestmentsGross1?.value) - Number(formRef.current?._inputs?.equityInvestmentsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="equityInvestmentsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.equityInvestmentsGross2?.value) - Number(formRef.current?._inputs?.equityInvestmentsDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="equityInvestmentsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.equityInvestmentsGross3?.value) - Number(formRef.current?._inputs?.equityInvestmentsDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '15',
            asset: <span>{t("Other financial fixed assets (portfolio, long-terms securities)")}</span>,
            gross1: <AvField
                name="otherFinancialFixedAssetsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherFinancialFixedAssetsGross}

            // required
            />,
            // gross2: <AvField
            //     name="otherFinancialFixedAssetsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.otherFinancialFixedAssetsGross}
            // // required
            // />,
            // gross3: <AvField
            //     name="otherFinancialFixedAssetsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.otherFinancialFixedAssetsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="otherFinancialFixedAssetsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherFinancialFixedAssetsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="otherFinancialFixedAssetsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.otherFinancialFixedAssetsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="otherFinancialFixedAssetsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.otherFinancialFixedAssetsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="otherFinancialFixedAssetsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                // onChange={(e) => onChangeSetValue(e.target.value)}
                // onWheel={(event) => event.currentTarget.blur()}
                disabled
                value={Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross1?.value) - Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="otherFinancialFixedAssetsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross2?.value) - Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="otherFinancialFixedAssetsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={Number(formRef.current?._inputs?.otherFinancialFixedAssetsGross3?.value) - Number(formRef.current?._inputs?.otherFinancialFixedAssetsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '16',
            asset: <strong>{t("Total fixed assets")}</strong>,
            gross1: <strong>{totalFixedAssetsGross1 ? totalFixedAssetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{totalFixedAssetsGross2 ? totalFixedAssetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{totalFixedAssetsGross3 ? totalFixedAssetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{totalFixedAssetsDepreciation1 ? totalFixedAssetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{totalFixedAssetsDepreciation2 ? totalFixedAssetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{totalFixedAssetsDepreciation3 ? totalFixedAssetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{totalFixedAssetsNet1 ? totalFixedAssetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{totalFixedAssetsNet2 ? totalFixedAssetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{totalFixedAssetsNet3 ? totalFixedAssetsNet3?.toLocaleString() : 0}</strong>,

        },
        {
            key: '17',
            asset: <span>{t("Current assets")}</span>,
            gross1: <AvField
                name="currentAssetsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.currentAssetsGross}

            // required
            />,
            // gross2: <AvField
            //     name="currentAssetsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.currentAssetsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="currentAssetsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.currentAssetsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="currentAssetsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.currentAssetsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="currentAssetsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.currentAssetsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="currentAssetsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.currentAssetsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="currentAssetsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.currentAssetsGross1?.value) - Number(formRef.current?._inputs?.currentAssetsDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="currentAssetsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.currentAssetsGross2?.value) - Number(formRef.current?._inputs?.currentAssetsDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="currentAssetsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.currentAssetsGross3?.value) - Number(formRef.current?._inputs?.currentAssetsDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '18',
            asset: <span>{t("Inventory and work in progress")}</span>,
            gross1: <AvField
                name="inventoryAndWorkInProgressGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.inventoryAndWorkInProgressGross}

            // required
            />,
            // gross2: <AvField
            //     name="inventoryAndWorkInProgressGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.inventoryAndWorkInProgressGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="inventoryAndWorkInProgressGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.inventoryAndWorkInProgressGross}

            // // required
            // />,
            depreciation1: <AvField
                name="inventoryAndWorkInProgressDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.inventoryAndWorkInProgressDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="inventoryAndWorkInProgressDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.inventoryAndWorkInProgressDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="inventoryAndWorkInProgressDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.inventoryAndWorkInProgressDepreciation}

            // // required
            // />,
            net1: <AvField
                name="inventoryAndWorkInProgressNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross1?.value) - Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="inventoryAndWorkInProgressNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross2?.value) - Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="inventoryAndWorkInProgressNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.inventoryAndWorkInProgressGross3?.value) - Number(formRef.current?._inputs?.inventoryAndWorkInProgressDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '19',
            asset: <strong>{t("Suppliers accounts payable and related accounts")}</strong>,
            gross1: <AvField
                name="suppliersAccountsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                value={suppliersAccountsPayableGross1}
                disabled

            // required
            />,
            // gross2: <AvField
            //     name="suppliersAccountsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={suppliersAccountsPayableGross2}
            //     disabled

            // // required
            // />,
            // gross3: <AvField
            //     name="suppliersAccountsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={suppliersAccountsPayableGross3}
            //     disabled

            // // required
            // />,
            depreciation1: <AvField
                name="suppliersAccountsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                value={suppliersAccountsPayableDepreciation1}
                disabled

            // required
            />,
            // depreciation2: <AvField
            //     name="suppliersAccountsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={suppliersAccountsPayableDepreciation2}
            //     disabled

            // // required
            // />,
            // depreciation3: <AvField
            //     name="suppliersAccountsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={suppliersAccountsPayableDepreciation3}
            //     disabled

            // // required
            // />,
            net1: <AvField
                name="suppliersAccountsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                value={Number(suppliersAccountsPayableGross1) - Number(suppliersAccountsPayableDepreciation1)}
                disabled

            // required
            />,
            // net2: <AvField
            //     name="suppliersAccountsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={Number(suppliersAccountsPayableGross2) - Number(suppliersAccountsPayableDepreciation2)}
            //     disabled

            // // required
            // />,
            // net3: <AvField
            //     name="suppliersAccountsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={Number(suppliersAccountsPayableGross3) - Number(suppliersAccountsPayableDepreciation3)}
            //     disabled

            // // required
            // />,

        },
        {
            key: '20',
            asset: <span>{t("Suppliers Advance payments on account on orders")}</span>,
            gross1: <AvField
                name="suppliersAdvancePaymentsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}

                value={year1?.suppliersAdvancePaymentsGross}
            // required
            />,
            // gross2: <AvField
            //     name="suppliersAdvancePaymentsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.suppliersAdvancePaymentsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="suppliersAdvancePaymentsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.suppliersAdvancePaymentsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="suppliersAdvancePaymentsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.suppliersAdvancePaymentsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="suppliersAdvancePaymentsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.suppliersAdvancePaymentsDepreciation}
            // // required
            // />,
            // depreciation3: <AvField
            //     name="suppliersAdvancePaymentsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.suppliersAdvancePaymentsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="suppliersAdvancePaymentsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross1?.value) - Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation1?.value)}


            // required
            />,
            // net2: <AvField
            //     name="suppliersAdvancePaymentsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross2?.value) - Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="suppliersAdvancePaymentsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.suppliersAdvancePaymentsGross3?.value) - Number(formRef.current?._inputs?.suppliersAdvancePaymentsDepreciation3?.value)}

            // // required
            // />,

        },
        {
            key: '21',
            asset: <span>{t("Trade Receivable")}</span>,
            gross1: <AvField
                name="tradeReceivableGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.tradeReceivableGross}

            // required
            />,
            // gross2: <AvField
            //     name="tradeReceivableGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.tradeReceivableGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="tradeReceivableGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.tradeReceivableGross}

            // // required
            // />,
            depreciation1: <AvField
                name="tradeReceivableDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.tradeReceivableDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="tradeReceivableDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.tradeReceivableDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="tradeReceivableDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.tradeReceivableDepreciation}

            // // required
            // />,
            net1: <AvField
                name="tradeReceivableNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.tradeReceivableGross1?.value) - Number(formRef.current?._inputs?.tradeReceivableDepreciation1?.value)}

            // required
            />,
            // net2: <AvField
            //     name="tradeReceivableNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.tradeReceivableGross2?.value) - Number(formRef.current?._inputs?.tradeReceivableDepreciation2?.value)}

            // // required
            // />,
            // net3: <AvField
            //     name="tradeReceivableNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.tradeReceivableGross3?.value) - Number(formRef.current?._inputs?.tradeReceivableDepreciation3?.value)}
            // // required
            // />,
        },
        {
            key: '22',
            asset: <span>{t("Other Receivables")}</span>,
            gross1: <AvField
                name="otherReceivablesGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherReceivablesGross}

            // required
            />,
            // gross2: <AvField
            //     name="otherReceivablesGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.otherReceivablesGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="otherReceivablesGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.otherReceivablesGross}

            // // required
            // />,
            depreciation1: <AvField
                name="otherReceivablesDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.otherReceivablesDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="otherReceivablesDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.otherReceivablesDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="otherReceivablesDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year3?.otherReceivablesDepreciation}
            // // required
            // />,
            net1: <AvField
                name="otherReceivablesNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.otherReceivablesGross1?.value) - Number(formRef.current?._inputs?.otherReceivablesDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="otherReceivablesNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.otherReceivablesGross2?.value) - Number(formRef.current?._inputs?.otherReceivablesDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="otherReceivablesNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     value={Number(formRef.current?._inputs?.otherReceivablesGross3?.value) - Number(formRef.current?._inputs?.otherReceivablesDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '23',
            asset: <strong>{t("Total current assets")}</strong>,
            gross1: <strong>{totalCurrentAssetsGross1 ? totalCurrentAssetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{totalCurrentAssetsGross2 ? totalCurrentAssetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{totalCurrentAssetsGross3 ? totalCurrentAssetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{totalCurrentAssetsDepreciation1 ? totalCurrentAssetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{totalCurrentAssetsDepreciation2 ? totalCurrentAssetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{totalCurrentAssetsDepreciation3 ? totalCurrentAssetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{totalCurrentAssetsNet1 ? totalCurrentAssetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{totalCurrentAssetsNet2 ? totalCurrentAssetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{totalCurrentAssetsNet3 ? totalCurrentAssetsNet3?.toLocaleString() : 0}</strong>,

        },
        {
            key: '24',
            asset: <span>{t("Shares and Marketable Securities")}</span>,
            gross1: <AvField
                name="sharesAndMarketableSecuritiesGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.sharesAndMarketableSecuritiesGross}

            // required
            />,
            // gross2: <AvField
            //     name="sharesAndMarketableSecuritiesGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.sharesAndMarketableSecuritiesGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="sharesAndMarketableSecuritiesGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.sharesAndMarketableSecuritiesGross}

            // // required
            // />,
            depreciation1: <AvField
                name="sharesAndMarketableSecuritiesDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.sharesAndMarketableSecuritiesDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="sharesAndMarketableSecuritiesDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year2?.sharesAndMarketableSecuritiesDepreciation}
            // // required
            // />,
            // depreciation3: <AvField
            //     name="sharesAndMarketableSecuritiesDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.sharesAndMarketableSecuritiesDepreciation}

            // // required
            // />,
            net1: <AvField
                name="sharesAndMarketableSecuritiesNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross1?.value) - Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="sharesAndMarketableSecuritiesNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross2?.value) - Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="sharesAndMarketableSecuritiesNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesGross3?.value) - Number(formRef.current?._inputs?.sharesAndMarketableSecuritiesDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '25',
            asset: <span>{t("Cash and cash equivalents")}</span>,
            gross1: <AvField
                name="cashAndCashEquivalentsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.cashAndCashEquivalentsGross}

            // required
            />,
            // gross2: <AvField
            //     name="cashAndCashEquivalentsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.cashAndCashEquivalentsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="cashAndCashEquivalentsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}

            //     value={year3?.cashAndCashEquivalentsGross}
            // // required
            // />,
            depreciation1: <AvField
                name="cashAndCashEquivalentsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.cashAndCashEquivalentsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="cashAndCashEquivalentsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.cashAndCashEquivalentsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="cashAndCashEquivalentsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.cashAndCashEquivalentsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="cashAndCashEquivalentsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.cashAndCashEquivalentsGross1?.value) - Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="cashAndCashEquivalentsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.cashAndCashEquivalentsGross2?.value) - Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="cashAndCashEquivalentsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.cashAndCashEquivalentsGross3?.value) - Number(formRef.current?._inputs?.cashAndCashEquivalentsDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '26',
            asset: <span>{t("Banks, financial and similar institutions")}</span>,
            gross1: <AvField
                name="banksFinancialGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.banksFinancialGross}

            // required
            />,
            // gross2: <AvField
            //     name="banksFinancialGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.banksFinancialGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="banksFinancialGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.banksFinancialGross}

            // // required
            // />,
            depreciation1: <AvField
                name="banksFinancialDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.banksFinancialDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="banksFinancialDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.banksFinancialDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="banksFinancialDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.banksFinancialDepreciation}

            // // required
            // />,
            net1: <AvField
                name="banksFinancialNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.banksFinancialGross1?.value) - Number(formRef.current?._inputs?.banksFinancialDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="banksFinancialNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.banksFinancialGross2?.value) - Number(formRef.current?._inputs?.banksFinancialDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="banksFinancialNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.banksFinancialGross3?.value) - Number(formRef.current?._inputs?.banksFinancialDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '27',
            asset: <strong>{t("Cash and financial instruments")}</strong>,
            gross1: <strong>{cashandFinancialIInstrumentsGross1 ? cashandFinancialIInstrumentsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{cashandFinancialIInstrumentsGross2 ? cashandFinancialIInstrumentsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{cashandFinancialIInstrumentsGross3 ? cashandFinancialIInstrumentsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{cashandFinancialIInstrumentsDepreciation1 ? cashandFinancialIInstrumentsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{cashandFinancialIInstrumentsDepreciation2 ? cashandFinancialIInstrumentsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{cashandFinancialIInstrumentsDepreciation3 ? cashandFinancialIInstrumentsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{cashandFinancialIInstrumentsNet1 ? cashandFinancialIInstrumentsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{cashandFinancialIInstrumentsNet2 ? cashandFinancialIInstrumentsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{cashandFinancialIInstrumentsNet3 ? cashandFinancialIInstrumentsNet3?.toLocaleString() : 0}</strong>,
        },
        {
            key: '28',
            asset: <span>{t("Valuation difference Assets")}</span>,
            gross1: <AvField
                name="valuationDifferenceAssetsGross1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.valuationDifferenceAssetsGross}

            // required
            />,
            // gross2: <AvField
            //     name="valuationDifferenceAssetsGross2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.valuationDifferenceAssetsGross}

            // // required
            // />,
            // gross3: <AvField
            //     name="valuationDifferenceAssetsGross3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.valuationDifferenceAssetsGross}

            // // required
            // />,
            depreciation1: <AvField
                name="valuationDifferenceAssetsDepreciation1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                onChange={(e) => onChangeSetValue(e.target.value)}
                onWheel={(event) => event.currentTarget.blur()}
                value={year1?.valuationDifferenceAssetsDepreciation}

            // required
            />,
            // depreciation2: <AvField
            //     name="valuationDifferenceAssetsDepreciation2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year2?.valuationDifferenceAssetsDepreciation}

            // // required
            // />,
            // depreciation3: <AvField
            //     name="valuationDifferenceAssetsDepreciation3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     onChange={(e) => onChangeSetValue(e.target.value)}
            //     onWheel={(event) => event.currentTarget.blur()}
            //     value={year3?.valuationDifferenceAssetsDepreciation}

            // // required
            // />,
            net1: <AvField
                name="valuationDifferenceAssetsNet1"

                className="form-control"
                // placeholder="Sales of goods for resale"

                type="number"
                disabled
                value={Number(formRef.current?._inputs?.valuationDifferenceAssetsGross1?.value) - Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation1?.value)}
            // required
            />,
            // net2: <AvField
            //     name="valuationDifferenceAssetsNet2"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.valuationDifferenceAssetsGross2?.value) - Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation2?.value)}
            // // required
            // />,
            // net3: <AvField
            //     name="valuationDifferenceAssetsNet3"

            //     className="form-control"
            //     // placeholder="Sales of goods for resale"

            //     type="number"
            //     disabled
            //     value={Number(formRef.current?._inputs?.valuationDifferenceAssetsGross3?.value) - Number(formRef.current?._inputs?.valuationDifferenceAssetsDepreciation3?.value)}
            // // required
            // />,

        },
        {
            key: '29',
            asset: <strong>{t("Total assets")}</strong>,
            gross1: <strong>{totalAssetsGross1 ? totalAssetsGross1?.toLocaleString() : 0}</strong>,
            // gross2: <strong>{totalAssetsGross2 ? totalAssetsGross2?.toLocaleString() : 0}</strong>,
            // gross3: <strong>{totalAssetsGross3 ? totalAssetsGross3?.toLocaleString() : 0}</strong>,
            depreciation1: <strong>{totalAssetsDepreciation1 ? totalAssetsDepreciation1?.toLocaleString() : 0}</strong>,
            // depreciation2: <strong>{totalAssetsDepreciation2 ? totalAssetsDepreciation2?.toLocaleString() : 0}</strong>,
            // depreciation3: <strong>{totalAssetsDepreciation3 ? totalAssetsDepreciation3?.toLocaleString() : 0}</strong>,
            net1: <strong>{totalAssetsNet1 ? totalAssetsNet1?.toLocaleString() : 0}</strong>,
            // net2: <strong>{totalAssetsNet2 ? totalAssetsNet2?.toLocaleString() : 0}</strong>,
            // net3: <strong>{totalAssetsNet3 ? totalAssetsNet3?.toLocaleString() : 0}</strong>,
        },

    ];

    const onConfirm = () => {
        if (currentData?.actionType == 'Status') {
            statusChange();
        } else if (currentData?.actionType == 'Delete') {
            onDelete()
        } else {
            setDeleteModal(false)
        }
    }

    const resetValues = () => {
        setIntangiblefixedassetsDepreciation1(0)
        setIntangiblefixedassetsNet1(0)
        setIntangiblefixedassetsGross1(0)

        setIntangiblefixedassetsDepreciation2(0)
        setIntangiblefixedassetsNet2(0)
        setIntangiblefixedassetsGross2(0)

        setIntangiblefixedassetsDepreciation3(0)
        setIntangiblefixedassetsNet3(0)
        setIntangiblefixedassetsGross3(0)

        setTangiblefixedassetsDepreciation1(0)
        setTangiblefixedassetsNet1(0)
        setTangiblefixedassetsGross1(0)

        setTangiblefixedassetsDepreciation2(0)
        setTangiblefixedassetsNet2(0)
        setTangiblefixedassetsGross2(0)

        setTangiblefixedassetsDepreciation3(0)
        setTangiblefixedassetsNet3(0)
        setTangiblefixedassetsGross3(0)

        setFinancialFixedAssetsDepreciation1(0)
        setFinancialFixedAssetsNet1(0)
        setFinancialFixedAssetsGross1(0)

        setFinancialFixedAssetsDepreciation2(0)
        setFinancialFixedAssetsNet2(0)
        setFinancialFixedAssetsGross2(0)

        setFinancialFixedAssetsDepreciation3(0)
        setFinancialFixedAssetsNet3(0)
        setFinancialFixedAssetsGross3(0)

        setSuppliersAccountsPayableDepreciation1(0)
        setSuppliersAccountsPayableGross1(0)

        setSuppliersAccountsPayableDepreciation2(0)
        setSuppliersAccountsPayableGross2(0)

        setSuppliersAccountsPayableDepreciation3(0)
        setSuppliersAccountsPayableGross3(0)

        setTotalCurrentAssetsDepreciation_1(0)
        setTotalCurrentAssetsGross_1(0)
        setTotalCurrentAssetsDepreciation_2(0)
        setTotalCurrentAssetsGross_2(0)
        setTotalCurrentAssetsDepreciation_3(0)
        setTotalCurrentAssetsGross_3(0)

        setCashandFinancialIInstrumentsDepreciation1(0)
        setCashandFinancialIInstrumentsGross1(0)
        setCashandFinancialIInstrumentsDepreciation2(0)
        setCashandFinancialIInstrumentsGross2(0)
        setCashandFinancialIInstrumentsDepreciation3(0)
        setCashandFinancialIInstrumentsGross3(0)

        setValuationDifferenceAssetsGross1(0)
        setValuationDifferenceAssetsDepreciation1(0)
        setValuationDifferenceAssetsGross2(0)
        setValuationDifferenceAssetsDepreciation2(0)
        setValuationDifferenceAssetsGross3(0)
        setValuationDifferenceAssetsDepreciation3(0)
    }

    return (
        <React.Fragment>
            <MyPrompt />
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={onConfirm}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData}
            />
            <Loader loading={loading} />
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS one")}
                            </li>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("FINANCIAL DOCUMENTS two")}
                            </li>
                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> {t("Help")}</button>
            <Modal className='file_upload_popup' isOpen={excelUploadModal} centered>
                <ModalHeader toggle={toggle}>Upload Excel</ModalHeader>
                <ModalBody>

                    <FileUploader
                        multiple={true}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                    <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>

                </ModalBody>
                <ModalFooter>
                    <Button className='btn_bg' onClick={uploadExcel}>
                        Upload
                    </Button>{' '}
                    <Button color="secondary" className='btn_secondary btn_bg' onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <div>
                <AvForm
                    id="myForm"
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                    }}
                    ref={formRef}
                >
                    {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <Table dataSource={data}
                                        scroll={{
                                            x: window?.innerWidth < 991 ? 1000:0,
                                            y: 350
                                        }}
                                        pagination={false}
                                    >
                                        <Column fixed={window?.innerWidth < 991 ? '':'left'} style={{ width: '30%' }} title={t("Asset")} dataIndex="asset" key="asset" />
                                        <ColumnGroup title={<> <AvField type="select" name="year_one" value={year1?.year} onChange={(e) => getUserData(e.target.value, setYear1)}>
                                            <option value="">{t("Select Year")}*</option>
                                            {years?.map((item) => (
                                                <option value={item?.value}>{item?.label}</option>
                                            ))}
                                        </AvField></>}>
                                            <Column title={t("Gross")} dataIndex="gross1" key="gross1" />
                                            <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation1" key="depreciation1" />
                                            <Column title={t("Net")} dataIndex="net1" key="net1" />
                                        </ColumnGroup>
                                        {/* <ColumnGroup title={<> <AvField type="select" name="year_two" value={year2?.year} onChange={(e) => getUserData(e.target.value, setYear2)}>
                                            <option value="">{t("Select Year")}</option>
                                            {years?.map((item) => (
                                                <option value={item?.value}>{item?.label}</option>
                                            ))}
                                        </AvField></>}>
                                            <Column title={t("Gross")} dataIndex="gross2" key="gross2" />
                                            <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation2" key="depreciation2" />
                                            <Column title={t("Net")} dataIndex="net2" key="net2" />
                                        </ColumnGroup>
                                        <ColumnGroup title={<> <AvField type="select" name="year_three" value={year3?.year} onChange={(e) => getUserData(e.target.value, setYear3)}>
                                            <option value="">{t("Select Year")}</option>
                                            {years?.map((item) => (
                                                <option value={item?.value}>{item?.label}</option>
                                            ))}
                                        </AvField></>}>
                                            <Column title={t("Gross")} dataIndex="gross3" key="gross3" />
                                            <Column title={t("DepreciationAmortizationImpairment")} dataIndex="depreciation3" key="depreciation3" />
                                            <Column title={t("Net")} dataIndex="net3" key="net3" />
                                        </ColumnGroup> */}

                                    </Table>
                                </Col>

                            </Row>

                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <div className="text-center button-group-section">
                                <Button type="submit" className='btn_bg' color="" onClick={() => setType('save')}>
                                    {t("Save")}
                                </Button>
                                <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                                    {t("Save & Submit")}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>

                </AvForm>
            </div>


        </React.Fragment>
    )
}

BalanceSheet.propTypes = {
    t: PropTypes.any,
    profileSuccess: PropTypes.func
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems, profileSuccess })(BalanceSheet)
)




