// import React from "react"
// import ReactApexChart from "react-apexcharts"

// const balancepieChart = () => {
//   const series = [80, 100, 80, 60, 60, 40, 80]

//   const options = {
//     labels: [
//       "Conscientiousness",
//       "Locus of Control",
//       "Honesty",
//       "Fluid Intelligence",
//       "Moderation",
//       "Overconfidence",
//       "Optimism"
//     ],
//     legend: {
//       show: true,
//       showForSingleSeries: false,
//       showForNullSeries: true,
//       showForZeroSeries: true,
//       horizontalAlign: "right",
//       floating: false,
//       fontSize: "14px",
//       fontFamily: "Helvetica, Arial",
//       width: 200,
//       height: undefined,
//       formatter: undefined,
//       offsetX: 0,
//       offsetY: 100,
//       labels: {
//         colors: undefined,
//         useSeriesColors: false
//       },
//       markers: {
//         width: 12,
//         height: 12,
//         strokeWidth: 0,
//         strokeColor: "#fff",
//         radius: 10,
//         customHTML: undefined,
//         onClick: undefined,
//         offsetX: 0,
//         offsetY: 0
//       },

//       onItemClick: {
//         toggleDataSeries: true
//       },
//       onItemHover: {
//         highlightDataSeries: true
//       }
//     },
//     plotOptions: {
//       radialBar: {
//         size: undefined,
//         inverseOrder: false,
//         startAngle: 0,
//         endAngle: 360,
//         offsetX: 0,
//         offsetY: 0,
//         hollow: {
//           margin: 5,
//           size: "30%",
//           background: "transparent",
//           image: undefined,
//           imageWidth: 150,
//           imageHeight: 150,
//           imageOffsetX: 0,
//           imageOffsetY: 0,
//           imageClipped: true,
//           position: "front",
//           dropShadow: {
//             enabled: false,
//             top: 0,
//             left: 0,
//             blur: 3,
//             opacity: 0.5
//           }
//         },
//         track: {
//           show: true,
//           startAngle: undefined,
//           endAngle: undefined,
//           background: "#f2f2f2",
//           strokeWidth: "97%",
//           opacity: 1,
//           margin: 5,
//           dropShadow: {
//             enabled: false,
//             top: 0,
//             left: 0,
//             blur: 3,
//             opacity: 0.5
//           }
//         },
//         dataLabels: {
//           show: true,
//           name: {
//             show: true,
//             fontSize: "16px",
//             fontFamily: undefined,
//             color: undefined,
//             offsetY: -10
//           },
//           value: {
//             show: true,
//             fontSize: "32px",
//             fontFamily: undefined,
//             color: undefined,
//             offsetY: 16,
//             formatter: function (val) {
//               return val / 10 / 2;
//             }
//           },
//           total: {
//             show: true,
//             label: "Score",
//             color: "#373d3f",
//             formatter: function (w) {
//               return "08";
//             }
//           }
//         }
//       }
//     },
//     colors: [
//       "#BD1E6F",
//       "#A21F6E",
//       "#86206C",
//       "#6B226B",
//       "#502369",
//       "#342468",
//       "#192566"
//     ]
//   }


//   return (
//     <ReactApexChart options={options} series={series} type="pie" height="380" />
//   )
// }

// export default balancepieChart

import React, { Component } from "react";
import Chart from "react-apexcharts";

const balancepieChart = ({ title, value }) => {

  const options = {
    labels: title,
    legend: {
      show: false,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      width: 200,
      height: undefined,
      formatter: undefined,
      offsetX: 0,
      offsetY: 100,
      labels: {
        color: "#559a9d",
        useSeriesColors: false
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#559a9d",
        radius: 10,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },

      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      }
    },
    plotOptions: {
      radialBar: {
        size: undefined,
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: "30%",
          background: "transparent",
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: "16px",
            fontFamily: undefined,
            color: "#559a9d",
            offsetY: -6,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: undefined,
            color: "#559a9d",
            offsetY: 6,
            formatter: function (val) {
              return Number(val).toLocaleString() + " %";
            }
          },
          total: {
            show: true,
            label: title[0],
            color: "#559a9d",
            formatter: function (w) {
              return Number(value[0]).toLocaleString() + " %";
            }
          }
        }
      }
    },
    colors: [
      "#1b005e"
    ]
  }

  const series = value




  return (
    <div className="donut">
      <Chart
        options={options}
        series={series}
        type="radialBar"
        width="460"
      />
    </div>
  );

}

export default balancepieChart;
