import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useLocation } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { profileSuccess } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { get, post } from 'helpers/helper_api';

import { toast } from 'react-toastify';
import MyPrompt from 'components/MyPrompt';
// import { withTranslation } from "react-i18next"

const Index = props => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState([]);
    const formRef = useRef();
    const [type, setType] = useState('save');
    const [buttonStatus, setButtonStatus] = useState(false)
    const { user } = props;

    const wasteManagement = (e, parent, child) => {
        let temp = Object?.assign([], data);
        if (e.target?.value == "Yes") {
            // setWastemanagement(true)
            temp[parent].questions[child].isOpen = true;
        } else {
            // setWastemanagement(false)
            temp[parent].questions[child].isOpen = false;
        }
        setData(temp)
    }

    useEffect(() => {
        const isEmpty = Object.values(form).some((field) => field?.answer === 0 || field?.answer == '');


        if (isEmpty) {
            setButtonStatus(false)
        } else {
            setButtonStatus(true)
        }
    }, [form])
    const handleInput = (v, q) => {
        let temp = Object.assign([], form);
        let already = temp?.findIndex(x => x.quesId == q)
        if (already == -1) {
            temp.push({ quesId: q, answer: v })
        }
        else {
            temp[already].answer = v
        }
        setForm(temp)
    }
    useEffect(() => {
        if (props?.user?.token)
            getUserData(location?.state?.id);
    }, [props])

    // console.log('location props', location)


    const getUserData = (userID) => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/esg/question/list', body)
                .then((res => {
                    setLoading(false);
                    // console.log('esg for sme question list', res)
                    if (res?.statusCode == 200) {
                        get(`/esg/answer/user?userId=${user?._id}`, { token: user?.token })
                            .then(ansRes => {
                                if (ansRes?.statusCode == 200) {
                                    let formTemp = [];
                                    res?.data?.map((item) => {
                                        if (ansRes?.data?.length > 0) {
                                            ansRes?.data?.map(ans => {
                                                if (ans?.questions?.length > 0) {
                                                    ans?.questions.map(ques => {
                                                        item?.questions?.map(ques2 => {
                                                            // ques2.answer = '';
                                                            if (ques?._id == ques2?._id) {
                                                                ques2.isOpen = false;
                                                                ques2.answer = userID == undefined ? null : ques?.answer;
                                                                formTemp.push({ quesId: ques?._id, answer: ques?.answer });
                                                                // console.log("question type", ques2?.type, ques?.answer);
                                                                if (ques2?.type != "text") {
                                                                    if (ques.answer != "No") {
                                                                        ques2.isOpen = userID == undefined ? null : true;
                                                                    } else {
                                                                        ques2.answer = userID == undefined ? null : "No";
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    });
                                    setForm(formTemp);
                                    setData(res?.data)
                                }
                            })
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "ESG", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('ESG', breadcrumbItems)
    }, [])

    function handleValidSubmit(event, values) {
        if (!loading) {
            let body = {
                userId: user?._id,
                token: user?.token,
                isSubmit: type == 'submit' ? true : undefined,
                form: form
            }
            console.log('submitting form', form);
            setLoading(true)
            post('/esg/answer', body)
                .then((res => {
                    setLoading(false);
                    console.log('ad answer', res)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        if (user?.step == 4) {
                            props?.profileSuccess({ ...user, step: 5 });
                            localStorage.setItem("user", JSON.stringify({ ...user, step: 5 }));
                        }
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }

    return (
        <React.Fragment>
            <MyPrompt />
            <MetaTags>
                <title>ESG | Demleen </title>
            </MetaTags>

            <Loader loading={loading} />
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
                ref={formRef}
            >
                {/* <h4 className="card-title mb-4">{t("Gross Margin")}</h4> */}
                <Card>
                    <CardBody>

                        <Row>
                            <Col lg={12}>
                                <Table

                                >

                                    <tbody >

                                        <tr>
                                            <td style={{ padding: 0, border: 'none' }} colspan="2">
                                                <Table
                                                    bordered
                                                    responsive
                                                >
                                                    <thead >
                                                        <tr className='right_head'>
                                                            <th colspan="5">{t("ESG")} <span className='i_text'>i</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data?.map((item, index) => (
                                                                <>
                                                                    <tr style={{ backgroundColor: '#f5f5f5' }} className='second_head_theree'>

                                                                        <th colSpan={2} style={{ width: '70%' }}>
                                                                            {i18n?.language == 'en' ? item?.title : item?.titleFs}
                                                                        </th>
                                                                        {/* <th style={{ width: '20%' }}>{t("Type")}</th> */}
                                                                    </tr>
                                                                    <tr className='second_head_theree'>
                                                                        <th colSpan={2} style={{ width: '70%' }}>
                                                                            {i18n?.language == 'en' ? item?.desc : item?.descFs}
                                                                        </th>
                                                                    </tr>
                                                                    {
                                                                        item?.questions?.map((item1, index1) => (

                                                                            <>
                                                                                {console.log(item1)}
                                                                                <tr>
                                                                                    <td>
                                                                                        {i18n?.language == 'en' ? item1?.title : item1?.titleFs}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item1?.type == 'text' ? <AvField
                                                                                                type="number"
                                                                                                name="text"
                                                                                                // required
                                                                                                value={item1?.answer}
                                                                                                onChange={(e) => handleInput(e.target.value, item1?._id)}
                                                                                            /> :

                                                                                                <td>
                                                                                                    <div className="form-group form_group">

                                                                                                        <AvRadioGroup inline name={"yes_no" + (index1 + 1)} value={item1?.isOpen ? "Yes" : "No"} onChange={(e) => { handleInput(e.target.value, item1?._id); wasteManagement(e, index, index1) }}>
                                                                                                            <AvRadio label={t("Yes")} value="Yes" />
                                                                                                            <AvRadio label={t("No")} value="No" />
                                                                                                        </AvRadioGroup>
                                                                                                    </div>

                                                                                                </td>
                                                                                        }

                                                                                    </td>

                                                                                </tr>
                                                                                {
                                                                                    item1?.isOpen == true &&

                                                                                    <tr >
                                                                                        <td colSpan={2} >
                                                                                            {
                                                                                                item1?.isOpen && <Col lg={12} md={12}>
                                                                                                    <div className="form-group">
                                                                                                        <AvField
                                                                                                            name="activity_description"
                                                                                                            onChange={(e) => handleInput(e.target.value, item1?._id)}
                                                                                                            className="form-control"
                                                                                                            placeholder={t("Short Description")}
                                                                                                            type="textarea"
                                                                                                            value={item1?.answer}
                                                                                                        // required


                                                                                                        />

                                                                                                    </div>
                                                                                                </Col>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                            ))
                                                        }



                                                    </tbody>
                                                </Table>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className="text-center button-group-section">
                            {/* <Button type="submit" className='btn_bg' color="">
                                {
                                    buttonStatus == true ? <span>{t("Submit")}</span> : <span>{t("Save")}</span>
                                }

                            </Button> */}
                            <Button type="submit" className='btn_bg' color="">
                                {t("Save")}
                            </Button>
                            <Button type="submit" onClick={() => setType('submit')} className='btn_bg mx-5' color="">
                                {t("Save & Submit")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </AvForm>


        </React.Fragment>
    )
}

Index.propTypes = {
    profileSuccess: PropTypes.func,
    t: PropTypes.any
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { profileSuccess, setBreadcrumbItems })(Index)
)