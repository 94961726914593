import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import moment from 'moment';
import Loader from 'components/Loader';
const Neuroprofiler1 = props => {
    let { user } = props;
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();
    // console.log('profile page', user)
    const { role } = props?.user;
    const [selectedSdg, setSelectedSdg] = useState([]);
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investmentRegion, setInvestmentRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const [lang, setLang] = useState(null);

    const [data, setData] = useState(null);
    const [age, setAge] = useState(null);
    const [userID, setUserID] = useState(null);
    const [alertType, setAlerType] = useState("");
    const [file, setFile] = useState(avatar);
    const [file1, setFile1] = useState(avatar);
    const [changeIcon, setChangeIcon] = useState(false);
    const [changeIcon1, setChangeIcon1] = useState(false);
    const [progress, setProgress] = useState(45)
    const [member, setMember] = useState([{}])
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(undefined);


    useEffect(() => {
        if (props?.userId)
            getUserData();
    }, [props?.userId])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])


    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: props?.user?.token

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login cong', res);
                        setData(res?.data)
                        setValue(res?.data?.nuroRating?.rating)

                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }
    // useEffect(() => {
    //     if (props?.user?.token)
    //       getUserDataF();
    //   }, [props?.user?.token])

    const getUserDataF = () => {
        let body = {
            userId: props?.userId,
            token: props?.user?.token,

        }
        post('/user', body)
            .then((res => {
                // setLoading(false);
                if (res?.statusCode == 200) {
                    // console.log('request on login', res);

                    setData(res?.data)
                    setValue(res?.data?.nuroRating?.rating)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                // setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }

    useEffect(() => {
        // console.log("user on nuero", user);
        if (!loading) {
            setLoading(true)
            if (props?.userId)
                post("/nuro/profile", { token: user?.token, userId: props?.userId })
                    .then(res => {
                        setLoading(false)
                        if (res?.statusCode == 200) {
                            setResult(res?.data)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.error(err);
                        toast.error("Something Went Wrong!");
                    })
        }
    }, [props?.userId]);


    function handleValidSubmit(e, v) {
        if (!loading) {
            setLoading(true)
            let body = {
                ...v,
                userId: props?.userId,
                token: props?.user?.token,
                nuroRating: {
                    comment: v?.nuroComment,
                    rating: value
                },

            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);
                        getUserData()
                        toast.success(res?.message)
                        // setValue(0)
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }


    return (
        <React.Fragment>

            <Loader loading={loading} />

            <AvForm
                className="form-horizontal form_horizontal_admin"
                onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                }}
            >
                <div className='member_section member_section_new mt-2'>
                    <Row>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div >
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>{t("Loss Aversion")} :</strong> {result?.loss_aversion}</p></li>
                                        </ul>

                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>
                                            <li><p><strong>{t("Loss Aversion Quantile")} :</strong> {result?.loss_aversion_quantile}</p></li>
                                        </ul>

                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>{t("Risk Profile")} :</strong> {t(result?.risk_profile)}</p></li>

                                        </ul>

                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>{t("Safe Asset Proportion in Allocation")} :</strong> {result?.safe_asset_proportion_in_allocation}</p></li>

                                        </ul>

                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">

                                        <ul className='member_section_detail'>

                                            <li><p><strong>{t("Winning Appetite")} :</strong> {result?.winning_appetite}</p></li>

                                        </ul>

                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">
                                        <ul className='member_section_detail'>
                                            <li><p><strong>{t("Winning Appetite Quantile")} :</strong> {result?.winning_appetite_quantile}</p></li>
                                        </ul>
                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">
                                        <ul className='member_section_detail'>
                                            <li><p><strong>Admin Review :</strong> {props?.data?.nuroRating?.comment}</p></li>
                                        </ul>
                                    </blockquote>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="form-control mb-3">
                                <div>
                                    <blockquote className="card-blockquote mb-0">
                                        <ul className='member_section_detail'>
                                            <li><p><strong>Admin Rating :</strong> {props?.data?.nuroRating?.rating}</p></li>
                                        </ul>
                                    </blockquote>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
                {
                    props?.isComment == true &&
                    <>
                        <h4 className="card-title mb-4">{t("Final Assessment")}</h4>
                        <div className='comment_section'>
                            <div>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group mb-3">
                                            <AvField type="textarea" name="nuroComment" label={t("Write Comment")} />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-group rating-box mb-3">
                                            <label>{t("Rating")}</label>
                                            <div className='slider-value'>
                                                <span>0</span>
                                                <RangeSlider
                                                    tooltip="on"
                                                    tooltipPlacement="bottom"
                                                    value={value}
                                                    onChange={changeEvent => setValue(changeEvent.target.value)}
                                                    min={0}
                                                    max={5}
                                                    // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                                                    step={1}

                                                />
                                                <span>5</span>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </div>
                        <div>
                            <div>
                                <h4 className="card-title comment_title mb-4">{t("Latest Comment")} <span>{moment(data?.nuroRating?.commentedOn).format("YYYY-MM-DD HH:mm:ss")}</span></h4>
                                <p>{data?.nuroRating?.comment}</p>

                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="text-center">
                                    <Button type="submit" className='btn_bg' color="">
                                        {t("Submit")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </AvForm>


        </React.Fragment>
    )
}



export default Neuroprofiler1;

