import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
  CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import { post, put } from 'helpers/helper_api';
import Loader from 'components/Loader';
import { Thead } from 'react-super-responsive-table';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
import moment from 'moment';

const CashFlow = (props) => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [year1, setYear1] = useState(undefined);
  const [year2, setYear2] = useState(undefined);
  const [year3, setYear3] = useState(undefined);
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null)
  const getUserData = (year, setter) => {
    if (!loading) {
      setLoading(true);
      let body = {
        year,
        userId: props?.userId,
        token: user?.token
      }
      post('/cash_flow', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            setter(res?.data);
          } else {
            setter(undefined);
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setter(undefined);
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        });
    }
  }

  const { t } = useTranslation();

  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: "Cash Flow Detail", link: "#" },
  ]

  function handleValidSubmit(e, v) {
    if (!loading) {
      let body = {
        ...v,
        userId: props?.userId,
        token: props?.user?.token,
        cashFlowRating: {
          comment: v?.cashFlowComment,
          rating: value
        },

      }
      put('/user', body)
        .then((res => {
          // setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            getUserData1();
            toast.success(res?.message)
            // setValue(0)
          } else {
            console.log(res?.error);
            toast.error(res?.error)
          }
        }))
        .catch(error => {
          // setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
          toast.error('Something went wrong')
        })
    }
  }
  useEffect(() => {
    if (props?.user?.token)
      getUserData1();
  }, [props?.user?.token])

  const getUserData1 = () => {
    if(!loading){
      setLoading(true)
    let body = {
      userId: props?.userId,
      token: props?.user?.token,

    }
    post('/user', body)
      .then((res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          // console.log('request on login', res);

          setData(res?.data)
          setValue(res?.data?.cashFlowRating?.rating)
          console.log('cash flow', res?.data?.cashFlowRating?.rating)
        } else {
          console.log(res?.error);
        }
      }))
      .catch(error => {
        setLoading(false);
        console.log('error while login', error);
        console.log('Something went wrong');
      })
  }
}


  console.log('cash flow rating', props?.data)

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <MetaTags>
        <title>Profit and Loss account | Demleen </title>
      </MetaTags>


      <Row>
        <Col className="col-12">
          <Card>
            <CardBody >
              {/* <CardTitle className="h4 mb-3">Stripped example </CardTitle> */}
              {/*                   
                  <BootstrapTable
                  keyField="id"
                  data={data}
                  columns={columns}
                  expandRow={ expandRow }
                  
                /> */}
              <div className=''>
                <table class="table normel_tabkle table-bordered" >
                  <thead style={{ backgroundColor: '#1b005e', color: '#fff' }}>
                    <tr className='col_header'>
                      <th scope="col">{t("Years")}</th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3)} >
                            <option value="">{t("Select Year")}</option>
                            <option>2017</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                            <option>2021</option>
                            <option>2022</option>
                          </AvField>
                        </AvForm>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Treasury at 1st Jan")}</td>
                      <td className='text_right'>{year1?.ZA}</td>
                      <td className='text_right'>{year2?.ZA}</td>
                      <td className='text_right'>{year3?.ZA}</td>
                    </tr>
                    <tr>
                      <td>{t("Self-financing Capacity = Net Income + Depreciation & Amortization - Exceptional Income")}</td>
                      <td className='text_right'>{year1?.FA}</td>
                      <td className='text_right'>{year2?.FA}</td>
                      <td className='text_right'>{year3?.FA}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Inventory")}</td>
                      <td className='text_right'>{year1?.FC}</td>
                      <td className='text_right'>{year2?.FC}</td>
                      <td className='text_right'>{year3?.FC}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Accounts Receivable")}</td>
                      <td className='text_right'>{year1?.FD}</td>
                      <td className='text_right'>{year2?.FD}</td>
                      <td className='text_right'>{year3?.FD}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Current Liabilities")}</td>
                      <td className='text_right'>{year1?.FE}</td>
                      <td className='text_right'>{year2?.FE}</td>
                      <td className='text_right'>{year3?.FE}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Working Capital Requirement")}</td>
                      <td className='text_right'>{year1?.changeInWorkingCapitalRequirement}</td>
                      <td className='text_right'>{year2?.changeInWorkingCapitalRequirement}</td>
                      <td className='text_right'>{year3?.changeInWorkingCapitalRequirement}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("Operating Cash Flow")}</th>
                      <th className='text_right' scope="col">{year1?.ZB}</th>
                      <th className='text_right' scope="col">{year2?.ZB}</th>
                      <th className='text_right' scope="col">{year3?.ZB}</th>
                    </tr>
                    <tr>
                      <td>{t("Capital Expenditures (intangible assets)")}</td>
                      <td className='text_right'>{year1?.FF}</td>
                      <td className='text_right'>{year2?.FF}</td>
                      <td className='text_right'>{year3?.FF}</td>
                    </tr>
                    <tr>
                      <td>{t("Capital Expenditures (tangible assets)")}</td>
                      <td className='text_right'>{year1?.FG}</td>
                      <td className='text_right'>{year2?.FG}</td>
                      <td className='text_right'>{year3?.FG}</td>
                    </tr>
                    <tr>
                      <td>{t("Acquisition of Financial Assets")}</td>
                      <td className='text_right'>{year1?.FH}</td>
                      <td className='text_right'>{year2?.FH}</td>
                      <td className='text_right'>{year3?.FH}</td>
                    </tr>
                    <tr>
                      <td>{t("Sale of Property, Plant & Equipment")}</td>
                      <td className='text_right'>{year1?.FI}</td>
                      <td className='text_right'>{year2?.FI}</td>
                      <td className='text_right'>{year3?.FI}</td>
                    </tr>
                    <tr>
                      <td>{t("Sale of Property, Plant & Equipment")}</td>
                      <td className='text_right'>{year1?.FJ}</td>
                      <td className='text_right'>{year2?.FJ}</td>
                      <td className='text_right'>{year3?.FJ}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("Investing Cash Flow")}</th>
                      <th className='text_right' scope="col">{year1?.ZC}</th>
                      <th className='text_right' scope="col">{year2?.ZC}</th>
                      <th className='text_right' scope="col">{year3?.ZC}</th>
                    </tr>
                    <tr>
                      <td>{t("Issuance of new shares")}</td>
                      <td className='text_right'>{year1?.FK}</td>
                      <td className='text_right'>{year2?.FK}</td>
                      <td className='text_right'>{year3?.FK}</td>
                    </tr>
                    <tr>
                      <td>{t("Received Investment grants")}</td>
                      <td className='text_right'>{year1?.FL}</td>
                      <td className='text_right'>{year2?.FL}</td>
                      <td className='text_right'>{year3?.FL}</td>
                    </tr>
                    <tr>
                      <td>{t("Levy on Equity")}</td>
                      <td className='text_right'>{year1?.FM}</td>
                      <td className='text_right'>{year2?.FM}</td>
                      <td className='text_right'>{year3?.FM}</td>
                    </tr>
                    <tr>
                      <td>{t("Paid Dividend")}</td>
                      <td className='text_right'>{year1?.FN}</td>
                      <td className='text_right'>{year2?.FN}</td>
                      <td className='text_right'>{year3?.FN}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash flow from Equity")}</th>
                      <td className='text_right'>{year1?.ZD}</td>
                      <td className='text_right'>{year2?.ZD}</td>
                      <td className='text_right'>{year3?.ZD}</td>
                    </tr>
                    <tr>
                      <td>{t("Debt")}</td>
                      <td className='text_right'>{year1?.FO}</td>
                      <td className='text_right'>{year2?.FO}</td>
                      <td className='text_right'>{year3?.FO}</td>
                    </tr>
                    <tr>
                      <td>{t("Other loans and debts")}</td>
                      <td className='text_right'>{year1?.FP}</td>
                      <td className='text_right'>{year2?.FP}</td>
                      <td className='text_right'>{year3?.FP}</td>
                    </tr>
                    <tr>
                      <td>{t("Repayments of loans and other financial debts")}</td>
                      <td className='text_right'>{year1?.FQ}</td>
                      <td className='text_right'>{year2?.FQ}</td>
                      <td className='text_right'>{year3?.FQ}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash Flow from Debt")}</th>
                      <td className='text_right'>{year1?.ZE}</td>
                      <td className='text_right'>{year2?.ZE}</td>
                      <td className='text_right'>{year3?.ZE}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash Flow")}</th>
                      <td className='text_right'>{year1?.ZF}</td>
                      <td className='text_right'>{year2?.ZF}</td>
                      <td className='text_right'>{year3?.ZF}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Net Treasury")}</td>
                      <td className='text_right'>{year1?.ZG}</td>
                      <td className='text_right'>{year2?.ZG}</td>
                      <td className='text_right'>{year3?.ZG}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Treasury at 31 Dec")}</th>
                      <td className='text_right'>{year1?.ZH}</td>
                      <td className='text_right'>{year2?.ZH}</td>
                      <td className='text_right'>{year3?.ZH}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          
        </Col>
      </Row>

    </React.Fragment>
  )
}


export default CashFlow;