import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import BarApexChart from "../AllCharts/apex/profitabilityChart";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { editProfile, resetProfileFlag } from "../../store/actions"
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import IncomestatementanalysisChart from 'pages/AllCharts/chartjs/incomeChart';
import { get } from 'helpers/helper_api';

const dataAnlysis = [
    {
        title: 'Revenue Growth',
        ratio: 2.5,
        desc: "Revenue Growth Desc"
    },
    {
        title: 'Gross Margin',
        ratio: 2.5,
        desc: "Gross Margin Desc"
    },
    {
        title: 'Operating Expense',
        ratio: 2.5,
        desc: "Operating Expense Desc"
    },
    {
        title: 'EBITDA Margin',
        ratio: 2.5,
        desc: "EBITDA Margin Desc"
    },
    {
        title: 'Operating Profit Margin',
        ratio: 2.5,
        desc: "Operating Profit Margin Desc"
    },
    {
        title: 'Return On Assets',
        ratio: 2.5,
        desc: "Return On Assets Desc"
    },
    {
        title: 'Return On Equity',
        ratio: 2.5,
        desc: "Return On Equity Desc"
    }
]

function IncomeStatementAnalysis(props) {
    const { i18n, t } = useTranslation();
    const [years, setYears] = useState('');
    const [yearsArr, setYearsArr] = useState(undefined);
    const [helpModal, setHelpModal] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [explainData, setExplainData] = useState(undefined)
    const [explainData1, setExplainData1] = useState(dataAnlysis[0])
    const [data, setData] = useState(undefined)
    const [title, setTitle] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [graphTitle, setGraphTitle] = useState(undefined)
    const [graphValue, setGraphValue] = useState(undefined)
    const [curruntData, setCurruntData] = useState(undefined)
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("Income Statement Analysis"), link: "#" },
    ]
    const { user } = props;



    useEffect(() => {
        props.setBreadcrumbItems(t("Income Statement Analysis"), breadcrumbItems)
    }, [i18n?.language])

    const changeTab = (item, index) => {
        setActiveTab(index)
        console.log('indexindexindex', index)
        setCurruntData(item)
        setExplainData1(dataAnlysis[index])
        if (item?.graph?.length > 0) {
            let graphT = [];

            item?.graph.map((item) => (
                graphT.push(item?.title)
            ))
            setGraphTitle(graphT?.reverse())
            let graphV = [];
            item?.graph.map((item) => (
                graphV.push(item?.value)
            ))
            setGraphValue(graphV?.reverse())
        }
        // setCurruntData(explainData?.loop[index])
    }

    useEffect(() => {
        if (user?.token) {
            getData()
        }
    }, [user, years])

    const getData = () => {
        let body = {
            token: user?.token,
        }
        let url = '/ratio/profit';
        if (years)
            url = `/ratio/profit?year=${years}`
        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {



                        if (res?.data?.years?.length) {

                            if (years == '') {
                                setYears(res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();

                            setYearsArr(yearsArr)

                        }
                        let temp = [];
                        res?.data?.allGraprh?.map((item) => (
                            temp.push(item?.title)
                        ))
                        console.log('ration temp', temp)

                        setExplainData(res?.data?.loop)
                        if (res?.data?.loop?.length)
                            setCurruntData(res?.data?.loop[0])
                        if (res?.data?.loop?.length > 0) {
                            let graphT = [];

                            res?.data?.loop[0]?.graph.map((item) => (
                                graphT.push(item?.title)
                            ))
                            setGraphTitle(graphT?.reverse())
                            let graphV = [];
                            res?.data?.loop[0]?.graph.map((item) => (
                                graphV.push(item?.value)
                            ))
                            setGraphValue(graphV?.reverse())
                        }

                        setTitle(temp)
                        let temp1 = [];

                        res?.data?.allGraprh?.map((item) => (
                            temp1.push(item?.value)
                        ))
                        // console.log('ration temp', temp)
                        setValue(temp1)
                        // rest of your code
                    } else {
                        console.error('Data is undefined in the response');
                    }



                }
            }).catch((err) => {
                console.log('err', err)
            })
    }

    const changeYearsData = (item) => {
        setYears(item);
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Modal size="lg" className='file_upload_popup' isOpen={helpModal} centered>
                <ModalHeader toggle={() => setHelpModal(!helpModal)}>Instruction</ModalHeader>
                <ModalBody>
                    <div className="popup_content">
                        <ul>
                            <li><i className="mdi mdi-hand-pointing-right"></i>
                                {t("RATIO ANALYSIS")}
                            </li>

                        </ul>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("EmailContact") }}></p>
                    </div>
                </ModalBody>

            </Modal>

            <button className="help_btn" onClick={() => setHelpModal(!helpModal)}><span>i</span> {t("Help")}</button>
            <Card>
                <CardBody>
                    <h6 className='explain-text'><strong><span>{t("profit_chat_label")} : {years}</span></strong></h6>
                </CardBody>
            </Card>
            <Card className='ratio_section'>
                <CardBody>
                    <div className='top_ratio'>
                        <ul className='ratio_list'>
                            {
                                explainData?.map((item, index) => (
                                    <li className={index == activeTab ? 'active' : null} onClick={() => changeTab(item, index)}>
                                        <h3>{t(item?.title)}</h3>
                                        <h1>{Number(item?.value).toLocaleString()} {t(item?.valueType)}</h1>
                                    </li>
                                ))
                            }


                        </ul>
                    </div>
                </CardBody>
            </Card>
            <Row>
                {
                    explainData1 &&

                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p className='explain-text'><span>{t(curruntData?.title)} :</span> {t(explainData1?.desc)}</p>
                            </CardBody>
                        </Card>
                    </Col>
                }
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center mb-3 grap_heading'>{t(curruntData?.title)}</h4>
                            <div className='year_chart'>
                                {
                                    graphTitle && graphValue && <IncomestatementanalysisChart label={curruntData?.title} title={graphTitle} value={graphValue} />
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h4 className='text-center mb-3 grap_heading'>{t("Profitability Ratios")}</h4>
                            <div className='ratio_year_tabs'>
                                <ul>
                                    {
                                        yearsArr?.map((item, index) => (
                                            <li>
                                                <button onClick={() => changeYearsData(item)} className={item == years ? 'active' : null}>{item}</button>
                                            </li>
                                        ))
                                    }

                                    {/* <li>
                                        <button onClick={() => setYears('2021')} className={years == '2021' && 'active'}>2021</button>
                                    </li>
                                    <li>
                                        <button onClick={() => setYears('2022')} className={years == '2022' && 'active'}>2022</button>
                                    </li> */}
                                </ul>
                            </div>

                            <div className='year_chart mt-3'>
                                {
                                    title ? <BarApexChart t={t} data={title} value={value} /> : null
                                }

                                {/* <DountChart title={t(explainData?.title)} /> */}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

IncomeStatementAnalysis.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(IncomeStatementAnalysis)
)