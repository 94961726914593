import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
  CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import { get, post, put } from 'helpers/helper_api';
import Loader from 'components/Loader';
import { Thead } from 'react-super-responsive-table';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Utils from "../Utils";

const CashFlow = (props) => {
  const { user, userId } = props;
  const { t } = useTranslation();
  const years = Utils.getYears();
  const [loading, setLoading] = useState(false);
  const [year1, setYear1] = useState(undefined);
  const [year2, setYear2] = useState(undefined);
  const [year3, setYear3] = useState(undefined);
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);

  const getUserData = (year, setter) => {
    if (!loading) {
      setLoading(true);
      let body = {
        year,
        userId: props?.userId,
        token: user?.token
      }
      post('/cash_flow', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            setter(res?.data);
          } else {
            setter(undefined);
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setter(undefined);
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        });
    }
  }


  const breadcrumbItems = [
    { title: "Demleen", link: "#" },
    { title: "Cash Flow Detail", link: "#" },
  ]

  function handleValidSubmit(e, v) {
    if (!loading) {
      setLoading(true)
      let body = {
        ...v,
        userId: props?.userId,
        token: props?.user?.token,
        cashFlowRating: {
          comment: v?.cashFlowComment,
          rating: value
        },

      }
      put('/user', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);
            getUserData1();
            toast.success(res?.message)
            // setValue(0)
          } else {
            console.log(res?.error);
            toast.error(res?.error)
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
          toast.error('Something went wrong')
        })
    }
  }
  useEffect(() => {
    if (props?.user?.token) {
      getUserData1();
      getDefaultData();
    }
  }, [props?.user?.token, userId])

  const getUserData1 = () => {
    if (!loading) {
      setLoading(true)
      let body = {
        userId: props?.userId,
        token: props?.user?.token,

      }
      post('/user', body)
        .then((res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // console.log('request on login', res);

            setData(res?.data)
            setValue(res?.data?.cashFlowRating?.rating)
            // console.log('cash flow', res?.data?.cashFlowRating?.rating)
          } else {
            console.log(res?.error);
          }
        }))
        .catch(error => {
          setLoading(false);
          console.log('error while login', error);
          console.log('Something went wrong');
        })
    }
  }

  const getDefaultData = () => {
    if (userId)
      if (!loading) {
        setLoading(true);
        get("/cash_flow/default?userId=" + userId, { token: user?.token })
          .then(res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              res?.data?.forEach((element, index) => {
                if (index == 0)
                  setYear1(element);
                if (index == 1)
                  setYear2(element);
                if (index == 2)
                  setYear3(element);
              });
            }
          })
      }
  }

  // console.log('cash flow rating', props?.data)

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <MetaTags>
        <title>Profit and Loss account | Demleen </title>
      </MetaTags>
      <Loader loading={loading} />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody >
              {/* <CardTitle className="h4 mb-3">Stripped example </CardTitle> */}
              {/*                   
                  <BootstrapTable
                  keyField="id"
                  data={data}
                  columns={columns}
                  expandRow={ expandRow }
                  
                /> */}
             <div className='table-resp'>
                <table class="table normel_tabkle table-bordered" >
                  <thead style={{ backgroundColor: '#1b005e', color: '#fff' }}>
                    <tr className='col_header'>
                      <th scope="col">{t("Years")}</th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_one" onChange={(e) => getUserData(e.target.value, setYear1)} value={year1?.year}>
                            <option value="">{t("Select Year")}</option>
                            {years?.map((item) => (
                              <option value={item?.value}>{item?.label}</option>
                            ))}
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_two" onChange={(e) => getUserData(e.target.value, setYear2)} value={year2?.year}>
                            <option value="">{t("Select Year")}</option>
                            {years?.map((item) => (
                              <option value={item?.value}>{item?.label}</option>
                            ))}
                          </AvField>
                        </AvForm>
                      </th>
                      <th style={{ width: '16.66666666666667%' }}>
                        <AvForm>
                          <AvField type="select" name="year_three" onChange={(e) => getUserData(e.target.value, setYear3)} value={year3?.year}>
                            <option value="">{t("Select Year")}</option>
                            {years?.map((item) => (
                              <option value={item?.value}>{item?.label}</option>
                            ))}
                          </AvField>
                        </AvForm>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Treasury at 1st Jan")}</td>
                      <td className='text_right'>{year1?.ZA ? Number(year1?.ZA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZA ? Number(year2?.ZA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZA ? Number(year3?.ZA).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Self-financing Capacity = Net Income + Depreciation & Amortization - Exceptional Income")}</td>
                      <td className='text_right'>{year1?.FA ? Number(year1?.FA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FA ? Number(year2?.FA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FA ? Number(year3?.FA).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Inventory")}</td>
                      <td className='text_right'>{year1?.FC ? Number(year1?.FC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FC ? Number(year2?.FC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FC ? Number(year3?.FC).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Accounts Receivable")}</td>
                      <td className='text_right'>{year1?.FD ? Number(year1?.FD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FD ? Number(year2?.FD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FD ? Number(year3?.FD).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Current Liabilities")}</td>
                      <td className='text_right'>{year1?.FE ? Number(year1?.FE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FE ? Number(year2?.FE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FE ? Number(year3?.FE).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Working Capital Requirement")}</td>
                      <td className='text_right'>{year1?.changeInWorkingCapitalRequirement ? Number(year1?.changeInWorkingCapitalRequirement).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.changeInWorkingCapitalRequirement ? Number(year2?.changeInWorkingCapitalRequirement).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.changeInWorkingCapitalRequirement ? Number(year3?.changeInWorkingCapitalRequirement).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("Operating Cash Flow")}</th>
                      <th className='text_right' scope="col">{year1?.ZB ? Number(year1?.ZB).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.ZB ? Number(year2?.ZB).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.ZB ? Number(year3?.ZB).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Capital Expenditures (intangible assets)")}</td>
                      <td className='text_right'>{year1?.FF ? Number(year1?.FF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FF ? Number(year2?.FF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FF ? Number(year3?.FF).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Capital Expenditures (tangible assets)")}</td>
                      <td className='text_right'>{year1?.FG ? Number(year1?.FG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FG ? Number(year2?.FG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FG ? Number(year3?.FG).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Acquisition of Financial Assets")}</td>
                      <td className='text_right'>{year1?.FH ? Number(year1?.FH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FH ? Number(year2?.FH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FH ? Number(year3?.FH).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Sale of Property, Plant & Equipment")}</td>
                      <td className='text_right'>{year1?.FI ? Number(year1?.FI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FI ? Number(year2?.FI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FI ? Number(year3?.FI).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Sale of Property, Plant & Equipment")}</td>
                      <td className='text_right'>{year1?.FJ ? Number(year1?.FJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FJ ? Number(year2?.FJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FJ ? Number(year3?.FJ).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("Investing Cash Flow")}</th>
                      <th className='text_right' scope="col">{year1?.ZC ? Number(year1?.ZC).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.ZC ? Number(year2?.ZC).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.ZC ? Number(year3?.ZC).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Issuance of new shares")}</td>
                      <td className='text_right'>{year1?.FK ? Number(year1?.FK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FK ? Number(year2?.FK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FK ? Number(year3?.FK).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Received Investment grants")}</td>
                      <td className='text_right'>{year1?.FL ? Number(year1?.FL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FL ? Number(year2?.FL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FL ? Number(year3?.FL).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Levy on Equity")}</td>
                      <td className='text_right'>{year1?.FM ? Number(year1?.FM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FM ? Number(year2?.FM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FM ? Number(year3?.FM).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Paid Dividend")}</td>
                      <td className='text_right'>{year1?.FN ? Number(year1?.FN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FN ? Number(year2?.FN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FN ? Number(year3?.FN).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash flow from Equity")}</th>
                      <td className='text_right'>{year1?.ZD ? Number(year1?.ZD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZD ? Number(year2?.ZD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZD ? Number(year3?.ZD).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Debt")}</td>
                      <td className='text_right'>{year1?.FO ? Number(year1?.FO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FO ? Number(year2?.FO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FO ? Number(year3?.FO).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Other loans and debts")}</td>
                      <td className='text_right'>{year1?.FP ? Number(year1?.FP).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FP ? Number(year2?.FP).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FP ? Number(year3?.FP).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Repayments of loans and other financial debts")}</td>
                      <td className='text_right'>{year1?.FQ ? Number(year1?.FQ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.FQ ? Number(year2?.FQ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.FQ ? Number(year3?.FQ).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash Flow from Debt")}</th>
                      <td className='text_right'>{year1?.ZE ? Number(year1?.ZE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZE ? Number(year2?.ZE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZE ? Number(year3?.ZE).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Financing Cash Flow")}</th>
                      <td className='text_right'>{year1?.ZF ? Number(year1?.ZF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZF ? Number(year2?.ZF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZF ? Number(year3?.ZF).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Change in Net Treasury")}</td>
                      <td className='text_right'>{year1?.ZG ? Number(year1?.ZG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZG ? Number(year2?.ZG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZG ? Number(year3?.ZG).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th>{t("Treasury at 31 Dec")}</th>
                      <td className='text_right'>{year1?.ZH ? Number(year1?.ZH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.ZH ? Number(year2?.ZH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.ZH ? Number(year3?.ZH).toLocaleString() : "0"}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <AvForm
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            <h4 className="card-title mb-4">{t("Final Assessment")}</h4>
            <Card className='comment_section'>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="form-group mb-3">
                      <AvField type="textarea" name="cashFlowComment" required label={t("Write Comment")} value={data?.cashFlowRating?.comment} />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group rating-box mb-3">
                      <label>{t("Rating")}</label>
                      <div className='slider-value'>
                        <span>0</span>
                        <RangeSlider
                          tooltip="on"
                          tooltipPlacement="bottom"
                          value={value}
                          onChange={changeEvent => setValue(changeEvent.target.value)}
                          min={0}
                          max={5}
                          // range={currentQues ? currentQues?.options?.map((item) => { return item?.binary_gamble?.y }) : ["0"]}
                          step={1}

                        />
                        <span>5</span>
                      </div>
                    </div>
                  </Col>

                </Row>

              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title comment_title mb-4">{t("Latest Comment")} <span>{moment(data?.cashFlowRating?.commentedOn).format("DD MMM, YYYY hh:mm a")}</span></h4>
                <p>{data?.cashFlowRating?.comment}</p>

              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="text-center">
                  <Button type="submit" className='btn_bg' color="">
                    {t("Submit")}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </Col>
      </Row>

    </React.Fragment>
  )
}


export default CashFlow;