import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { renderToString } from 'react-dom/server';


const RotatedText = ({ text }) => {
    const spanRef = useRef(null);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.style.transform = 'rotate(-90deg)';
            spanRef.current.style.transformOrigin = 'bottom left';
            spanRef.current.style.display = 'inline-block';
            spanRef.current.style.whiteSpace = 'nowrap';
        }
    }, []);

    return <span ref={spanRef}>{text}</span>;
};

const incomeChart = ({ title, value, label }) => {

    const series = [
        {
            name: '',
            data: value,
        }
    ]
    const options = {
        colors: ["#3c4ccf", "#f0f1f4"],
        chart: {
            stacked: true,
            toolbar: {
                show: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toLocaleString()
            },
        },
        plotOptions: {
            bar: {
                // columnWidth: "40%",
            },
        },
        grid: {
            borderColor: "#f8f8fa",
            row: {
                colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: title,
            labels: {
                formatter: function (val) {
                    return val
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            title: {
                text: 'Years',
            },
        },
        yaxis: {
            title: {
                text: label == "Revenue Growth" ? 'Turnover' : 'Percentage',
            },
        },
        tooltip: {
            y: {
                formatter: function (val, opt) {

                    let per = undefined;
                    var temp = [0.0];
                    if (opt?.series?.length)
                        if (opt?.series[0]?.length > 1) {
                            let value = opt?.series[0];
                            if (value[0])
                                per = ((value[1] - value[0]) * 100) / value[1]
                            else
                                per = 0;
                            temp.push(per)
                            if (value?.length > 2) {
                                if (value[1])
                                    per = ((value[2] - value[1])) / value[1]
                                else
                                    per = 0;
                                temp.push(per)
                            }
                            if (value?.length > 3) {
                                if (value[2])
                                    per = ((value[3] - value[2])) / value[2]
                                else
                                    per = 0;
                                temp.push(per)
                            }
                            if (value?.length > 4) {
                                if (value[3])
                                    per = ((value[4] - value[3])) / value[3]
                                else
                                    per = 0;
                                temp.push(per)
                            }
                        }
                    // console.log('opt', opt, temp)
                    return `${val} (${temp[opt?.dataPointIndex].toFixed(2)}%)`
                },
            },
            x: {
                formatter: function (val) {
                    // console.log('value tooltips', val)
                    return val
                },
            },
        },
        fill: {
            opacity: 1,
        },

        legend: {
            show: false,
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
    }



    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="410"
            />
        </React.Fragment>
    )
}

export default incomeChart
