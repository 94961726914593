import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import IncomestatementanalysisChart from "../../../pages/AllCharts/chartjs/incomeChart";
import BarApexChart from "../../../pages/AllCharts/apex/profitabilityChart";
import { get } from 'helpers/helper_api';

const dataAnlysis = [
    {
        title: 'Revenue Growth',
        ratio: 2.5,
        desc: "Revenue Growth Desc"
    },
    {
        title: 'Gross Margin',
        ratio: 2.5,
        desc: "Gross Margin Desc"
    },
    {
        title: 'Operating Expense',
        ratio: 2.5,
        desc: "Operating Expense Desc"
    },
    {
        title: 'EBITDA Margin',
        ratio: 2.5,
        desc: "EBITDA Margin Desc"
    },
    {
        title: 'Operating Profit Margin',
        ratio: 2.5,
        desc: "Operating Profit Margin Desc"
    },
    {
        title: 'Return On Assets',
        ratio: 2.5,
        desc: "Return On Assets Desc"
    },
    {
        title: 'Return On Equity',
        ratio: 2.5,
        desc: "Return On Equity Desc"
    }
]

function Profitability({ userId, user }) {

    const { t } = useTranslation();
    const [years, setYears] = useState('');
    const [ratioTab, setRatioTab] = useState([]);
    const [ratioYears, setRatioYears] = useState([]);
    const [ratioCurrentData, setRatioCurrentData] = useState(null);
    const [graphTitle, setGraphTitle] = useState(undefined);
    const [graphValue, setGraphValue] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [value, setValue] = useState(0);
    const [currentGraphTab, setCurrentGraphTab] = useState(0);

    useEffect(() => {
        getData();
    }, [user, years])

    const getData = () => {
        let body = {
            token: user?.token,
        }
        let url = `/ratio/profit?userId=${userId}`;
        if (years)
            url = `/ratio/profit?year=${years}&userId=${userId}`
        get(url, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    // console.log('ration res', res?.data)
                    if (res?.data) {
                        if (res?.data?.years?.length) {
                            if (years == '') {
                                setYears(res?.data?.years[0])
                            }
                            let yearsArr = res?.data?.years?.reverse();
                            setRatioYears(yearsArr)
                        }
                        let temp = [];
                        res?.data?.allGraprh?.map((item) => (
                            temp.push(item?.title)
                        ))
                        // console.log('ration temp', temp)
                        setRatioTab(res?.data?.loop)
                        if (res?.data?.loop?.length)
                            res?.data?.loop?.forEach((item) => {
                                dataAnlysis?.forEach((dat) => {
                                    if (dat?.title == item?.title)
                                        item.desc = dat.desc;
                                })
                            })
                        setRatioCurrentData(res?.data?.loop[0]);
                        setCurrentGraphTab(0);
                        if (res?.data?.loop?.length > 0) {
                            let graphT = [];
                            res?.data?.loop[0]?.graph.map((item) => (
                                graphT.push(item?.title)
                            ))
                            setGraphTitle(graphT?.reverse())
                            let graphV = [];
                            res?.data?.loop[0]?.graph.map((item) => (
                                graphV.push(item?.value)
                            ))
                            setGraphValue(graphV?.reverse())
                        }
                        setTitle(temp);
                        let temp1 = [];
                        res?.data?.allGraprh?.map((item) => (
                            temp1.push(item?.value)
                        ))
                        setValue(temp1);
                    } else {
                        console.error('Data is undefined in the response');
                    }
                }
            }).catch((err) => {
                console.log('err', err)
            })
    }

    return (
        <Col sm="12">
            <Card>
                <CardBody>
                    <h6 className='explain-text mb-4'><strong><span>{t("profit_chat_label")} : {years}</span></strong></h6>
                    {ratioTab?.length > 0 &&
                        <div className='top_ratio mb-4'>
                            <ul className='ratio_list'>
                                {
                                    ratioTab?.map((item, index) => (
                                        <li className={index == currentGraphTab ? 'active' : null} onClick={() => changeTab(item, index)}>
                                            <h3>{t(item?.title)}</h3>
                                            <h1>{Number(item?.value).toLocaleString()} {t(item?.valueType)}</h1>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    }
                    <p className='explain-text mb-4'><span>{t(ratioCurrentData?.title)} :</span> {t(ratioCurrentData?.desc)}</p>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <h4 className='text-center mb-3 grap_heading'>{t(ratioCurrentData?.title)}</h4>
                                    <div className='year_chart'>
                                        {
                                            graphTitle && graphValue && <IncomestatementanalysisChart label={ratioCurrentData?.title} title={graphTitle} value={graphValue} />
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <h4 className='text-center mb-3 grap_heading'>{t("Profitability Ratios")}</h4>
                                    <div className='ratio_year_tabs'>
                                        <ul>
                                            {
                                                ratioYears?.map((item, index) => (
                                                    <li>
                                                        <button onClick={() => setYears(item)} className={item == years ? 'active' : null}>{item}</button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className='year_chart mt-3'>
                                        {
                                            title ? <BarApexChart t={t} data={title} value={value} /> : null
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default Profitability