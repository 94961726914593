import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    CardBody,
    Media,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import "../Tables/datatables.scss"
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import avatar1 from "../../assets/images/users/user-1.jpg";
import Loader from 'components/Loader';
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import DeleteModal from 'components/DeleteModal';
import StateJson from '../../assets/state.json';
import { CSVLink } from 'react-csv';

const Sme = (props) => {
    let history = useHistory();
    let { user } = props;
    const [selectedSector, setSelectedSector] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [selectedDevelopGoal, setSelectedDevelopGoals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { t, i18n } = useTranslation();
    const [searchStr, setSearchStr] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [profileData, setProfileData] = useState();
    const businessSector = Utils.getBusinessSector();
    const [weight, setWeight] = useState([]);
    const [country, setCountry] = useState([]);
    const [country1, setCountry1] = useState('');
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: t("List of SMEs"), link: "#" },
    ]
    const sdgOptions = Utils.getSDG();

    useEffect(() => {
        props.setBreadcrumbItems(t("List of SMEs"), breadcrumbItems)
    }, []);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user, searchStr]);

    useEffect(() => {

        const africaCountries = StateJson.filter(x => x.region === "Africa");

        // console.log('country', africaCountries);
        let temp0 = [];
        africaCountries?.map((item) => {
            temp0.push({ name: item?.name, value: item?.name });
        })

        setCountry(temp0)
    }, []);

    const handleButtonClick = () => {
        // Close the dropdown
        setCountry1(null)
        // getData();
    };

    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            let sectors = [];
            selectedSector?.forEach((item) => {
                sectors.push(item?.value);
            })
            let goals = [];
            selectedDevelopGoal?.forEach((item) => {
                goals.push(item?.value);
            })
            let body = {
                role: "SME",
                search: searchStr,
                businessSector: sectors,
                developmentGoals: goals,
                country: country1,
                token: user?.token
            }
            post("/user/list", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.error('While getting SME list', err);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    useEffect(() => {
        if (user?.token) {

            get('/weight/list', { token: user?.token }).
                then((res) => {
                    if (res?.statusCode == 200) {
                        setWeight(res?.data);
                    }
                })
        }
    }, [user])

    const calculateRating = (row) => {
        let total = 0;
        if (row?.profRating?.rating) {
            let per = Number(weight?.profitAndLoss) / 100;
            total = total + (Number(row?.profRating?.rating) * per);

        }

        if (row?.esgRating?.rating) {
            let per = Number(weight?.eSG) / 100;
            total = total + (Number(row?.esgRating?.rating) * per)

        }
        if (row?.financeRating?.rating) {
            let per = Number(weight?.financingRequest) / 100;
            total = total + (Number(row?.financeRating?.rating) * per)

        }
        if (row?.cashFlowRating?.rating) {
            let per = Number(weight?.cashFlow) / 100;
            total = total + (Number(row?.cashFlowRating?.rating) * per)

        }
        if (row?.balanceRating?.rating) {
            let per = Number(weight?.balanceSheet) / 100;
            total = total + (Number(row?.balanceRating?.rating) * per)

        }
        if (row?.nuroRating?.rating) {
            let per = Number(weight?.nuroProfiler) / 100;
            total = total + (Number(row?.nuroRating?.rating) * per)

        }

        if (row?.ratioRating?.rating) {
            let per = Number(weight?.ratio) / 100;
            total = total + (Number(row?.ratioRating?.rating) * per)

        }
        return total;

    }

    useEffect(() => {
        setColumns([
            {
                name: t("Username"),
                selector: (row, index) => <button className='number_btn' style={{ backgroundColor: 'transparent' }} onClick={() => history.push('/search-details', { userId: row?._id })} title={row?.username}>{row?.username}</button>,
                maxWidth: '130px',
                minWidth: '130px'
            },
            {
                name: t("Company Name"),
                selector: row => row.companyName,
                maxWidth: '200px'
            },
            {
                name: t("Sector"),
                selector: row => t(row.businessSector),
            },
            {
                name: t("Geography"),
                selector: row => row.businessCity,
                maxWidth: '150px'
            },
            {
                name: t("Email"),
                selector: row => row?.email,
            },
            {
                name: t("Rating"),
                selector: row => <span>{calculateRating(row)}</span>,
                maxWidth: "100px"
            },
            {
                name: t('Status'),
                selector: row => <span>
                    {
                        row?.approveStatus == 'Pending' &&
                        <p className='status_check mt-0' style={{ backgroundColor: 'skyblue', color: '#fff' }} >{t("Pending")}</p>
                    }
                    {
                        row?.approveStatus == 'Submitted' &&
                        <p className='status_check mt-0' style={{ backgroundColor: 'orange', color: '#fff' }} >{t("New Request")}</p>
                    }
                    {
                        row?.approveStatus == 'In Progress' &&
                        <p className='status_check mt-0' style={{ backgroundColor: 'blue', color: '#fff' }} >{t("In Progress")}</p>
                    }
                    {
                        row?.approveStatus == 'Accepted' &&
                        <p className='status_check mt-0' style={{ backgroundColor: 'green', color: '#fff' }}>{t("Accepted")}</p>
                    }
                    {
                        row?.approveStatus == 'Rejected' &&
                        <p className='status_check mt-0' style={{ backgroundColor: 'red', color: '#fff' }}>{t("Rejected")}</p>
                    }
                </span>,
                maxWidth: '125px'
            },
            {
                name: 'Action',
                selector: row =>
                    <div className='action_box'>
                        <button onClick={() => { handleShow(); setProfileData(row) }} ><i class="mdi mdi-account" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Profile details")} ></i></button>
                        <button onClick={() => history.push('/search-details', { userId: row?._id })}><i class="mdi mdi-eye" data-bs-toggle="tooltip" data-bs-placement="top" title={t("More Details")}></i></button>
                        {/* <button ><i class="mdi mdi-heart" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Company"></i></button> */}
                    </div>,
                maxWidth: '120px'
            },
        ]);
    }, [data, i18n.language, weight]);

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }
    // console.log('profileData', profileData)

    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;

    const handleStatusChange = (row) => {
        setCurrentData({ ...row, actionType: 'Approve' });
        setDeleteModal(true);
    }

    const onStatus = () => {
        if (!loading) {
            setLoading(true)
            const body = {
                token: user?.token,
                isApproved: !currentData?.isApproved,
                userId: currentData?._id
            }
            put('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res?.data?.isApproved);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        setDeleteModal(false);
                        setCurrentData(null);
                        getData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const exportToCSV = () => {
        const csvData = data.map((row) => ({
            Username: row?.username,
            Company_Name: row?.companyName,
            Sector: row?.businessSector,
            Geography: row?.businessCity,
            Email: row?.email,
            Rating: calculateRating(row),
            Status: row?.approveStatus
        }));
        return csvData;
    };

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={onStatus}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <Modal centered show={profileModal} className='model_profile' >
                <Modal.Header onHide={handleClose} closeButton>
                    <Modal.Title>{t("COMPANY PROFILE")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='profile_d'>
                        <Media className="mb-4">
                            <img
                                className="d-flex me-3 rounded-circle avatar-sm"
                                src={avatar1}
                                alt="lexa"
                            />
                            <Media body className="align-self-center">
                                <h4 className="font-size-18 m-0">
                                    {profileData?.fname}  {profileData?.lname}
                                </h4>
                                <small className="text-muted">{profileData?.email}</small>
                            </Media>
                        </Media>
                        <ul className='profile_details_box'>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>{t("Phone Number")}</strong> {profileData?.mobile}
                                </h4>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>{t("Company Name")}</strong> {profileData?.companyName}
                                </h4>
                            </li>
                            {/* <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Geography</strong> {profileData?.businessCity}
                                </h4>
                            </li> */}
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>{t("Business Sector")}</strong> {profileData?.businessSector}
                                </h4>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>{t("Company Address")}</strong> {profileData?.businessAddress}
                                </h4>
                            </li>
                        </ul>

                        {/* <hr /> */}
                    </div>
                </Modal.Body>

            </Modal>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            <div className='investor_component'>
                                <div className='search_section'>
                                    <Row>
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <button className='apply_btn_b'>
                                                <CSVLink
                                                    data={exportToCSV(data)}
                                                    filename={"SME.csv"}
                                                    className="text-white"
                                                    target="_blank"
                                                >
                                                    {t("Export as CSV")}
                                                </CSVLink>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='search_section'>
                                    <h3 className='Filter'>Filter <i class="mdi mdi-filter-variant"></i></h3>
                                    <AvForm>
                                        <Row>
                                            <Col className='p_0px' lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                    {/* <label>{t("Sector/Industry supported")}</label> */}
                                                    <MultiSelect
                                                        options={businessSector} // Options to display in the dropdown
                                                        hasSelectAll={true}
                                                        value={selectedSector}
                                                        onChange={setSelectedSector}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Sector..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            {/* <Col lg={2} md={4}>
                                                <div className="form-group mb-3">
                                                    <MultiSelect
                                                        options={regionoptions}
                                                        hasSelectAll={true}
                                                        value={selectedRegion}
                                                        onChange={setSelectedRegion}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Region..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}

                                                    />
                                                </div>
                                            </Col> */}
                                            <Col className='p_0px' lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                    {/* <label>{t("Investment Region in Africa")}</label> */}
                                                    <MultiSelect
                                                        options={sdgOptions} // Options to display in the dropdown
                                                        hasSelectAll={true}
                                                        value={selectedDevelopGoal}
                                                        onChange={setSelectedDevelopGoals}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select SDG..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='p_0px' lg={3} md={4}>
                                                <div className="form-group form_group_search mb-3" style={{ position: 'relative' }}>
                                                    <button className='close_drop_down' onClick={handleButtonClick}>X</button>
                                                    <SelectSearch options={country} value={country1} search={true} autocomplete="off" onChange={(e) => setCountry1(e)} name="businessState" placeholder={i18n?.language == 'en' ? 'Choose your Country':'Choisissez votre pays'} />

                                                </div>
                                            </Col>
                                            <Col className='p_0px' lg={1} md={1}>
                                                <button className='apply_btn_b' onClick={getData}>{t("Apply")}</button>
                                            </Col>
                                            <Col className='p_0px' lg={2} md={5}>
                                                <AvField type="text" name="Search" placeholder={t("Search")} value={searchStr} onChange={(e => setSearchStr(e.target.value))} />
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    // expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    // fixedHeader
                                    // fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const user = state.Profile.success;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Sme)
)