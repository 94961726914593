import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table,
    CardTitle,
    UncontrolledTooltip
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import avatar1 from "../../assets/images/users/user-1.jpg"
const SelectedCompanies = (props) => {
    let history = useHistory();
    const [businessStructure, setBusinessStructure] = useState([]);
    const [region, setRegion] = useState([]);
    const [profileModal, setProfileModal] = useState(false);
    const [sdg, setSdg] = useState([]);
    const handleClose = () => setProfileModal(false);
    const handleShow = () => setProfileModal(true);
    const { t } = useTranslation();
    const BusinessSector = [
        { label: t("Agriculture, Plantations, Other rural sectors"), value: t("Agriculture, Plantations, Other rural sectors") },
        { label: t("Basic Metal Production"), value: t("Basic Metal Production") },
        { label: t("Business & Professional services"), value: t("Business & Professional services") },
        { label: t("Chemical industries"), value: t("Chemical industries") },
        { label: t("Construction, Building Materials"), value: t("Construction, Building Materials") },
        { label: t("Education"), value: t("Education") },
        { label: t("Financial services"), value: t("Financial services") },
        { label: t("Food and drink industries"), value: t("Food and drink industries") },
        { label: t("Forestry, Wood, Pulp and paper"), value: t("Forestry, Wood, Pulp and paper") },
        { label: t("Health services"), value: t("Health services") },
        { label: t("Hotels, Tourism, Catering"), value: t("Hotels, Tourism, Catering") },
        { label: t("IT, Telecommunications services"), value: t("IT, Telecommunications services") },
        { label: t("Mechanical and electrical engineering"), value: t("Mechanical and electrical engineering") },
        { label: t("Mining (coal, other mining)"), value: t("Mining (coal, other mining)") },
        { label: t("Oil and gas production, Oil refining"), value: t("Oil and gas production, Oil refining") },
        { label: t("Pharmaceuticals"), value: t("Pharmaceuticals") },
        { label: t("Public services"), value: t("Public services") },
        { label: t("Publishing, Communication, Multimedia"), value: t("Publishing, Communication, Multimedia") },
        { label: t("Technology"), value: t("Technology") },
        { label: t("Textile, Clothing,Footwear"), value: t("Textile, Clothing,Footwear") },
        { label: t("Trade, Trading, Distribution"), value: t("Trade, Trading, Distribution") },
        { label: t("Transport, Logistics,Mobility"), value: t("Transport, Logistics,Mobility") },
        { label: t("Utilities (water, gas, electricity)"), value: t("Utilities (water, gas, electricity)") },
    ]
    const regionoptions = [
        { label: t("Western Africa"), value: t("Western Africa") },
        { label: t("Eastern Africa"), value: t("Eastern Africa") },
        { label: t("Northern Africa"), value: t("Northern Africa") },
        { label: t("Southern Africa"), value: t("Southern Africa") },
        { label: t("Central Africa"), value: t("Central Africa") },
    ]
    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Search Companies", link: "#" },
    ]
    const options = [

        { label: t("SDG 1 - No poverty"), value: t("SDG 1 - No poverty") },
        { label: t("SDG 2 - Zero hunger"), value: t("SDG 2 - Zero hunger") },
        { label: t("SDG 3 - Good health and well-being"), value: t("SDG 3 - Good health and well-being") },
        { label: t("SDG 4 - Quality education"), value: t("SDG 4 - Quality education") },
        { label: t("SDG 5 - Gender equality"), value: t("SDG 5 - Gender equality") },
        { label: t("SDG 6 - Clean water and sanitation"), value: t("SDG 6 - Clean water and sanitation") },
        { label: t("SDG 7 - Affordable and clean energy"), value: t("SDG 7 - Affordable and clean energy") },
        { label: t("SDG 8 - Decent work and economic growth"), value: t("SDG 8 - Decent work and economic growth") },
        { label: t("SDG 9 - Industry innovation and infrastructure"), value: t("SDG 9 - Industry innovation and infrastructure") },
        { label: t("SDG 10 - Reduced inequalities"), value: t("SDG 10 - Reduced inequalities") },
        { label: t("SDG 11 - Sustainable cities and communities"), value: t("SDG 11 - Sustainable cities and communities") },
        { label: t("SDG 12 - Responsible consumption and production"), value: t("SDG 12 - Responsible consumption and production") },
        { label: t("SDG 13 - Climate action"), value: t("SDG 13 - Climate action") },
        { label: t("SDG 14 - Life below water"), value: t("SDG 14 - Life below water") },
        { label: t("SDG 15 - Life on land"), value: t("SDG 15 - Life on land") },
        { label: t("SDG 16 - Peace, justice and strong institutions"), value: t("SDG 16 - Peace, justice and strong institutions") },
        { label: t("SDG 17 - Partnership for the goals"), value: t("SDG 17 - Partnership for the goals") },
    ];
    useEffect(() => {
        props.setBreadcrumbItems('Search Companies', breadcrumbItems)
    })

    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    const columns = [
        {
            name: 'S.No',
            selector: row => row.id,
        },
        {
            name: 'Company Name',
            selector: row => row.company_name,
        },
        {
            name: 'Sector',
            selector: row => row.sector,
        },
        {
            name: 'Geography',
            selector: row => row.geography,
        },
        {
            name: 'Address',
            selector: row => row.address,
        },
        {
            name: 'Action',
            selector: row =>
                <div className='action_box'>

                    <button onClick={handleShow} >
                        <i class="mdi mdi-account" data-bs-toggle="tooltip" data-bs-placement="top" title="Profile details" onClick={ExpandedComponent}></i>
                    </button>
                    <button onClick={() => history.push('/search-details')}><i class="mdi mdi-eye" data-bs-toggle="tooltip" data-bs-placement="top" title="Details"></i></button>
                    <button ><i class="mdi mdi-delete" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove Company"></i></button>
                </div>,

        },
    ];

    const data = [
        { id: 1, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 2, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 3, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 4, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 5, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 6, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 7, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
        { id: 8, company_name: 'ABC PVT.LTD', sector: 'ABC India', geography: 'Delhi', address: 'Address' },
    ]

    const ExpandedComponent = ({ data }) =>
        <div className='search_expended_component'>
            <h3>Profile</h3>
        </div>;

    return (
        <React.Fragment>

            <MetaTags>
                <title>Profit and Loss account | Demleen </title>
            </MetaTags>
            <Modal centered show={profileModal} className='model_profile' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Company Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='profile_d'>
                        <Media className="mb-4">
                            <img
                                className="d-flex me-3 rounded-circle avatar-sm"
                                src={avatar1}
                                alt="lexa"
                            />
                            <Media body className="align-self-center">
                                <h4 className="font-size-18 m-0">
                                    ABC PVT.LTD
                                </h4>
                                <small className="text-muted">support@d.....</small>
                            </Media>
                        </Media>
                        <ul className='profile_details_box'>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Phone Number</strong> 9889xxxxxx
                                </h4>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Geography</strong> ABC Africa
                                </h4>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Sector</strong> ABC Africa
                                </h4>
                            </li>
                            <li>
                                <h4 className="mt-0 font-size-16">
                                    <strong>Company Address</strong> Africa
                                </h4>
                            </li>
                        </ul>

                        {/* <hr /> */}
                    </div>
                </Modal.Body>

            </Modal>

            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody >

                            <div className='investor_component'>
                                {/* <div className='search_section'>
                                    <h3 className='Filter'>Filter <i class="mdi mdi-filter-variant"></i></h3>
                                    <AvForm>
                                        <Row>
                                            <Col lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                    
                                                    <MultiSelect
                                                        options={BusinessSector} 
                                                        hasSelectAll={true}
                                                        value={businessStructure}
                                                        onChange={setBusinessStructure}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Sector..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}

                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4}>
                                                <div className="form-group mb-3">
                                                  
                                                    <MultiSelect
                                                        options={regionoptions} 
                                                        hasSelectAll={true}
                                                        value={region}
                                                        onChange={setRegion}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select Region..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}

                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3} md={4}>
                                                <div className="form-group mb-3">
                                                   
                                                    <MultiSelect
                                                        options={options} 
                                                        hasSelectAll={true}
                                                        value={sdg}
                                                        onChange={sdgFun}
                                                        overrideStrings={{
                                                            selectSomeItems: t("Select SDG..."),
                                                            allItemsAreSelected: t("All Items are Selected"),
                                                            selectAll: t("Select All"),
                                                            search: t("Search"),
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={1} md={1}>
                                                <button className='apply_btn_b'>Apply</button>
                                            </Col>
                                            <Col lg={3} md={5}>
                                                <AvField type="text" name="Search" placeholder="Search..." />
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </div> */}
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    // expandableRows={true}
                                    expandOnRowClicked={true}
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ExpandedComponent}
                                    expandableRowsComponentProps={row => row.Action}
                                    direction="ltr"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap

                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag, setBreadcrumbItems })(SelectedCompanies)
)