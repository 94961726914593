import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user-1.jpg";
import { editProfile, resetProfileFlag, profileSuccess } from "../../store/actions";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import * as Utils from "../Utils";
import "react-step-progress-bar/styles.css";
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import moment from 'moment';
import Loader from 'components/Loader';
const ProfileData = props => {
    let { user } = props;
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { i18n, t } = useTranslation();
    // console.log('profile page', props?.user)
    const { role } = props?.user;
    const [selectedSdg, setSelectedSdg] = useState([]);
    const [sdg, setSdg] = useState([]);
    const [region, setRegion] = useState([]);
    const [investmentRegion, setInvestmentRegion] = useState([]);
    const [investment, seInvestment] = useState([]);
    const [businessStructure, setBusinessStructure] = useState([]);
    const [lang, setLang] = useState(null);

    const [data, setData] = useState(null);
    const [age, setAge] = useState(null);
    const [userID, setUserID] = useState(null);
    const [alertType, setAlerType] = useState("");
    const [file, setFile] = useState(avatar);
    const [file1, setFile1] = useState(avatar);
    const [changeIcon, setChangeIcon] = useState(false);
    const [changeIcon1, setChangeIcon1] = useState(false);
    const [progress, setProgress] = useState(45)
    const [member, setMember] = useState([{}])
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const options = [

        { label: t("SDG 1 - No poverty"), value: t("SDG 1 - No poverty") },
        { label: t("SDG 2 - Zero hunger"), value: t("SDG 2 - Zero hunger") },
        { label: t("SDG 3 - Good health and well-being"), value: t("SDG 3 - Good health and well-being") },
        { label: t("SDG 4 - Quality education"), value: t("SDG 4 - Quality education") },
        { label: t("SDG 5 - Gender equality"), value: t("SDG 5 - Gender equality") },
        { label: t("SDG 6 - Clean water and sanitation"), value: t("SDG 6 - Clean water and sanitation") },
        { label: t("SDG 7 - Affordable and clean energy"), value: t("SDG 7 - Affordable and clean energy") },
        { label: t("SDG 8 - Decent work and economic growth"), value: t("SDG 8 - Decent work and economic growth") },
        { label: t("SDG 9 - Industry innovation and infrastructure"), value: t("SDG 9 - Industry innovation and infrastructure") },
        { label: t("SDG 10 - Reduced inequalities"), value: t("SDG 10 - Reduced inequalities") },
        { label: t("SDG 11 - Sustainable cities and communities"), value: t("SDG 11 - Sustainable cities and communities") },
        { label: t("SDG 12 - Responsible consumption and production"), value: t("SDG 12 - Responsible consumption and production") },
        { label: t("SDG 13 - Climate action"), value: t("SDG 13 - Climate action") },
        { label: t("SDG 14 - Life below water"), value: t("SDG 14 - Life below water") },
        { label: t("SDG 15 - Life on land"), value: t("SDG 15 - Life on land") },
        { label: t("SDG 16 - Peace, justice and strong institutions"), value: t("SDG 16 - Peace, justice and strong institutions") },
        { label: t("SDG 17 - Partnership for the goals"), value: t("SDG 17 - Partnership for the goals") },
    ];

    const businessSector = Utils.getBusinessSector();

    const sdgOptions = Utils.getSDG();

    const regionoptions = [
        { label: t("Western Africa"), value: t("Western Africa") },
        { label: t("Eastern Africa"), value: t("Eastern Africa") },
        { label: t("Northern Africa"), value: t("Northern Africa") },
        { label: t("Southern Africa"), value: t("Southern Africa") },
        { label: t("Central Africa"), value: t("Central Africa") },
    ]

    const investmentoptions = [
        { label: t("Equity"), value: t("Equity") },
        { label: t("Debt / Loans"), value: t("Debt / Loans") },
        { label: t("Guarantees"), value: t("Guarantees") },
        { label: t("Grants"), value: t("Grants") },
    ]

    const Business_Structure = [
        { label: t("Non-Profit"), value: t("Non-Profit") },
        { label: t("Cooperative"), value: t("Cooperative") },
        { label: t("For-Profit (sole)"), value: t("For-Profit (sole)") },
        { label: t("For Profit (one-person limited liability undertaking)"), value: t("For Profit (one-person limited liability undertaking)") },
        { label: t("For Profit (limited liability corporation)"), value: t("For Profit (limited liability corporation)") },
        { label: t("For Profit (simplified joint stock company)"), value: t("For Profit (simplified joint stock company)") },
        { label: t("For-Profit (partnership)"), value: t("For-Profit (partnership)") },
        { label: t("For-Profit (limited partnership)"), value: t("For-Profit (limited partnership)") },
    ]

    useEffect(() => {
        if (user?.token)
            getUserData();
    }, [user])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        if (currentLanguage)
            setLang(currentLanguage)
    }, [lang])


    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: props?.userId,
                token: user?.token

            }
            post('/user', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // console.log('request on login', res);

                        setData(res?.data)

                        setMember(res?.data?.member)
                        if (res?.data?.isEmailVerified == true) {
                            setProgress(70)
                            if (res?.data?.businessDesc) {
                                setProgress(100)
                            }
                        }

                        setAge(res?.data?.age)
                        let temp0 = [];
                        res?.data?.businessGoals?.map((item) => {
                            temp0.push({ label: item, value: item });
                        })
                        setRegion(temp0)
                        let temp = [];
                        res?.data?.investmentRegion?.map((item) => {
                            temp.push({ label: item, value: item });
                        })
                        setInvestmentRegion(temp);
                        let temp1 = [];
                        res?.data?.investmentInstrument?.map((item) => {
                            temp1.push({ label: item, value: item });
                        })
                        seInvestment(temp1)
                        let temp2 = [];
                        res?.data?.developmentGoals?.map((item) => {
                            temp2.push({ label: item, value: item });
                        })
                        setSdg(temp2)
                        let temp3 = [];
                        res?.data?.orgSector?.map((item) => {
                            temp3.push({ label: item, value: item });
                        })
                        setBusinessStructure(temp3)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }


    const sdgFun = (e) => {
        setSdg(e)
        console.log(e)
    }

    const getAge = (e) => {
        let userDateinput = e.target.value;
        let birthDate = new Date(userDateinput);
        // console.log(" birthDate"+ birthDate);

        // get difference from current date;
        let difference = Date.now() - birthDate.getTime();

        let ageDate = new Date(difference);
        // console.log(" ageDate"+ ageDate);
        let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        // console.log(" calculatedAge", calculatedAge);
        setAge(calculatedAge)
    }



    const getFile = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj)
            setChangeIcon(true)
        setFile(URL.createObjectURL(fileObj))
        console.log('fileObj is', fileObj?.name);
    }
    const getFile1 = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj)
            setChangeIcon1(true)
        setFile1(URL.createObjectURL(fileObj))
        console.log('fileObj is', fileObj?.name);
    }

    console.log('user data', data)


    const deleteBox = (index) => {
        console.log('index', index)
        let copyArr = Object.assign([], member);
        copyArr.splice(index, 1);
        console.log('copyArr', copyArr)
        setMember(copyArr);
    }

    const addMemberData = (v, i, k) => {
        let temp = Object.assign([], member);
        temp[i][k] = v;
        setMember(temp)
    }
    console.log('member', member)


    // useEffect(() => {
    //     if (props?.user?.token)
    //         getUserData1();
    // }, [props?.user?.token])

    // const getUserData1 = () => {
    //     let body = {
    //         userId: props?.userId,
    //         token: props?.user?.token,

    //     }
    //     post('/user', body)
    //         .then((res => {
    //             // setLoading(false);
    //             if (res?.statusCode == 200) {
    //                 console.log('request on login', res);

    //                 setData(res?.data)
    //                 setValue(res?.data?.profRating?.rating)
    //             } else {
    //                 console.log(res?.error);
    //             }
    //         }))
    //         .catch(error => {
    //             // setLoading(false);
    //             console.log('error while login', error);
    //             console.log('Something went wrong');
    //         })
    // }



    return (
        <React.Fragment>
            <Loader loading={loading} />
            <Card>
                <CardBody>
                    <Row>
                        <Col lg="4">
                            <Media>
                                <div className="ms-3 profile_img_pick">
                                    <img
                                        src={file}
                                        alt=""
                                        className="avatar-lg rounded-circle img-thumbnail"
                                    />


                                </div>
                                <Media body className="align-self-center px-4">
                                    <div className="text-muted">
                                        <h5 className='mb-1'>{data?.fname} {data?.lname}</h5>
                                        <p className="mb-1">{data?.email}</p>
                                        <p className="mb-0">{data?.gender}</p>
                                    </div>
                                </Media>
                            </Media>
                        </Col>

                        <Col lg="8">
                            <div className='profile_progress_section'>
                                <ProgressBar
                                    percent={progress}
                                    filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                >
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className='progress_bar_section'>
                                                <div className='step_icon'>
                                                    {
                                                        accomplished ? <i className="mdi mdi-check"></i> : <p>1</p>
                                                    }

                                                </div>
                                                <span>Signup</span>
                                            </div>
                                        )}
                                    </Step>
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className='progress_bar_section'>
                                                <div className='step_icon'>
                                                    {
                                                        accomplished ? <i className="mdi mdi-check"></i> : <p>2</p>
                                                    }
                                                </div>
                                                <span>Login</span>
                                            </div>
                                        )}
                                    </Step>
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className='progress_bar_section'>
                                                <div className='step_icon'>
                                                    {
                                                        accomplished ? <i className="mdi mdi-check"></i> : <p>3</p>
                                                    }
                                                </div>
                                                <span>Email Verification</span>
                                            </div>
                                        )}
                                    </Step>
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className='progress_bar_section'>
                                                <div className='step_icon'>
                                                    {
                                                        accomplished ? <i className="mdi mdi-check"></i> : <p>4</p>
                                                    }
                                                </div>
                                                <span>Profile Completion</span>
                                            </div>
                                        )}
                                    </Step>
                                    {/* <Step transition="scale">
                    {({ accomplished }) => (
                      <div className='progress_bar_section'>
                        <div className='step_icon'>
                          {
                            accomplished ? <i className="mdi mdi-check"></i> : <p>5</p>
                          }
                        </div>
                        <span>Profile Activation </span>
                      </div>
                    )}
                  </Step> */}
                                </ProgressBar>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>


            <AvForm
                className="form-horizontal form_horizontal_admin"

            >
                <h4 className="card-title mb-4">{t("Personal Details")}</h4>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="fname"
                                        label={t("First Name")}
                                        value={data?.fname}
                                        className="form-control"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="lname"
                                        label={t("Last Name")}
                                        value={data?.lname}
                                        className="form-control"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="email"
                                        value={data?.email}
                                        label={t("Email Address")}
                                        className="form-control"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="mobile"
                                        value={data?.mobile}
                                        label={t("Phone Number")}
                                        className="form-control"
                                        type="number"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="dob"
                                        label={t("Date of Birth")}
                                        value={data?.dob}
                                        className="form-control"
                                        onChange={getAge}
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="age"
                                        label={t("Age")}
                                        className="form-control"
                                        value={age}
                                        type="number"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField type="text" name="designation" disabled value={data?.designation} label={t("Current Position At The Company*")} />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField type="text" name="gender" disabled value={data?.gender} label={t("Gender")} />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <h4 className="card-title mb-4">{t("Company Details")}</h4>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="3">
                                <div className='company_logo_card'>
                                    <div className="profile_img_pick">
                                        <img
                                            src={file1}
                                            alt=""
                                            className="avatar-md rounded-circle img-thumbnail"
                                        />


                                    </div>
                                    <p>Company Logo</p>
                                </div>

                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessName"
                                        value={data?.businessName}
                                        label={t("Business Legal Name")}
                                        className="form-control"
                                        // placeholder="Business Legal Name"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="companyName"
                                        label={t("Company Name")}
                                        value={data?.companyName}
                                        className="form-control"
                                        // placeholder="Company Name"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessStartDate"
                                        label={t("Business Start Date")}
                                        value={data?.businessStartDate?.split('T')[0]}
                                        className="form-control"
                                        // placeholder="Business Start Date"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessRegisterNo"
                                        value={data?.businessRegisterNo}
                                        label={t("Registration Number")}
                                        className="form-control"
                                        // placeholder="Registration Number"
                                        type="number"

                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessTaxId"
                                        value={data?.businessTaxId}
                                        label={t("Tax Id")}
                                        className="form-control"
                                        // placeholder="Tax Id"
                                        type="number"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessAddress"
                                        value={data?.businessAddress}
                                        label={t("Company Address")}
                                        className="form-control"
                                        // placeholder="Company Address"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessEmail"
                                        value={data?.businessEmail}
                                        label={t("Email Address")}
                                        className="form-control"
                                        // placeholder={t("Email Address")}
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessPhone"
                                        value={data?.businessPhone}
                                        label={t("Phone Number")}
                                        className="form-control"
                                        // placeholder={t("Phone Number")}
                                        type="number"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessState"
                                        value={data?.businessState}
                                        label={t("State")}
                                        className="form-control"
                                        // placeholder="State"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessCity"
                                        value={data?.businessCity}
                                        label={t("City")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={3} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessZipCode"
                                        value={data?.businessZipCode}
                                        label={t("Zip Code")}
                                        className="form-control"
                                        // placeholder="
                                        disabled
                                        type="number"
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <AvField type="text" name="businessStructure" disabled value={data?.businessStructure} label={t("Business Structure")} />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <AvField type="text" name="businessSector" disabled value={data?.businessSector} label={t("Business Sector")} />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessWebsite"
                                        value={data?.businessWebsite}
                                        label={t("Website")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessAccountPartner"
                                        value={data?.businessAccountPartner}
                                        label={t("External Accounting partner")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={8} md={6}>
                                <div className="form-group mb-3">

                                    <AvField
                                        name="businessAccountPartner"
                                        value={data?.developmentGoals}
                                        label={t("Related Sustainable Development Goals (3 max)")}
                                        className="form-control"
                                        // placeholder="City"
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="businessDesc"
                                        value={data?.businessDesc}
                                        label={t("Activity Description")}
                                        className="form-control"
                                        placeholder={t("Short Description")}
                                        type="text"
                                        disabled
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                {
                    role == 'Investor' &&
                    <>
                        <h4 className="card-title mb-4">{t("Your Organisation Preference Details")}</h4>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <label>{t("Supported Sustainable Development Goals")}</label>
                                            <MultiSelect
                                                options={options} // Options to display in the dropdown
                                                hasSelectAll={true}
                                                value={sdg}
                                                onChange={sdgFun}
                                                overrideStrings={{
                                                    selectSomeItems: t("Select Some items..."),
                                                    allItemsAreSelected: t("All Items are Selected"),
                                                    selectAll: t("Select All"),
                                                    search: t("Search"),
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <label>{t("Investment Region in Africa")}</label>
                                            <MultiSelect
                                                options={regionoptions} // Options to display in the dropdown
                                                hasSelectAll={true}
                                                value={investmentRegion}
                                                onChange={setInvestmentRegion}
                                                overrideStrings={{
                                                    selectSomeItems: t("Select Some items..."),
                                                    allItemsAreSelected: t("All Items are Selected"),
                                                    selectAll: t("Select All"),
                                                    search: t("Search"),
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <label>{t("Investment Instrument")}</label>
                                            <MultiSelect
                                                options={investmentoptions} // Options to display in the dropdown
                                                hasSelectAll={true}
                                                value={investment}
                                                onChange={seInvestment}
                                                overrideStrings={{
                                                    selectSomeItems: t("Select Some items..."),
                                                    allItemsAreSelected: t("All Items are Selected"),
                                                    selectAll: t("Select All"),
                                                    search: t("Search"),
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <label>{t("Sector/Industry supported")}</label>
                                            <MultiSelect
                                                options={businessSector} // Options to display in the dropdown
                                                hasSelectAll={true}
                                                value={businessStructure}
                                                onChange={setBusinessStructure}
                                                overrideStrings={{
                                                    selectSomeItems: t("Select Some items..."),
                                                    allItemsAreSelected: t("All Items are Selected"),
                                                    selectAll: t("Select All"),
                                                    search: t("Search"),
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        <label style={{ fontWeight: 'bold', fontSize: 20 }}>{t("Investment Characteristics")}</label>
                                        <div className="form-group d-flex justify_content_between mb-3">

                                            <AvField type="select" name="investmentCurrency" value={data?.investmentCurrency} label={t("Currency")} >
                                                <option>{t("Select")}</option>
                                                <option>EUR</option>
                                                <option>DOLLAR</option>
                                                <option>XOF/XAF - Franc CFA</option>
                                            </AvField>
                                            <AvField
                                                name="investmentAmount"
                                                label={t("Amount to Invest")}
                                                className="form-control"
                                                value={data?.investmentAmount}
                                                // placeholder="Amount to Invest"
                                                type="number"

                                            />
                                            <AvField type="select" name="investmentUnit" value={data?.investmentUnit} label={t("Unit")} >
                                                <option>{t("Select")}</option>
                                                <option>Million</option>
                                                <option>Billion</option>
                                                <option>Hundred Thousand</option>
                                            </AvField>

                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </>
                }
                <h4 className="card-title mb-4">{t("Management Team")}</h4>
                <Card>
                    <CardBody>
                        {
                            member?.map((item, index) => (
                                <Row>
                                    <Col lg={12}>
                                        <div className='card_hrader'>
                                            <h4>{t("Membre")} {index + 1}</h4>

                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'fname' + index}
                                                label={t("First Name")}
                                                onChange={(e) => addMemberData(e.target.value, index, 'fname')}
                                                className="form-control"
                                                type="text"
                                                value={item?.fname}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'lname' + index}
                                                label={t("Last Name")}
                                                onChange={(e) => addMemberData(e.target.value, index, 'lname')}
                                                className="form-control"
                                                type="text"
                                                value={item?.lname}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'email' + index}
                                                onChange={(e) => addMemberData(e.target.value, index, 'email')}
                                                label={t("Email Address")}
                                                className="form-control"
                                                type="text"
                                                value={item?.email}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'mobile' + index}
                                                onChange={(e) => addMemberData(e.target.value, index, 'mobile')}
                                                label={t("Phone Number")}
                                                className="form-control"
                                                type="number"
                                                value={item?.mobile}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'department' + index}
                                                label={t("Department")}
                                                onChange={(e) => addMemberData(e.target.value, index, 'department')}
                                                className="form-control"
                                                type="text"
                                                value={item?.department}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className="form-group mb-3">
                                            <AvField
                                                name={'designation' + index}
                                                label={t("Designation")}
                                                onChange={(e) => addMemberData(e.target.value, index, 'designation')}
                                                type="text"
                                                value={item?.designation}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        }

                    </CardBody>
                </Card>


            </AvForm>


        </React.Fragment>
    )
}



export default ProfileData;

