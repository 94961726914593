import PropTypes, { element } from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef, useId } from "react"
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
  CardTitle
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
// import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import "../Tables/datatables.scss"
import Spreadsheet from "react-spreadsheet";
import { get, post, put } from 'helpers/helper_api';
import Loader from 'components/Loader';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Utils from "../Utils";

const ProfitandLossReport1 = (props) => {
  const { user, userId } = props;
  const years = Utils.getYears();
  // console.log(user);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [year1, setYear1] = useState(undefined);
  const [year2, setYear2] = useState(undefined);
  const [year3, setYear3] = useState(undefined);
  const [value, setValue] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props?.proYear) {
      setYear1(props?.proYear?.year1);
      setYear2(props?.proYear?.year2);
      setYear3(props?.proYear?.year3);
    }
  }, [props?.proYear])

  // console.log('proYear ', props?.proYear)

  return (
    <React.Fragment>
      <Loader loading={loading} />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody >

              <div className='table-resp'>
                <table class="table normel_tabkle table-bordered" >
                  <thead style={{ backgroundColor: '#1b005e', color: '#fff' }}>
                    <tr className='col_header'>
                      <th scope="col">{t("Years")}</th>
                      <th className='text_right' style={{ width: '16.66666666666667%' }}>
                        {year1?.year}
                      </th>
                      <th className='text_right' style={{ width: '16.66666666666667%' }}>
                        {year2?.year}
                      </th>
                      <th className='text_right' style={{ width: '16.66666666666667%' }}>
                        {year3?.year}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Sales of goods for resale")}</td>
                      <td className='text_right'>{year1?.TA ? Number(year1?.TA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TA ? Number(year2?.TA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TA ? Number(year3?.TA).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Purchases of goods for resale")}</td>
                      <td className='text_right'>{year1?.RA ? Number(year1?.RA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RA ? Number(year2?.RA).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RA ? Number(year3?.RA).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of goods for resale")}</td>
                      <td className='text_right'>{year1?.RB ? Number(year1?.RB).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RB ? Number(year2?.RB).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RB ? Number(year3?.RB).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("GROSS MARGIN")}</th>
                      <th className='text_right' scope="col">{year1?.XA ? Number(year1?.XA).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XA ? Number(year2?.XA).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XA ? Number(year3?.XA).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Sales of manuf. goods, services, goods for resale")}</td>
                      <td className='text_right'>{year1?.TB ? Number(year1?.TB).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TB ? Number(year2?.TB).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TB ? Number(year3?.TB).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Works & Services")}</td>
                      <td className='text_right'>{year1?.TC ? Number(year1?.TC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TC ? Number(year2?.TC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TC ? Number(year3?.TC).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Ancillary income")}</td>
                      <td className='text_right'>{year1?.TD ? Number(year1?.TD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TD ? Number(year2?.TD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TD ? Number(year3?.TD).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TURNOVER")}</th>
                      <th className='text_right' scope="col">{year1?.XB ? Number(year1?.XB).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XB ? Number(year2?.XB).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XB ? Number(year3?.XB).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of finished goods, work in progress")}</td>
                      <td className='text_right'>{year1?.TE ? Number(year1?.TE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TE ? Number(year2?.TE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TE ? Number(year3?.TE).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Own work capitalized")}</td>
                      <td className='text_right'>{year1?.TF ? Number(year1?.TF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TF ? Number(year2?.TF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TF ? Number(year3?.TF).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Operating grants")}</td>
                      <td className='text_right'>{year1?.TG ? Number(year1?.TG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TG ? Number(year2?.TG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TG ? Number(year3?.TG).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Other operating income")}</td>
                      <td className='text_right'>{year1?.TH ? Number(year1?.TH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TH ? Number(year2?.TH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TH ? Number(year3?.TH).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Transfers of operating expenses")}</td>
                      <td className='text_right'>{year1?.TI ? Number(year1?.TI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TI ? Number(year2?.TI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TI ? Number(year3?.TI).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory purchases - Raw materials (and supplies)")}</td>
                      <td className='text_right'>{year1?.RC ? Number(year1?.RC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RC ? Number(year2?.RC).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RC ? Number(year3?.RC).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of raw materials (and supplies)")}</td>
                      <td className='text_right'>{year1?.RD ? Number(year1?.RD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RD ? Number(year2?.RD).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RD ? Number(year3?.RD).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory purchases - Other consumables")}</td>
                      <td className='text_right'>{year1?.RE ? Number(year1?.RE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RE ? Number(year2?.RE).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RE ? Number(year3?.RE).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Changes in inventory of other consumables")}</td>
                      <td className='text_right'>{year1?.RF ? Number(year1?.RF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RF ? Number(year2?.RF).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RF ? Number(year3?.RF).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Transport of goods and collective transport of employees")}</td>
                      <td className='text_right'>{year1?.RG ? Number(year1?.RG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RG ? Number(year2?.RG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RG ? Number(year3?.RG).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("External Services")}</td>
                      <td className='text_right'>{year1?.RH ? Number(year1?.RH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RH ? Number(year2?.RH).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RH ? Number(year3?.RH).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Taxes and duties")}</td>
                      <td className='text_right'>{year1?.RI ? Number(year1?.RI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RI ? Number(year2?.RI).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RI ? Number(year3?.RI).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Other external expenses")}</td>
                      <td className='text_right'>{year1?.RJ ? Number(year1?.RJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RJ ? Number(year2?.RJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RJ ? Number(year3?.RJ).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("VALUE ADDED")}</th>
                      <th className='text_right' scope="col">{year1?.XC ? Number(year1?.XC).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XC ? Number(year2?.XC).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XC ? Number(year3?.XC).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Salaries and Payroll Expenses")}</td>
                      <td className='text_right'>{year1?.RK ? Number(year1?.RK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RK ? Number(year2?.RK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RK ? Number(year3?.RK).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("GROSS OPERATING PROFIT BEFORE DEPRECIATION & AMORTIZATION")}</th>
                      <th className='text_right' scope="col">{year1?.XD ? Number(year1?.XD).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XD ? Number(year2?.XD).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XD ? Number(year3?.XD).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Reversal of depreciation, amortization, impairment losses and provisions (Operating income)")}</td>
                      <td className='text_right'>{year1?.TJ ? Number(year1?.TJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TJ ? Number(year2?.TJ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TJ ? Number(year3?.TJ).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Depreciation, amortization, impairment and provisions - Operating expenses")}</td>
                      <td className='text_right'>{year1?.RL ? Number(year1?.RL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RL ? Number(year2?.RL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RL ? Number(year3?.RL).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TOTAL OPERATING PROFIT")}</th>
                      <th className='text_right' scope="col">{year1?.XE ? Number(year1?.XE).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XE ? Number(year2?.XE).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XE ? Number(year3?.XE).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Financial Income")}</td>
                      <td className='text_right'>{year1?.TK ? Number(year1?.TK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TK ? Number(year2?.TK).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TK ? Number(year3?.TK).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Reversal of provisions for liabilities and impairment losses (Financial income)")}</td>
                      <td className='text_right'>{year1?.TL ? Number(year1?.TL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TL ? Number(year2?.TL).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TL ? Number(year3?.TL).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Transfers of financial expenses")}</td>
                      <td className='text_right'>{year1?.TM ? Number(year1?.TM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TM ? Number(year2?.TM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TM ? Number(year3?.TM).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Financial Expenses")}</td>
                      <td className='text_right'>{year1?.RM ? Number(year1?.RM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RM ? Number(year2?.RM).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RM ? Number(year3?.RM).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Amortization, impairment and provisions - Financial expenses")}</td>
                      <td className='text_right'>{year1?.RN ? Number(year1?.RN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RN ? Number(year2?.RN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RN ? Number(year3?.RN).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("TOTAL FINANCIAL INCOME")}</th>
                      <th className='text_right' scope="col">{year1?.XF ? Number(year1?.XF).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XF ? Number(year2?.XF).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XF ? Number(year3?.XF).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("0 Pre-tax profit or loss before exceptional items")}</td>
                      <td className='text_right'>{year1?.XG ? Number(year1?.XG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.XG ? Number(year2?.XG).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.XG ? Number(year3?.XG).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Income from sale of assets")}</td>
                      <td className='text_right'>{year1?.TN ? Number(year1?.TN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TN ? Number(year2?.TN).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TN ? Number(year3?.TN).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Other exceptional income")}</td>
                      <td className='text_right'>{year1?.TO ? Number(year1?.TO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.TO ? Number(year2?.TO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.TO ? Number(year3?.TO).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Book value of assets sold")}</td>
                      <td className='text_right'>{year1?.RO ? Number(year1?.RO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RO ? Number(year2?.RO).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RO ? Number(year3?.RO).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Other exceptional expenses")}</td>
                      <td className='text_right'>{year1?.RP ? Number(year1?.RP).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RP ? Number(year2?.RP).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RP ? Number(year3?.RP).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("RESULTAT HORS ACTIVITES ORDINAIRES")}</th>
                      <th className='text_right' scope="col">{year1?.XH ? Number(year1?.XH).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XH ? Number(year2?.XH).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XH ? Number(year3?.XH).toLocaleString() : "0"}</th>
                    </tr>
                    <tr>
                      <td>{t("Employee profit-sharing")}</td>
                      <td className='text_right'>{year1?.RQ ? Number(year1?.RQ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RQ ? Number(year2?.RQ).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RQ ? Number(year3?.RQ).toLocaleString() : "0"}</td>
                    </tr>
                    <tr>
                      <td>{t("Income taxes")}</td>
                      <td className='text_right'>{year1?.RS ? Number(year1?.RS).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year2?.RS ? Number(year2?.RS).toLocaleString() : "0"}</td>
                      <td className='text_right'>{year3?.RS ? Number(year3?.RS).toLocaleString() : "0"}</td>
                    </tr>
                    <tr className='table_col'>
                      <th scope="col">{t("PROFIT AND LOSS")}</th>
                      <th className='text_right' scope="col">{year1?.XI ? Number(year1?.XI).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year2?.XI ? Number(year2?.XI).toLocaleString() : "0"}</th>
                      <th className='text_right' scope="col">{year3?.XI ? Number(year3?.XI).toLocaleString() : "0"}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </React.Fragment >
  )
}



export default ProfitandLossReport1;