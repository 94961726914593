import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { setBreadcrumbItems } from "../../../store/actions";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import AddQuestion from "./AddQuestion";
import { get, post, put } from 'helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as Utils from "./../../Utils";

const Question = (props) => {
    let history = useHistory();
    const [isAdd, setIsAdd] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const { user } = props;
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [segments, setSegments] = useState([]);
    const [facets, setFacets] = useState([]);
    const { i18n, t } = useTranslation();

    const breadcrumbItems = [
        { title: "Demleen", link: "#" },
        { title: "Questions", link: "#" },
    ]

    useEffect(() => {
        if (props?.user?.token) {
            getUserData();
            getSegments();
            getFacets();
        }
    }, [props]);

    const getSegments = () => {
        let body = {
            userId: user?._id,
            token: user?.token
        }
        get('/nuro/segment/list', body)
            .then((res => {
                setLoading(false);
                console.log('segment list', res)
                if (res?.statusCode == 200) {
                    setSegments(res?.data)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }

    const getFacets = () => {
        let body = {
            userId: user?._id,
            token: user?.token
        }
        get('/nuro/facet/list', body)
            .then((res => {
                setLoading(false);
                console.log('segment list', res)
                if (res?.statusCode == 200) {
                    setFacets(res?.data)
                } else {
                    console.log(res?.error);
                }
            }))
            .catch(error => {
                setLoading(false);
                console.log('error while login', error);
                console.log('Something went wrong');
            })
    }

    useEffect(() => {
        if (!profileModal)
            setCurrentData(null)
    }, [profileModal])

    const getUserData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                userId: user?._id,
                token: user?.token
            }
            get('/nuro/list', body)
                .then((res => {
                    setLoading(false);
                    console.log('segment list', res)
                    if (res?.statusCode == 200) {
                        setData(res?.data)
                    } else {
                        console.log(res?.error);
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                })
        }
    }
    useEffect(() => {
        props.setBreadcrumbItems(t("Questions Neuroprofiler"), breadcrumbItems)
    })

    const columns = [
        {
            name: "Position",
            cell: (row, index) => <>
                <Draggable key={row._id} draggableId={row._id} index={index}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
                        </div>
                    )}
                </Draggable>
            </>,
            maxWidth: '50px'
        },
        {
            name: t('Question'),
            selector: row => i18n?.language == 'en' ? <span> {row?.title}</span> : <span>{row?.titleFs}</span>,
            maxWidth: '600px'
        },
        {
            name: t('Segment'),
            selector: row => row?.segment?.length ? i18n?.language == 'en' ? row?.segment[0]?.title : row?.segment[0]?.titleFs : "-",
            maxWidth: '200px'
        },
        {
            name: t('Facet'),
            selector: row => row?.facet?.length ? i18n?.language == 'en' ? row?.facet[0]?.title : row?.facet[0]?.titleFs : "-",
            maxWidth: '200px'
        },
        {
            name: t('type'),
            selector: row => row?.type,
            maxWidth: '100px'
        },
        {
            name: t('Status'),
            selector: row =>
                <div className='status_box'>
                    {
                        <button className={row?.isActive == true ? 'active' : 'Inactive'} onClick={() => { setCurrentData({ ...row, actionType: "Status" }); setDeleteModal(true) }}>{row?.isActive == true ? 'Active' : 'Inactive'}</button>
                    }
                </div>
            ,
            maxWidth: '120px'
        },
        {
            name: 'Action',
            selector: row =>
                <div className='action_box'>

                    <button onClick={() => { setCurrentData(row); setIsAdd(true) }}>
                        <i class="mdi mdi-pencil" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Edit")} onClick={ExpandedComponent}></i></button>
                    <button onClick={() => { setCurrentData(row); setDeleteModal(true) }}><i class="mdi mdi-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t('Delete')}></i></button>
                    {/* <button ><i class="mdi mdi-heart" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Company"></i></button> */}
                </div>,
            maxWidth: '120px'
        },
    ];

    const ExpandedComponent = ({ data }) =>
        <div className='expending_box'>
            <Row>
                <Col lg={7}>
                    <p> {t("Title")} :- {i18n?.language == 'en' ? data?.title : data?.titleFs}</p>
                </Col>
                <Col lg={5}>
                    <div>
                        <img src={data?.image} alt={data?.image} />
                    </div>
                </Col>
            </Row>
        </div>;

    const statusChange = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                isActive: !currentData?.isActive,
                quesId: currentData?._id
            }

            put('/nuro', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const onDelete = () => {
        if (!loading) {
            setLoading(true)
            setDeleteModal(false)
            const body = {
                token: user?.token,
                quesId: currentData?._id
            }

            post('/nuro/delete', body)
                .then((res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        console.log('status change', res);
                        toast.success(res?.message)
                        // history.push('/dashboard')
                        getUserData()
                    } else if (res?.statusCode == 401) {
                        history?.push('/logout')
                    } else {
                        console.log(res?.error);
                        toast.error(res?.error)
                    }
                }))
                .catch(error => {
                    setLoading(false);
                    console.log('error while login', error);
                    console.log('Something went wrong');
                    toast.error('Something went wrong')
                })
        }
    }

    const handleDragEnd = (result) => {
        // console.log('dragging item', result);
        // Handle the drag-and-drop logic here
        if (!result.destination) return; // Item was dropped outside the list
        const items = [...data];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        let ids = [], position = [];
        items.forEach((item, index) => {
            ids.push(item?._id);
            position.push(index);
        })
        let body = {
            ids,
            position,
            token: user?.token
        }
        put("/nuro/position", body)
            .then(res => {
                // if (res?.statusCode == 200)
                setData(items);
            })
    };

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={currentData?.actionType == 'Status' ? statusChange : onDelete}
                onCloseClick={() => setDeleteModal(false)}
                data={currentData} />
            <MetaTags>
                <title>Profiler Questions | Demleen </title>
            </MetaTags>
            {isAdd ?
                <AddQuestion
                    setIsAdd={setIsAdd}
                    currentData={currentData}
                    user={user}
                    loading={loading}
                    setLoading={setLoading}
                    getData={getUserData}
                    setCurrentData={setCurrentData}
                    segments={segments}
                    facets={facets}
                />
                :
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody >

                                <div className='investor_component'>
                                    <div className='search_section'>
                                        <button onClick={() => { setCurrentData(null); setIsAdd(true); }} className='add_button' style={{ width: 'fit-content' }}>{t("Add1")}</button>
                                    </div>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="table">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        className='finenceTable_section finenceTable_section_'
                                                        expandableRows={true}
                                                        expandOnRowClicked={true}
                                                        expandableRowDisabled={row => row.disabled}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        expandableRowsComponentProps={row => row.Action}
                                                        direction="ltr"
                                                        pagination
                                                        paginationPerPage={50}
                                                        paginationRowsPerPageOptions={[50, 100, 200, 300]}
                                                        responsive
                                                        subHeaderAlign="right"
                                                        subHeaderWrap
                                                    />
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </React.Fragment >
    )
}

const mapStatetoProps = state => {

    const user = state.Profile.success;
    return { user };
}

export default withRouter(
    connect(mapStatetoProps, { setBreadcrumbItems })(Question)
)